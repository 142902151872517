import { Stack, Text, Box} from '@chakra-ui/react';
import React from 'react';
import { Radio, RadioGroup } from '@chakra-ui/react';



export const SelectDropdownCheckbox = (props) => {
  const { handleChange, name, options = [{ value: 'sampleValue', label: 'sampleLabel' }] } = props;
  const [value, setValue] = React.useState<string>('0');

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        classNamePrefix="vams-custom-select"
        justifyContent="space-even"
       
    
        id="radio_dropdown"
            >

            <Text as="b" w={"90px"} h={"50px"}>{name}</Text>
        <RadioGroup onChange={setValue} value={value}>
          <Stack spacing={[6, 10]} direction={['column', 'row']}>

            {options.map((v, i) => (
              <>
                 <Radio value={(i + 1).toString()} w={"80px"} h={"50px"}    id={name} key={i} option={options} onChange={() => handleChange(v)}>
                   {v.label}
                </Radio>
              </>
            ))}
          </Stack>
        </RadioGroup>
      </Box>
    </>
  );
};
