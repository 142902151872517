import React, { createContext, useContext, useState } from 'react';

interface IAppContext {
  vehicleType: string;
  setVehicleType: (e) => void;
  setFiltersToNull: (e) => void;
  setLocationId: (e) => void;
  setPlatform: (e) => void;
  setManufacturer: (e) => void;
  setCity: (e) => void;
  setStartDate: (e) => void;
  setEndDate: (e) => void;
  startDate: string;
  endDate: string;
}
export const AppContext = createContext<IAppContext | null>(null);

export const useAppContext = () => useContext(AppContext);

interface IChildren {
  children: React.ReactChild;
}

const AppContextProvider = ({ children }: IChildren) => {
  const [vehicleType, setVehicleType] = useState('');
  const [location_id, setLocationId] = useState<string>('');
  const [platform, setPlatform] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [city, setCity] = useState<any>('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const setFiltersToNull = () => {
    setVehicleType('');
    setLocationId('');
    setPlatform('');
    setManufacturer('');
    setCity('');
    setStartDate('');
    setEndDate('');
  };

  const values = {
    setVehicleType,
    vehicleType,
    location_id,
    setLocationId,
    platform,
    setPlatform,
    manufacturer,
    setManufacturer,
    city,
    setCity,
    setFiltersToNull,
    startDate,
    endDate,
    setStartDate,
    setEndDate
  };
  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
