import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Box } from '@chakra-ui/react';

import { QUERY_KEYS } from 'util/constants';
import { PROTECTED_ROUTES } from '../routes/protected-routes';
import {
  getAllCities,
  getAllSubCities,
  getSubReason,
  getVehicleType,
  getManufactures,
  getPlatforms
} from 'util/filtersHelper';

import AppNav from '../components/app-nav/app-nav.component';
import ScrollToTop from 'components/ScrollToTop';

const AppWrapper = () => {
  const routes = useRoutes(PROTECTED_ROUTES);
  return routes;
};

const Authenticated = () => {
  useQuery(QUERY_KEYS.ALL_CITIES, getAllCities);
  useQuery(QUERY_KEYS.ALL_SUB_CITIES, getAllSubCities);
  useQuery(QUERY_KEYS.ALL_VEHICLE_TYPE, getVehicleType);
  useQuery([QUERY_KEYS.ALL_MANUFACTURERS], getManufactures);
  useQuery([QUERY_KEYS.ALL_PLATFORM], getPlatforms);
  useQuery([QUERY_KEYS.ALL_SUB_REASON], getSubReason);

  return (
    <Router>
      <ScrollToTop />
      <Box bg="#f4f6fa" minH="100vh">
        <AppNav />
        <AppWrapper />
      </Box>
    </Router>
  );
};
export default Authenticated;
