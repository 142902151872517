export const QUERY_KEYS = {
  ALL_VEHICLES: 'getAllVehicles',
  ALL_MANUFACTURERS: 'getAllManufactureres',
  ALL_VEHICLE_TYPES: 'getAllVehicleTypes',
  ALL_VEHICLE_MODELS: 'getVehicleModels',
  ALL_LOCATIONS: 'allLocations',
  ALL_TRIMS: 'allTrims',
  ALL_BATCH: 'allBatch',
  ALL_SERVICES: 'allServices',
  ALL_PARTNERS: 'allPatners',
  ALL_VEHICLE_STATUS: 'allVehicleStatus',
  ALL_PLATFORMS: 'allPlatforms',
  ALL_REASONS: 'allReasons',
  ALL_FINANCIAL_PARTNERS: 'allFinancialPartners',
  SUB_CITIES_WITH_SEARCH: 'subCitiesWithSearch',
  MODEL_WITH_SEARCH: 'modelWithSearch'
};
