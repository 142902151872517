import React, { FC, useState } from 'react';
import { Box, Center, Flex, Td, Tr } from '@chakra-ui/react';
// import { CSVLink } from 'react-csv';
import { useQuery } from 'react-query';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router';
import { format } from 'date-fns';

import { MaintenanceTableColumn } from './constants';
import { maintenanceRequest, reportRequest } from 'services/request';
import axiosInstance from 'services/httpService';
import { getVehicleType } from 'util/index';

// import { ReactComponent as Excel } from 'assets/images/excel.svg';

import FilterContainer from 'components/filter-container/filter-container.component';
import PageWrapper from 'components/page-wrapper';
import { MaintenanceSummary } from 'components/Maintenance/MaintenanceSummary';
import { Table } from 'components/Maintenance/Table';
import { StatusDisplay } from 'components/StatusDisplay/StatusDisplay';
import Pagination from 'components/UiElements/Pagination/Pagination';
import { SearchFilterWrapper } from 'components/UiElements/SearchFilter';
import VehicleDetail from 'components/UiElements/VehicleDetail';
import { SelectDropdown } from 'components/UiElements/SelectDropdown/select-dropdown';

export const UnscheduledMaintenance: FC = () => {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage] = React.useState(25);
  const [filterLocation, setFilterLocation] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');

  const navigate = useNavigate();

  const getAllVehicles = async (page) => {
    const url = `${
      maintenanceRequest.ALL_MAINTENANCE_VEHICLES
    }?unscheduled=unscheduled&page=${page}&size=${rowsPerPage}${filterLocation && `&location=${filterLocation}`}${
      searchQuery && `&search_query=${searchQuery}`
    }${startDate && `&start_date=${format(startDate, 'yyyy-MM-dd')}`}${
      endDate && `&end_date=${format(endDate, 'yyyy-MM-dd')}`
    }`;
    const data = await axiosInstance.get(url);
    return data?.data;
  };

  const { data, isLoading }: any = useQuery(
    ['unscheduled-maintenance', page, filterLocation, searchQuery, startDate, endDate],
    () => getAllVehicles(page)
  );
  const maintenanceData = data && data?.data?.results;

  const getAllLocations = async () => {
    const url = reportRequest.ALL_SUB_CITIES;
    const data = await axiosInstance.get(url);
    return data?.data;
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const { data: locationData }: any = useQuery(['locations'], getAllLocations);

  const _location_data =
    locationData &&
    locationData?.payload?.message?.objects?.map((el) => ({
      label: el.name,
      value: el.id
    }));

  return (
    <>
      <FilterContainer canNavigate pageName="Unscheduled Maintenance" />
      <PageWrapper>
        <Flex mt={5}>
          <Box w="300px">
            <MaintenanceSummary title="Total unscheduled maintenance" totalVehicles={data?.data?.unscheduledCount} />
          </Box>
          <Box height="700px" overflow="hidden" position={'relative'} background="#fff" width="100%" ml="4">
            <Box height="100%" paddingBottom="81px" position="relative" overflow="scroll">
              <SearchFilterWrapper
                searchPlaceholder=""
                filterTitle=""
                setSearchQuery={setSearchQuery}
                title="Vehicle Checkin"
                toggleBlock={<></>}
                filterBlock={
                  <form className="table-filter-form">
                    <div className="strip-select-style">
                      <SelectDropdown
                        handleChange={(e) => {
                          setFilterLocation(e?.value);
                        }}
                        isClearable
                        name=""
                        options={_location_data}
                        placeholder="Location"
                      />
                    </div>
                    <div className="filter-date-range">
                      {/* <Date */}
                      <DatePicker
                        isClearable
                        selected={startDate}
                        onChange={onDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        placeholderText="Select A Date"
                        // inline
                      />

                      {/* <DatePicker selected={endDate} onChange={(date: Date) => setStartDate(date)} /> */}
                    </div>
                  </form>
                }
                exportBlock={
                  <button>
                    {/* <CSVLink filename="unscheduled-vehicle-list.csv" data={maintenanceData || ''}>
                      <Excel style={{ width: '14px' }} /> <span>EXPORT TO CSV</span>
                    </CSVLink> */}
                  </button>
                }
              />
              {isLoading ? (
                <Center pt={5}>Loading...</Center>
              ) : (
                <Table
                  columns={MaintenanceTableColumn}
                  tableData={maintenanceData?.map((data) => (
                    <Tr
                      cursor="pointer"
                      fontSize="14px"
                      onClick={() => navigate(`/maintenance/vehicle/${data?.id}`)}
                      key={data?.champion?.name}
                    >
                      <Td>
                        <VehicleDetail
                          max_vehicle_id={data?.vehicle?.max_vehicle_id}
                          first_name={data?.champion?.first_name}
                          last_name={data?.champion?.last_name}
                          vehicle_type_id={getVehicleType(data?.vehicle?.model?.vehicle_type_id)}
                        />
                      </Td>
                      <Td>{data?.vehicle_plate_number}</Td>
                      <Td>{data?.vehicle?.trim?.name}</Td>
                      <Td>{data?.status}</Td>
                      <Td>{data?.odometer.toFixed(2)}</Td>
                      <Td>
                        <StatusDisplay fontSize="0.75rem" status={data?.status} />
                      </Td>
                    </Tr>
                  ))}
                />
              )}
            </Box>
            {maintenanceData?.length > 10 && (
              <Box background={'#fff'} bottom={0} width={'100%'} position="absolute">
                <Pagination page={page} setPage={setPage} />
              </Box>
            )}
          </Box>
        </Flex>
      </PageWrapper>
    </>
  );
};

UnscheduledMaintenance.defaultProps = {};
