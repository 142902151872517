import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

type EmptyProps = {
  description?: string;
  iconWidth?: number | string;
  iconHeight?: number | string;
};

export const RenderEmptyData: FC<EmptyProps> = ({ description, iconWidth, iconHeight }) => {
  return (
    <Flex direction="column" justify="center" align="center" py={10} gap={4}>
      <InfoIcon w={iconWidth} h={iconHeight} color="hsla(0, 0%, 0%, 0.1)" />
      <Text color="hsla(0, 0%, 44%, 1)" mb={5} size="md" textAlign="center" w={300}>
        {description}
      </Text>
    </Flex>
  );
};
