import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputRightAddon
} from '@chakra-ui/react';
import { postRequest, useMutationWrapper } from 'services/apiHelper';
import { toast } from 'react-toastify';
import { authRequest } from 'services/request';
import { useQuery } from 'react-query';
import axiosInstance from 'services/httpService';
import { convertParamsToString } from 'helpers/auth-helpers';
import { KEY_LS_MAX_TOKEN, KEY_LS_MAX_USER, KEY_LS_MAX_USER_VAMS } from 'util/constants';
import { USER_ROLES } from 'util/customQueryHooks';
import { PROTECTED_PATHS, PUBLIC_PATHS } from 'routes/constants';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const Login: React.FC<any> = () => {
  const [searchParams] = useSearchParams();

  let query = searchParams.get('timeout');

  const [userRole, setuserRole] = useState('');
  const history = useNavigate();
  const { pathname } = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [temporaryPassword, setTemporaryPassword] = useState('');
  const [resetActive, setResetActive] = useState(false);

  const [showPass, setShowPass] = useState(false);
  const [showTempPass, setShowTempPass] = useState(false);

  const [userId, setuserId] = useState('');
  const [userIdVams, setuserIdVams] = useState('');
  const isLoginPath = pathname === PUBLIC_PATHS.LOGIN || pathname === '/';
  const isPasswordResetPath = pathname === PUBLIC_PATHS.FORGOT_PASSWORD;

  const userObj = JSON.parse(localStorage.getItem('__maxadmnrole__') || '{}');

  const getUserOnAccountService = async ({ queryKey }) => {
    const url = convertParamsToString(authRequest.GET_USER_FROM_ACCOUNT_SERVICE, {
      userId: queryKey[1]
    });
    const { data } = await axiosInstance.get(url);
    return data;
  };

  const { refetch: refetchUserFromAccount } = useQuery(['current-user', userIdVams], getUserOnAccountService, {
    enabled: false,
    onSuccess: (response) => {
      console.log(response, 'refetch');

      localStorage.setItem(KEY_LS_MAX_USER, JSON.stringify(response.data));
      const allowFleetOfficerLoginRoles = [USER_ROLES.FLEET_OFFICER];
      if (allowFleetOfficerLoginRoles.includes(userObj?.role?.name)) {
        window.location.href = PROTECTED_PATHS.ASSET_MANAGEMENT; //!to dissable vehicle assement, on page load,     window.location.href = PROTECTED_PATHS.VEHICLE_ASSIGNMENT;
      } else {
        if (userRole === 'fleet_officer_maintenance') {
          window.location.href = PROTECTED_PATHS.MAINTENANCE;
        } else {
          window.location.href = PROTECTED_PATHS.VEHICLES;
        }
      }
    }
  });

  const getUserOnVams = async ({ queryKey }) => {
    const url = convertParamsToString(authRequest.GET_USER_ON_VAMS, {
      userId: queryKey[1] || userId
    });
    const { data } = await axiosInstance.get(url);
    return data;
  };

  const { refetch } = useQuery(['user-vams', userId], getUserOnVams, {
    enabled: false,
    onSuccess: (response) => {
      const userRole = response.data.role.name;
      setuserRole(userRole);
      const allowLoginRoles = [
        USER_ROLES.ADMIN,
        USER_ROLES.AGENT,
        USER_ROLES.FLEET_OFFICER,
        USER_ROLES.FLEET_OFFICER_MOVEMENT,
        USER_ROLES.FLEET_OFFICER_MAINTENANCE,
        USER_ROLES.SUPER_ADMIN,
        USER_ROLES.VEHICLE_MANAGER,
        USER_ROLES.BULK_UPLOAD_MANAGER
      ];

      if (!allowLoginRoles.includes(userRole)) {
        localStorage.removeItem(KEY_LS_MAX_TOKEN);
        localStorage.removeItem(KEY_LS_MAX_USER);
        setuserId('');
        return toast.error('You are not authorized to access this route');
      }
      localStorage.setItem(KEY_LS_MAX_USER_VAMS, JSON.stringify(response.data));
      setuserIdVams(response.data.user_account_id);
    },
    onError: async (error: any) => {
      const errorMessage = error.response.data.message;
      localStorage.removeItem(KEY_LS_MAX_TOKEN);
      localStorage.removeItem(KEY_LS_MAX_USER);
      setuserId('');
      return toast.error(errorMessage);
    }
  });

  const LoginSuccess = (response) => {
    toast.success('Login successfully');
    setuserId(response.data.user.id);
    localStorage.setItem(KEY_LS_MAX_TOKEN, response.data.access_token);
  };

  const forgotPasswordSuccess = (res) => {
    setResetActive(true);
    toast.success(res.message);
  };
  const resetPasswordSuccess = (res) => {
    setResetActive(false);
    toast.success(res.message);

    console.log(res);

    history('/login');
  };

  const forgotPasswordMutation = useMutationWrapper(postRequest, forgotPasswordSuccess);
  const resetPasswordMutation = useMutationWrapper(postRequest, resetPasswordSuccess);
  const { mutate, isLoading } = useMutationWrapper(postRequest, LoginSuccess);
  useEffect(() => {
    if (userId !== '') {
      refetch();
    }
  }, [userId, refetch]);

  useEffect(() => {
    if (userIdVams !== '') {
      refetchUserFromAccount();
    }
  }, [userIdVams, refetchUserFromAccount]);
  useEffect(() => {
    if (query) {
      toast.warn(
        <div>
          <Heading textAlign="left" size="sm">
            Session Timeout
          </Heading>
          <p>Please login again!</p>
        </div>
      );
    }
  }, [query]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoginPath) {
      mutate({
        url: authRequest.LOGIN,
        data: {
          username: email,
          password
        }
      });
    } else if (resetActive && !isLoginPath) {
      resetPasswordMutation.mutate({
        url: authRequest.RESET_PASSWORD,
        data: {
          username: email,
          temporary_password: temporaryPassword,
          new_password: password
        }
      });
    } else {
      forgotPasswordMutation.mutate({
        url: authRequest.FORGOT_PASSWORD,
        data: {
          username: email
        }
      });
    }
  };

  return (
    <Grid gridTemplateColumns="1fr 1fr" h="100vh">
      <Flex bg="#fff8db" justify="center" alignItems="center">
        <Heading textAlign="center" size="lg">
          Welcome To Vehicle Activation Management System
        </Heading>
      </Flex>
      <Flex bg="#f2f7fa" justify="center" alignItems="center">
        <Box
          w="360px"
          bg="#fff"
          borderRadius="4px"
          border="1px dotted #dedede"
          // opacity=".88"
          padding="3rem"
        >
          <form onSubmit={handleSubmit}>
            <FormControl id="email" isRequired>
              <FormLabel mb="0">Email address</FormLabel>
              <Input type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
            </FormControl>
            {resetActive && (
              <FormControl id="email" isRequired mt="4">
                <FormLabel mb="0">Temporary password</FormLabel>
                <InputGroup>
                  <Input
                    type={showTempPass ? 'text' : 'password'}
                    onChange={(event) => setTemporaryPassword(event.target.value)}
                  />
                  <InputRightAddon onClick={() => setShowTempPass(!showTempPass)}>
                    {showTempPass ? <MdVisibilityOff /> : <MdVisibility />}
                  </InputRightAddon>
                </InputGroup>
              </FormControl>
            )}
            {(isLoginPath || resetActive) && (
              <FormControl id="email" isRequired mt="4">
                <FormLabel mb="0">{resetActive ? 'New password' : 'Password'}</FormLabel>
                <InputGroup>
                  {' '}
                  <Input
                    type={showPass ? 'text' : 'password'}
                    onChange={(event) => setPassword(event.target.value)}
                  />{' '}
                  <InputRightAddon onClick={() => setShowPass(!showPass)}>
                    {showPass ? <MdVisibilityOff /> : <MdVisibility />}
                  </InputRightAddon>
                </InputGroup>
              </FormControl>
            )}
            <Button
              type="submit"
              isFullWidth
              mt="4"
              isLoading={isLoading || forgotPasswordMutation.isLoading || resetPasswordMutation.isLoading}
            >
              {isLoginPath ? 'Login' : 'Submit'}
            </Button>
            <Button
              variant="link"
              isFullWidth
              mt="2"
              onClick={() => {
                history(isPasswordResetPath ? PUBLIC_PATHS.LOGIN : PUBLIC_PATHS.FORGOT_PASSWORD);
                setResetActive(false);
              }}
            >
              {isPasswordResetPath ? 'Back to Login' : 'Forgot Password'}
            </Button>
          </form>
        </Box>
      </Flex>
    </Grid>
  );
};

export default Login;
