import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import UserIcon from 'assets/images/helmet-md.svg';
import AgentIcon from 'assets/images/max-agent-image.svg';
import Badge, { BadgeStyle } from 'components/badge/Badge';
import CustomButton, { ButtonStates } from 'components/buttons/Button';
import CardTitle from 'components/card/card-title';
import ListItem from 'components/list-items/LisItem';
import { store } from 'data/store';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { FaPhone } from 'react-icons/fa6';
import { MdKeyboardArrowRight, MdOutlineHighlightOff } from 'react-icons/md';
import { PiNote } from 'react-icons/pi';
import { useQuery } from 'react-query';
import axiosInstance from 'services/httpService';
import { IMaxUser } from 'types/user';
import { KEY_LS_MAX_USER_VAMS } from 'util/constants';
import { incidentBadgeStyle, removeUnderScores, ucFirst } from 'util/ui-helpers';
import UpdateTicketModal from './update-ticket-modal';

export default function ViewIncidentDetailsModal({
  isOpen,
  onClose,
  onOpenCallScriptModal,
  status,
  ticketId,
  refetch,
  isViewingTicketDetails
}: InciddentDetailsProps) {
  const [showUpdateTicket, setShowUpdateTicket] = useState<boolean>(false);

  const getMethod = async ({ queryKey }) => {
    let { route, params } = queryKey[1];
    try {
      const { data } = await axiosInstance.get(`${route}`, {
        params
      });
      return data;
    } catch (error) {
      throw error;
    }
  };
  const { data: ticket } = useQuery(
    [
      'ticket',
      {
        route: `${process.env.REACT_APP_TMS_BASE_URL}/ticket/${ticketId}`,
        params: {}
      }
    ],
    getMethod,
    { enabled: Boolean(ticketId) }
  );

  const ticketInfo = ticket?.data;

  const userDetails = store.getJson(KEY_LS_MAX_USER_VAMS) as IMaxUser;

  const isCallAnalyst = userDetails?.app_roles?.['welfare-service'] === 'call-analyst';

  const incidentInfo = [
    {
      title: 'Location',
      value: `${ticketInfo?.location || 'N/A'}`
    },
    {
      title: 'Date of incident',
      value: `${ticketInfo ? format(new Date(ticketInfo?.created_at), 'dd MMMM yyyy') : 'N/A'}`
    },
    {
      title: 'Time of incident',
      value: `${ticketInfo ? format(parseISO(ticketInfo?.created_at), 'hh:mm a') : 'N/A'}`
    },
    {
      title: 'Reporter',
      value: `${ticketInfo?.reporter || 'N/A'}`
    },
    {
      title: 'Vehicle Plate',
      value: `${ticketInfo?.plate_number || 'N/A'}`
    }
  ];

  const ticketStatus = ticketInfo ? ticketInfo?.status : '';

  const incidentReport = ticketInfo?.incident_report;

  const originalDateStr = !!incidentReport?.created_at ? incidentReport?.created_at : '';

  // Parse the original date string into a Date object
  const originalDate = new Date(originalDateStr);

  // Format the date components
  const formattedDate = originalDate.toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  });

  const formattedTime = originalDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit'
  });
  // Combine date and time components in the desired format
  const formattedDateTime = `${formattedDate}, ${formattedTime}`;

  return (
    <>
      <UpdateTicketModal
        isOpen={showUpdateTicket}
        onClose={() => {
          setShowUpdateTicket(!showUpdateTicket);
        }}
        ticketInfo={ticketInfo}
        ticketId={ticketId as string}
        refetch={refetch}
      />
      <Modal isCentered blockScrollOnMount={false} onClose={onClose} size="lg" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent maxWidth="370px" className="amortization-schedule-summary-modal">
          <ModalHeader>
            <div className="flex items-end mb-4">
              <div className="mr-[15px]">
                <ListItem
                  mainTitle={`${ucFirst(ticketInfo?.type) || ''} type`}
                  mainTitleStyle="comm-r-14 text-[#808080] opacity-50"
                  subTitle={`${ucFirst(removeUnderScores(ticketInfo?.category)) || ''}`}
                  subTitleStyle=" comm-d-18"
                />
              </div>
              <div>
                <Badge state={incidentBadgeStyle(ticketStatus!)!} badgeStyle={BadgeStyle.box}>
                  {ticketInfo?.status}
                </Badge>
              </div>
            </div>
          </ModalHeader>
          <div className="text-grey01 cursor-pointer">
            <ModalCloseButton as={MdOutlineHighlightOff} />
          </div>
          <ModalBody>
            <div className=" max-h-[500px] overflow-y-scroll scrollbar-hidden">
              <div className="border border-grey01 rounded-2xl mt-5 bg-white pb-5 ">
                <CardTitle title="Ticket Type" />

                <div className="grid grid-cols-2 px-12 py-8 gap-y-6">
                  {incidentInfo.map((data, index) => (
                    <ListItem
                      key={index}
                      mainTitle={data?.title}
                      mainTitleStyle="comm-r-14 text-black-50"
                      subTitle={data?.value!.toLocaleString()}
                      subTitleStyle="comm-m-16 font-bold"
                    />
                  ))}
                </div>
                {isViewingTicketDetails === true ? (
                  <></>
                ) : (
                  !isCallAnalyst && (
                    <div className="flex justify-center">
                      {' '}
                      {/* Centering the div */}
                      <div
                        className="flex h-[54px] items-center justify-between cursor-pointer p-4 bg-[#F7F7F7] w-[90%] rounded-3xl group hover:bg-[#3e3e3e]"
                        onClick={onOpenCallScriptModal}
                      >
                        <div className="flex items-center w-full ">
                          <div className="flex justify-center items-center w-[34px] h-[34px] mr-4 rounded-[50%] bg-[#fcdd18] group group-hover:bg-white">
                            <FaPhone size={10} />
                          </div>
                          <div className="w-[78%]">
                            <p className="text-grey02 group-hover:text-white text-[10px]">Recorded on call</p>
                            <p className="text-black group-hover:text-white text-[12px] font-semibold">
                              Added Call Script
                            </p>
                          </div>
                          <div className="w-[10%] group-hover:text-white">
                            <MdKeyboardArrowRight size={20} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="border border-grey01 rounded-2xl mt-5 bg-white p-5">
                <ListItem
                  mainTitle={`${ucFirst(ticketInfo?.champion_name) || ''}`}
                  mainTitleStyle="comm-d-14 font-bold text-black"
                  subTitle={`${ticketInfo?.champion_max_id?.toUpperCase() || ''}`}
                  subTitleStyle="comm-m-12  text-grey02"
                  appendParentClass="items-center"
                  leading={
                    <div className="mr-[15px]">
                      <img src={UserIcon} alt="champion icon" />
                    </div>
                  }
                  trailingStyle="basis-1/2 justify-end items-center"
                />
              </div>

              <>
                {isViewingTicketDetails === true ? (
                  <></>
                ) : (
                  <div className="border border-grey01 rounded-2xl mt-5 bg-white ">
                    <CardTitle title="Assigned Agent" />
                    <div className="p-5">
                      <ListItem
                        mainTitle={`${ucFirst(ticketInfo?.agent_name) || ''}`}
                        mainTitleStyle="comm-d-14 font-bold text-black"
                        appendParentClass="items-center"
                        leading={
                          <div className="mr-[15px]">
                            <img src={AgentIcon} alt={'Agent icon'} />
                          </div>
                        }
                        // trailing={
                        //   !isCallAnalyst ? (
                        //     <div
                        //       className="text-[#E88E15] uppercase text-center comm-b-12 w-full cursor-pointer hover:underline"
                        //       onClick={() =>
                        //         navigate.push(`/agent/${ticketInfo?.agent_id}`)
                        //       }
                        //     >
                        //       View agent
                        //     </div>
                        //   ) : (
                        //     <></>
                        //   )
                        // }
                        trailingStyle="basis-1/2 justify-end items-center"
                      />
                    </div>
                  </div>
                )}

                {ticketInfo?.incident_report !== null
                  ? !isCallAnalyst && (
                      <div className="border border-grey01 rounded-2xl mt-5 bg-white ">
                        <CardTitle title="Investigation Report" />
                        <div className="p-5">
                          <ListItem
                            mainTitle="Added New Report"
                            mainTitleStyle="comm-d-14 text-black"
                            subTitle={`Uploaded ${formattedDateTime}`}
                            subTitleStyle="comm-m-12 text-grey02"
                            appendParentClass="items-center"
                            leading={
                              <div className="w-[33px] h-[33px] flex items-center justify-center mr-[15px] rounded-[8px] border border-[#eeeeee] bg-[#F4F6FA]">
                                <PiNote className="w-[15.2px] h-[19px] rotate-[180deg]" />
                              </div>
                            }
                            // trailing={
                            //   <div
                            //     className="text-[#E88E15] text-center uppercase comm-b-12 w-full cursor-pointer hover:underline"
                            //     onClick={() =>
                            //       navigate.push(`/view-report/${ticketId}`)
                            //     }
                            //   >
                            //     View report
                            //   </div>
                            // }
                            trailingStyle="basis-1/2 justify-end items-center"
                          />
                        </div>
                      </div>
                    )
                  : ''}
              </>
            </div>
          </ModalBody>
          {!isCallAnalyst && (
            <ModalFooter>
              <CustomButton
                customStyle="justify-center mt-4 !h-[45px] !text-[12px] !font-bold !rounded-[10px]"
                state={ButtonStates.warning}
                handleClick={() => {
                  setShowUpdateTicket(!showUpdateTicket);
                  onClose();
                }}
              >
                UPDATE TICKET STATUS
              </CustomButton>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

type InciddentDetailsProps = {
  isOpen: boolean;
  onClose: () => void;
  onOpenCallScriptModal?: () => void;
  status?: string;
  ticketId?: string;
  isViewingTicketDetails?: boolean;
  refetch?: any;
};
