import 'react-datepicker/dist/react-datepicker.css';

import theme from 'styles/theme';
import { ChakraProvider } from '@chakra-ui/react';
import { store } from './data/store';
import { KEY_LS_REQUESTED_ADDR } from './util/constants';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactQueryDevtools } from 'react-query/devtools';
import Pages from 'pages';
import AppContextProvider from 'global/AppContext';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js';
import { hotjar } from 'react-hotjar';

const RenderDevTool = () => {
  if (process.env.NODE_ENV === 'development') {
    return <ReactQueryDevtools initialIsOpen={false} />;
  }
  return null;
};

function App() {
  hotjar.initialize(3545923, 6);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 1000 * 600, // this is in milliseconds
        cacheTime: 10 * 60,
        retry: 0
      }
    }
  });
  store.save(KEY_LS_REQUESTED_ADDR, window.location.href);
  const [remaining, setRemaining] = React.useState<number>(0);

  const exemptPaths = ['/login', '/password-reset'];

  const onIdle = () => {
    if (!exemptPaths.includes(window.location.pathname)) {
      localStorage.removeItem('__maxadmntkn__');
      window.location.href = '/login?timeout=session_timeout';
      console.log(remaining, 'session timedout');
      return;
    }
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 14400_000,
    throttle: 500
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <AppContextProvider>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <ToastContainer />
          <ErrorBoundary>
            <Pages />
          </ErrorBoundary>
          <RenderDevTool />
        </QueryClientProvider>
      </ChakraProvider>
    </AppContextProvider>
  );
}

export default App;
