import { FC } from 'react';
import { Link } from 'react-router-dom';
import { SmallCloseIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spacer,
  Text
} from '@chakra-ui/react';

import { BaseModalProps } from 'components/BaseModal';
import { VehicleInformationBlock } from 'components/VehicleActivity/VehicleInformationBlock';
import { AssignedChampionBlock } from 'components/VehicleActivity/AssignedChampionBlock';
import { ContractDetailsBlock } from 'components/VehicleActivity/ContractDetailsBlock';
import { StatusDisplay } from 'components/StatusDisplay/StatusDisplay';

import CarIcon from 'assets/images/vehicle-activity/car-icon.svg';
import MotorcycleIcon from 'assets/images/vehicle-activity/motorcycle-icon.svg';
import TricycleIcon from 'assets/images/vehicle-activity/tricycle-icon.svg';

interface VehicleActivityProps extends BaseModalProps {
  vehicleInfo: any;
  championInfo: any;
  currentContractInfo: any;
  isGetChampionLoading: boolean;
}

export const VehicleActivityModal: FC<VehicleActivityProps> = (props) => {
  const { championInfo, currentContractInfo, isOpen, isGetChampionLoading, onClose, vehicleInfo } = props;
  const { city, manufacturer, max_vehicle_id, model, plate_number, trim, vehicle_id, vehicle_status, vehicle_type } =
    vehicleInfo;
  const { championName } = championInfo;

  const vehicleInformation = [
    {
      title: 'Location',
      value: city
    },
    {
      title: 'Manufacturer',
      value: manufacturer
    },
    {
      title: 'Trim',
      value: trim
    },
    {
      title: 'Model',
      value: model
    },
    {
      title: 'Asset Class',
      value: vehicle_type
    },
    {
      title: 'Plate Number',
      value: plate_number
    }
  ];

  let vehicleType = vehicle_type;

  const switchAvatarIcons = {
    Car: CarIcon,
    Tricycle: TricycleIcon,
    Motorcycle: MotorcycleIcon
  };

  const avatarIcon = switchAvatarIcons[vehicleType] || 'Car';

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent bg="#FAFAFA" px={1} py={2}>
        <ModalHeader>
          <Flex>
            <HStack spacing={3}>
              <Avatar src={avatarIcon} bg="#fae8d0" size="md" />
              <Box>
                <Text fontSize="1rem" fontWeight={600} color="#3E3E3E">
                  {vehicle_type} - {manufacturer}
                </Text>
                <Text fontSize="xs" color="#808080">
                  {max_vehicle_id}
                </Text>
              </Box>
              <Flex justifyContent="center">
                <StatusDisplay borderRadius="none" status={vehicle_status} fontSize="12px" />
              </Flex>
            </HStack>
            <Spacer />
            <SmallCloseIcon onClick={onClose} />
          </Flex>
        </ModalHeader>

        <ModalBody mb={4}>
          <Grid h="350px" templateRows="repeat(12, 1fr)" templateColumns="repeat(12, 1fr)" gap={4}>
            <GridItem
              rowStart={1}
              rowEnd={8}
              colStart={1}
              colEnd={7}
              bg="#fff"
              border="1px solid #EEEEEE"
              borderRadius="10px"
            >
              <VehicleInformationBlock title="Vehicle Information" vehicleInformation={vehicleInformation} />
            </GridItem>
            <GridItem
              rowStart={8}
              rowEnd={13}
              colStart={1}
              colEnd={7}
              bg="#fff"
              border="1px solid #EEEEEE"
              borderRadius="10px"
            >
              <AssignedChampionBlock
                championName={championName}
                vehicleID={vehicle_id}
                title="Assigned Champion"
              />
            </GridItem>
            <GridItem rowSpan={12} colStart={7} colEnd={13} bg="#fff" border="1px solid #EEEEEE" borderRadius="10px">
              <ContractDetailsBlock
                currentContractInfo={currentContractInfo}
                isLoading={isGetChampionLoading}
                title="Current Contract Details"
                trancheTitle="Active Tranche"
                vehicleID={vehicle_id}
              />
            </GridItem>
          </Grid>
          <Box align="center" mt={4}>
            <Link to={`/vehicle-activity/vehicle/detail/${vehicle_id}`}>
              <Button size="sm">View More Details</Button>
            </Link>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
