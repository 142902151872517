import { Table, Thead, Tbody, Tr, Th, TableContainer } from '@chakra-ui/react';
import './BasicTable.css'; // Import your CSS file

export default function BasicTable({ header, children, appendClass }: BasicTableProps) {
  return (
    <TableContainer className={`bg-white basic-table ${appendClass ? appendClass : ''}`}>
      <Table size="sm">
        <Thead className="custom-thead font-medium"> {/* Add custom class for styling */}
          <Tr>
            {header.map((item, index) => (
              <Th key={index}>{item.title}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className='custom-tbody'>{children}</Tbody>
      </Table>
    </TableContainer>
  );
}

interface BasicTableProps {
  children: React.ReactNode | JSX.Element;
  appendClass?: string;
  header: {
    title: string;
    trailing?: JSX.Element;
  }[];
}
