import { FC } from 'react';
import { Box, Button, Flex, Grid, GridItem, Text, useDisclosure } from '@chakra-ui/react';
import Moment from 'react-moment';
import { nanoid } from 'nanoid';

import { useMaintenanceContext } from 'pages/maintenance/context';

import { StatsContainer } from 'components/stats/StatsContainer';
import { StatusDisplay } from 'components/StatusDisplay/StatusDisplay';
import { StatusChangeModal } from './StatusChangeModal';

interface JobCardTabI {
  refetch: any;
}

export const JobCardTab: FC<JobCardTabI> = ({ refetch }) => {
  const { jobCardDetails, pastQaTesters, pastTechnicians, techniciansReport, testersReport, vehicleData } =
    useMaintenanceContext();

  const {
    isOpen: isOpenStatusChangeModal,
    onOpen: onOpenStatusChangeModal,
    onClose: onCloseStatusChangeModal
  } = useDisclosure();

  const currentTester = pastQaTesters[pastQaTesters?.length - 1];
  const currentTechnician = pastTechnicians[pastTechnicians.length - 1];

  const isVehicleNotAssigned =
    vehicleData?.maintenance_schedule?.is_technician_assigned === 'no' ||
    vehicleData?.maintenance_schedule?.is_qa_tester_assigned === 'no';

  const completedJobCardLogEndDate =
    jobCardDetails[0]?.job_card_status_logs && new Date(jobCardDetails[0]?.job_card_status_logs[0]?.created_at);

  const completedJobCardLogStartDate =
    jobCardDetails[0]?.job_card_status_logs &&
    new Date(jobCardDetails[0]?.job_card_status_logs[jobCardDetails[0]?.job_card_status_logs?.length - 1]?.created_at);
  console.log('jobdetails:', jobCardDetails[0]);

  return (
    <>
      {isVehicleNotAssigned ? (
        <Text color="hsla(0, 0%, 24%, 1)" px={10} py={4} minH="500px">
          Please assign a Technician and QA Tester
        </Text>
      ) : (
        <Grid templateColumns="repeat(6, 1fr)" gap={5} px={4}>
          <GridItem colStart={1} colEnd={3}>
            <StatsContainer title="Job ID">
              <Text color="hsla(0, 0%, 17%, 1)" fontSize="0.875rem" px={5} py={5}>
                {jobCardDetails[0]?.job_code ?? 'N/A'}
              </Text>
            </StatsContainer>
          </GridItem>
          <GridItem colStart={3} colEnd={7}>
            <StatsContainer title="Status">
              <Flex alignItems="center" justifyContent="space-between" px={5} py="10px">
                {jobCardDetails[0]?.status ? (
                  <StatusDisplay borderRadius="none" status={jobCardDetails[0]?.status} fontSize="12px" />
                ) : (
                  'N/A'
                )}

                <Box>
                  <Text color="hsla(0, 0%, 24%, 0.5)" fontSize="0.8125rem">
                    Timer 001
                  </Text>
                  <Text color="hsla(218, 17%, 62%, 1)" fontSize="0.875rem">
                    {jobCardDetails[0]?.status === 'completed' ? (
                      <Moment
                        duration={new Date(completedJobCardLogStartDate)}
                        format="Y[ Year],M[ Month],D[ Day],H[ Hour],m[ Min]"
                        date={new Date(completedJobCardLogEndDate)}
                      />
                    ) : (
                      <Moment fromNow>{jobCardDetails[0]?.maintenance_start_time}</Moment>
                    )}
                  </Text>
                </Box>
                {jobCardDetails[0]?.status !== 'completed' && (
                  <Button
                    bgColor="hsla(218, 14%, 89%, 1)"
                    borderRadius="2px"
                    fontSize="0.75rem"
                    fontWeight="semibold"
                    onClick={onOpenStatusChangeModal}
                    px={6}
                    py={2}
                    isDisabled={!jobCardDetails[0]?.job_code}
                  >
                    CHANGE STATUS
                  </Button>
                )}
              </Flex>
            </StatsContainer>
          </GridItem>
          <GridItem colStart={1} colEnd={4}>
            <StatsContainer title="Technician">
              <Box alignItems="center" gap={4} justifyContent="space-between" px={6} py={4}>
                <Text color="hsla(0, 0%, 24%, 0.5)" fontSize="0.8125rem">
                  Name
                </Text>
                <Text color="hsla(0, 0%, 24%, 1)">
                  {pastTechnicians.length > 0
                    ? `${currentTechnician?.user?.first_name || ''} ${currentTechnician?.user?.last_name || ''}`
                    : 'Not Applicable'}
                </Text>
              </Box>
            </StatsContainer>
          </GridItem>
          <GridItem colStart={4} colEnd={7}>
            <StatsContainer title="QA Tester">
              <Box px={6} py={4}>
                <Text color="hsla(0, 0%, 24%, 0.5)" fontSize="0.8125rem">
                  Name
                </Text>
                <Text color="hsla(0, 0%, 24%, 1)" fontSize="0.875rem">
                  {pastQaTesters.length > 0
                    ? `${currentTester?.user?.first_name || ''} ${currentTester?.user?.last_name || ''}`
                    : 'Not Applicable'}
                </Text>
              </Box>
            </StatsContainer>
          </GridItem>
          <GridItem colStart={1} colEnd={4}>
            <StatsContainer title="QA Flagged Things To Fix">
              <Box maxH="500px" overflowY="scroll">
                <Grid templateColumns="repeat(2, 1fr)" gap={3} maxH="150px" overflowY="scroll" px={6} py="19px">
                  {!techniciansReport?.checklist_metadata && (
                    <GridItem>
                      <Text color="#3E3E3E" fontSize="0.875rem">
                        Not Applicable
                      </Text>
                    </GridItem>
                  )}
                  {testersReport?.checklist_metadata
                    ?.filter((item) => item?.currentStatus?.status === 'bad')
                    ?.map((item) => (
                      <GridItem key={nanoid()}>
                        <Text color="#3E3E3E" fontSize="0.875rem">
                          - {item?.name}
                        </Text>
                      </GridItem>
                    ))}
                </Grid>
              </Box>
            </StatsContainer>
          </GridItem>
          <GridItem colStart={4} colEnd={7}>
            <StatsContainer title="Technician's Comments">
              <Box height="92px" overflowY="scroll" px={6} py="10px">
                <Text color="hsla(0, 0%, 24%, 1)" fontSize="0.875rem">
                  {jobCardDetails[0]?.comment ?? 'N/A'}
                </Text>
              </Box>
            </StatsContainer>
          </GridItem>
          <GridItem colStart={1} colEnd={7}>
            <StatsContainer title="Status History">
              <Box maxH="300px" overflowY="scroll">
                {!jobCardDetails[0]?.job_card_status_logs?.length ? (
                  <Text color="hsla(0, 0%, 24%, 1)" fontSize="0.875rem" px={10} py={4}>
                    The vehicle has no previous status history
                  </Text>
                ) : (
                  jobCardDetails[0]?.job_card_status_logs?.map((log) => (
                    <Grid templateColumns="repeat(4, 1fr)" gap={10} key={nanoid()} px={10} py={4}>
                      <Box py={5}>
                        <StatusDisplay borderRadius="none" status={log?.new_status} fontSize="12px" />
                      </Box>
                      <Box>
                        <Text color="hsla(0, 0%, 24%, 0.5)" fontSize="0.8125rem">
                          Time
                        </Text>
                        <Text color="hsla(0, 0%, 24%, 1)">
                          <Moment date={log?.created_at} format="Do MMMM YYYY h:mm:ss a" />
                        </Text>
                      </Box>
                      <Box>
                        <Text color="hsla(0, 0%, 24%, 0.5)" fontSize="0.8125rem">
                          Reason
                        </Text>
                        <Text color="hsla(0, 0%, 24%, 1)">{log?.reason}</Text>
                      </Box>
                      <Box>
                        <Text color="hsla(0, 0%, 24%, 0.5)" fontSize="0.8125rem">
                          Timer
                        </Text>
                        <Text color="hsla(218, 17%, 62%, 1)">
                          <Box as="span" color="hsla(0, 0%, 24%, 1)">
                            {log?.duration_in_minute}
                          </Box>
                          (minutes)
                        </Text>
                      </Box>
                    </Grid>
                  ))
                )}
              </Box>
            </StatsContainer>
          </GridItem>
        </Grid>
      )}
      <StatusChangeModal isOpen={isOpenStatusChangeModal} onClose={onCloseStatusChangeModal} refetch={refetch} />
    </>
  );
};
