import React, { useEffect, useState } from 'react';
import { PlusCircleIcon } from '@heroicons/react/solid';
import { PencilAltIcon } from '@heroicons/react/outline';
import { nanoid } from 'nanoid';
import { Avatar, Button, Grid, Switch as ToggleButton, Box, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { format } from 'date-fns';
import { TiDocumentAdd } from 'react-icons/ti';
import { MdDownload } from 'react-icons/md';
import { toast } from 'react-toastify';
import { useQuery, useQueryClient } from 'react-query';
import { HiPlusCircle } from 'react-icons/hi';
import Swal from 'sweetalert2';

import { QUERY_KEYS } from 'util/constants';
import { patchRequest, postRequest, useMutationWrapper, useQueryWrapper } from 'services/apiHelper';
import { userRequest } from 'services/request';
import axiosInstance from 'services/httpService';
import { convertParamsToString } from 'helpers/auth-helpers';
import { KEY_LS_MAX_USER_VAMS } from 'util/constants';

import emtpyState from 'assets/images/files-empty.svg';

import './user-management.scss';

import { ActionBar } from 'components/ActionBar/ActionBar';
import CustomTable from 'components/BasicTable';
import FilterContainer from 'components/filter-container/filter-container.component';
import Modal from 'components/BaseModal';
import PageWrapper from 'components/page-wrapper';
import GridLayout from 'components/page-wrapper/GridLayout';
import SearchBox from 'components/Search';
import Select from 'components/custom-select';
import { reshapeDataForSelect } from 'pages/vehicle-assignment/convertToFormat';
import { getAllCities } from 'util/filtersHelper';
import { FaTrash } from 'react-icons/fa';

const FormItem = (props: any) => {
  const { index, onDelete, showDelete, userRoles } = props;

  const allCities = useQuery([QUERY_KEYS.ALL_CITIES], getAllCities);

  return (
    <Grid templateColumns="repeat(4,1fr)" gap="1rem">
      <div>
        <label htmlFor="email">Email</label>
        <Field
          as="input"
          type="email"
          name={`userSchema[${index}].email`}
          className="ug__modal__content-form__field-input"
        />
        <ErrorMessage name="email" component="div" className="form_error_message" />
      </div>
      <div>
        <div>
          <label htmlFor="assignRole">Assign Roles</label>
          <Field
            as="select"
            name={`userSchema[${index}].assignRole`}
            id="assignRole"
            className="ug__modal__content-form__field-input"
          >
            <option value="" label="- Select a role -" />
            {userRoles.map((role) => (
              <option key={nanoid()} value={role.id}>
                {role.name}
              </option>
            ))}
          </Field>
          <ErrorMessage name="assignRole" component="div" className="form_error_message" />
        </div>
      </div>
      <div>
        <div>
          <label htmlFor="city">Select City</label>
          <Field
            as="select"
            name={`userSchema[${index}].city`}
            id="city"
            className="ug__modal__content-form__field-input"
          >
            <option value="" label="- Select a City -" />
            {allCities.data?.map((role) => (
              <option key={nanoid()} value={role.value}>
                {role.name}
              </option>
            ))}
          </Field>
          <ErrorMessage name="city" component="div" className="form_error_message" />
        </div>
      </div>
      <div className="icon">
        {showDelete && (
          <button type="button" onClick={onDelete}>
            <FaTrash color="#EA5D5D" />
          </button>
        )}
      </div>
    </Grid>
  );
};
export const UserManagement: React.FC = () => {
  const USER_MGT_TABLE_COLUMNS = [
    {
      Header: 'User',
      accessor: 'first_name',
      Cell: (value: any) => {
        const firstName = value.row.original.first_name;
        const lastName = value.row.original.last_name;
        return (
          <div>
            {firstName} {lastName}
          </div>
        );
      }
    },
    {
      Header: 'Job Role',
      accessor: 'description'
    },
    {
      Header: 'Site Role',
      accessor: 'name'
    },
    {
      Header: 'Date Added',
      accessor: (item) => {
        return format(new Date(item.created_at), 'hh:mma   dd-MM-yyyy');
      }
    },

    {
      Header: 'Status',
      accessor: (item) => {
        return (
          <p
            className={
              item.status.toLowerCase() === 'active'
                ? 'ug__profile-summary__active-info__actions-badge-activated'
                : 'ug__profile-summary__active-info__actions-badge-deactivated'
            }
            style={{ textAlign: 'center' }}
          >
            {item.status}
          </p>
        );
      }
      //
    }
  ];
  const [searchText, setSearchText] = useState('');
  const queryClient = useQueryClient();
  const allCities = useQuery([QUERY_KEYS.ALL_CITIES], getAllCities);
  const [updateUser, setupdateUser] = useState({
    city: '',
    role: '',
    email: ''
  });
  const [userSchema, setUserSchema] = useState([
    {
      id: nanoid(),
      email: '',
      assignRole: '',
      city: ''
    }
  ]);

  const { data: allUserRoles } = useQueryWrapper(QUERY_KEYS.ALL_ROLES, userRequest.GET_USER_ROLES);
  const onDelete = (index: any) => {
    if (userSchema.length > 1) {
      const currentValue = [...userSchema];
      currentValue.splice(index, 1);
      setUserSchema(currentValue);
    }
  };
  const [statusMessage, setStatusMessage] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentActiveUser, setCurrentActiveUser] = useState<any>();
  const [showUpdateUserForm, setShowUpdateUserForm] = useState<boolean>(false);
  const [formArray, setFormArray] = useState<Array<any>>([]);

  const searchUser = async (params: any) => {
    const { queryKey } = params;

    const url = convertParamsToString(userRequest.GET_ALL_USERS_PAGINATED, {
      pageNo: '',
      pageSize: '',
      search: queryKey[1]
    });
    const response = await axiosInstance.get(url);
    return response.data.data;
  };

  // const allUsers = useQueryWrapper(QUERY_KEYS.GET_ALL_USERS, userRequest.GET_ALL_USERS_PAGINATED);
  const allUsers = useQuery([QUERY_KEYS.GET_ALL_USERS, searchText], searchUser);
  const openEditUserModal = () => {
    setShowUpdateUserForm(true);
    setupdateUser({
      ...updateUser,
      email: currentActiveUser?.email,
      role: currentActiveUser?.role_id
    });
  };

  const addMoreUsers = () => {
    if (userSchema.length <= 4) {
      const newValues = [...userSchema];
      newValues.push({ id: nanoid(), email: '', assignRole: '', city: '' });

      setUserSchema(newValues);
      setFormArray([...formArray]);
    }
  };

  const setUserDetails = (user: any) => {
    setCurrentActiveUser(user);
  };
  const handleModalToggleDisplay = () => {
    setShowModal(!showModal);
  };
  const onSuccess = () => {
    toast.success('You have successfully added a user');
    queryClient.invalidateQueries(QUERY_KEYS.GET_ALL_USERS);
    setShowModal(false);
  };
  const onSuccessAddMultiple = (response) => {
    queryClient.invalidateQueries(QUERY_KEYS.GET_ALL_USERS);
    setShowModal(false);
    response.message.map((msg) => toast.info(msg), {
      autoClose: false
    });
  };
  const { mutate } = useMutationWrapper(postRequest, onSuccess);
  const { mutate: addMultiple } = useMutationWrapper(postRequest, onSuccessAddMultiple);
  const handleCreateUserSubmit = ({ userSchema }: any) => {
    let isDataValid = true;
    userSchema.map((user) => {
      if (user.email === '' || user.assignRole === '') {
        isDataValid = false;
        return toast.error('Please fill all fields');
      }
      return true;
    });
    if (!isDataValid) {
      return;
    }
    if (userSchema.length === 1) {
      return mutate({
        url: userRequest.ADD_NEW_USER,
        data: {
          email: userSchema[0].email,
          role_id: userSchema[0].assignRole,
          city_id: userSchema[0].city
        }
      });
    }
    const sendMultipleUsers = userSchema.map((user) => {
      return {
        email: user.email,
        role_id: user.assignRole,
        city_id: user.city
      };
    });
    addMultiple({
      url: userRequest.ADD_MULTIPLE_USERS,
      data: { users: sendMultipleUsers }
    });
  };
  const onUpdateSuccess = () => {
    toast.success('User updated successfully');
    queryClient.invalidateQueries(QUERY_KEYS.GET_ALL_USERS);
    setShowUpdateUserForm(false);
  };
  const [checkedState, setcheckedState] = useState(currentActiveUser?.status.toLowerCase() === 'active');
  useEffect(() => {
    setcheckedState(currentActiveUser?.status.toLowerCase() === 'active');
  }, [currentActiveUser]);
  const { mutate: sendStatusToAPI } = useMutationWrapper(postRequest, onUpdateSuccess);
  const userId = JSON.parse(localStorage.getItem(KEY_LS_MAX_USER_VAMS) || '').user_account_id;

  const updateUserStatus = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to change a user status',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!'
    }).then((result) => {
      if (result.isConfirmed) {
        sendStatusToAPI({
          url: userRequest.UPDATE_USER_STATUS,
          data: {
            user_id: currentActiveUser.user_account_id,
            status: checkedState ? 'inactive' : 'active',
            auth_user_id: userId
          }
        });
        setcheckedState(!checkedState);
        setStatusMessage(!statusMessage);
      }
    });
  };

  const { mutate: sendUpdateInfoToAPI, isLoading: updateUserLoading } = useMutationWrapper(
    patchRequest,
    onUpdateSuccess
  );

  const handleUpdateUser = () => {
    sendUpdateInfoToAPI({
      url: userRequest.UPDATE_USER_ROLE,
      data: {
        email: updateUser.email,
        role_id: updateUser.role,
        city_id: updateUser.city,
        auth_user_id: userId
      }
    });
  };
  const [isBulkAdd] = useState(false);
  const handleAdduser = () => {
    if (userSchema.length < 3) {
      addMoreUsers();
    }
  };
  const handleDownload = () => {
    //TODO: download the template
  };

  return (
    <>
      <FilterContainer pageName="User Management">
        <SearchBox onChange={(e) => setSearchText(e.target.value)} />
      </FilterContainer>
      <PageWrapper>
        <div className="page-holder">
          <ActionBar>
            <div>
              <Button leftIcon={<HiPlusCircle fontSize="16px" />} onClick={handleModalToggleDisplay}>
                Add New User
              </Button>
            </div>
          </ActionBar>
          <GridLayout>
            <div className="table-wrapper">
              <CustomTable
                data={allUsers.data?.users}
                selectedRow={setUserDetails}
                columns={USER_MGT_TABLE_COLUMNS}
                loading={allUsers.isLoading}
              />
            </div>

            <aside className="ug__profile-summary__container">
              {!currentActiveUser ? (
                <div className="empty-holder">
                  <img src={emtpyState} alt="emtpy" />
                  <p className="empty-text">Select User from the list to see more details.</p>
                </div>
              ) : (
                <Box>
                  <div className="ug__profile-summary__active-wrapper">
                    <div className="ug__profile-summary__active-info__main">
                      <div className="ug__profile-summary__active-info__main-edit">
                        <PencilAltIcon width={20} onClick={openEditUserModal} />
                      </div>
                      <div className="ug__profile-summary__active-info__main-avatar">
                        <Avatar src="https://via.placeholder.com/150" h="100px" w="100px" mb="2" />
                      </div>
                      <p className="ug__profile-summary__active-info__name">
                        {currentActiveUser?.first_name} {currentActiveUser?.last_name}
                      </p>
                      <p>{currentActiveUser?.email}</p>
                    </div>
                    <Box className="ug__profile-summary__active-info__others" px="4" py="2" my="4">
                      <div className="p-2 pl-4">
                        <small className="">Job Role</small>
                        <p>{currentActiveUser?.description}</p>
                      </div>
                    </Box>
                    {currentActiveUser && (
                      <Box className="ug__profile-summary__active-info__actions">
                        <ToggleButton
                          colorScheme="teal"
                          size="lg"
                          onChange={updateUserStatus}
                          isChecked={checkedState}
                        />
                        <p
                          className={
                            checkedState
                              ? 'ug__profile-summary__active-info__actions-badge-activated'
                              : 'ug__profile-summary__active-info__actions-badge-deactivated'
                          }
                        >
                          {checkedState ? 'Activated' : 'Deactivated'}
                        </p>
                      </Box>
                    )}
                  </div>
                </Box>
              )}
            </aside>
          </GridLayout>
        </div>
        <Modal
          isOpen={showModal}
          title="Add New User"
          subTitle="Add a new user from our service"
          onClose={handleModalToggleDisplay}
          size="2xl"
        >
          <div className="ug__modal">
            <main className="ug__modal__content">
              {isBulkAdd ? (
                <div className="upload-view">
                  <h5>To add multiple vehicles at once, upload a CSV file</h5>
                  <div className="doc-holder">
                    <TiDocumentAdd size="40px" fontWeight="300px" />
                  </div>
                  <div className="btn-holder mt-4 mx-auto">
                    <Button btnBackgroundColor="#FFCC00" btnTextColor="#000" onClick={handleDownload}>
                      <MdDownload className="mr-4" size="20px" />
                      Download template
                    </Button>
                  </div>
                </div>
              ) : (
                <Formik
                  initialValues={{
                    userSchema
                  }}
                  onSubmit={handleCreateUserSubmit}
                >
                  {() => (
                    <Form>
                      {userSchema.map((data: any, index: any) => (
                        <FormItem
                          userRoles={allUserRoles}
                          onDelete={() => onDelete(index)}
                          index={index}
                          showDelete={userSchema.length > 1}
                        />
                      ))}
                      <Button
                        disabled={userSchema.length > 2}
                        type="button"
                        mt="4"
                        variant="secondaryOutline"
                        onClick={handleAdduser}
                        leftIcon={<PlusCircleIcon className="mr-2" width={20} style={{ fontSize: '18px' }} />}
                      >
                        Add New User
                      </Button>
                      <Box as="footer" mt="12">
                        <Button type="submit">Submit</Button>
                        <Button ml="4" variant="secondary" onClick={handleModalToggleDisplay}>
                          Cancel
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )}
            </main>
          </div>
        </Modal>
        <Modal
          isOpen={showUpdateUserForm}
          title="Edit User Role"
          size="2xl"
          subTitle="Update the current users permissions"
          onClose={() => setShowUpdateUserForm(false)}
        >
          <Box>
            <Grid templateColumns="repeat(3,1fr)" gap="1rem">
              <FormControl id="email">
                <FormLabel mb={0}>Email address</FormLabel>
                <Input disabled value={currentActiveUser?.email} />
              </FormControl>
              <FormControl id="role">
                <FormLabel mb={0}>Role</FormLabel>
                <Select
                  placeholder="Select Role"
                  onChange={(option) =>
                    setupdateUser({
                      ...updateUser,
                      role: option.value
                    })
                  }
                  fullWidth
                  defaultValue={{
                    label: allUserRoles?.find((role) => role.id === currentActiveUser?.role_id)?.name,
                    value: currentActiveUser?.role_id
                  }}
                  options={reshapeDataForSelect(allUserRoles, 'name', 'id')}
                />
              </FormControl>
              <FormControl id="city">
                <FormLabel mb={0}>City</FormLabel>
                <Select
                  placeholder="Select City"
                  onChange={(option) =>
                    setupdateUser({
                      ...updateUser,
                      city: option?.value
                    })
                  }
                  fullWidth
                  options={allCities.data}
                  defaultValue={{
                    label: allCities.data?.find((city) => city.value === currentActiveUser?.city_id)?.name,
                    value: currentActiveUser?.city_id
                  }}
                />
              </FormControl>
            </Grid>
            <Box mt="8">
              <Button onClick={() => handleUpdateUser()} isLoading={updateUserLoading}>
                Update User
              </Button>
              <Button
                disabled={updateUserLoading}
                ml="4"
                variant="secondary"
                onClick={() => setShowUpdateUserForm(false)}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      </PageWrapper>
    </>
  );
};
