import { FC, useState } from 'react';
import { Avatar, Box, Center, Flex, Td, Text, Tr, useDisclosure } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import Moment from 'react-moment';

import { maintenanceRequest, reportRequest } from 'services/request';
import axiosInstance from 'services/httpService';
import { convertParamsToString } from 'helpers/auth-helpers';
import { QUERY_KEYS } from 'util/constants';
import { useMaintenanceContext } from 'pages/maintenance/context';

import ChampionHelmetIcon from 'assets/images/vehicle-activity/champion-helmet.svg';

import { Table } from 'components/Maintenance/Table';
import { ReportHistoryModal } from '../MaintenanceReportTab/ReportHistoryModal';

const tableHeaders = [
  { title: 'Champion Name' },
  { title: 'Location' },
  { title: 'Checkin Type' },
  { title: 'Checkin Date/Time' },
  { title: 'Odometer' },
  { title: 'KM Covered' }
];

export const ReportHistoryTab: FC = () => {
  const [page] = useState(1);
  const [size] = useState(10);
  const [vehId, setVehId] = useState('');

  const { isOpen, onClose } = useDisclosure();

  const { vehicleData } = useMaintenanceContext();

  const getMaintenanceReportHistory = async ({ queryKey }) => {
    try {
      const url = convertParamsToString(maintenanceRequest.REPORT_HISTORY_BY_ID, {
        maintenance_vehicle_id: queryKey[1]
      });
      const res = await axiosInstance.get(url);
      return res?.data?.data?.results;
    } catch (err) {
      console.error('Maintenance report history::', err);
    }
  };

  const getVehicleReportHistoryByID = async ({ queryKey }) => {
    try {
      const url = convertParamsToString(maintenanceRequest.MAINTENANCE_SCHEDULE_HISTORY_BY_VEHICLE_ID, {
        vehicleID: queryKey[1],
        page: queryKey[2],
        size: queryKey[3]
      });
      const res = await axiosInstance.get(url);
      return res?.data?.data?.results[0];
    } catch (err) {
      console.error('Vehicle report history by ID::', err);
    }
  };

  const getAllLocations = async () => {
    try {
      const url = reportRequest.ALL_SUB_CITIES;
      const res = await axiosInstance.get(url);
      return res?.data?.payload?.message?.objects;
    } catch (err) {
      console.error('Report tab locations::', err);
    }
  };

  const getVehicleID = (vehicleId) => {
    setVehId(vehicleId);
    // onOpen();
  };

  function findVehicleLocation(arr: any[], vehicleId) {
    if (!arr?.length) return;

    const location = arr?.find((location) => location?.id === vehicleId)?.name;
    return location;
  }

  const { data: reportHistory, isLoading: isLoadingReportHistory }: any = useQuery(
    [QUERY_KEYS.MAINTENANCE_REPORT_HISTORY, vehicleData?.vehicle_id],
    getMaintenanceReportHistory,
    {
      enabled: !!vehicleData?.vehicle_id
    }
  );

  const { data: reportHistoryById, isLoading: isLoadingReportHistoryById }: any = useQuery(
    [QUERY_KEYS.MAINTENANCE_REPORT_HISTORY_BY_ID, vehId, page, size],
    getVehicleReportHistoryByID,
    {
      enabled: !!vehId
    }
  );

  const { data: locationData }: any = useQuery(QUERY_KEYS.LOCATIONS, getAllLocations, {
    enabled: !!vehicleData?.vehicle_id
  });

  return (
    <Box px={4}>
      <Box height="700px" overflow="hidden" position="relative" background="#fff" width="100%">
        <Box height="100%" paddingBottom="81px" overflow="scroll">
          {isLoadingReportHistory ? (
            <Center pt={10}>Loading...</Center>
          ) : (
            <Table
              columns={tableHeaders}
              tableData={reportHistory?.map((data) => (
                <Tr cursor="pointer" fontSize="14px" key={data?.id} onClick={() => getVehicleID(data?.vehicle_id)}>
                  <Td>
                    <Flex alignItems="center" gap={3}>
                      <Avatar bg="#fae8d0" src={ChampionHelmetIcon} />
                      <Box>
                        <Text color="#3E3E3E" fontWeight="semibold">
                          {data?.champion?.first_name} {data?.champion?.last_name}
                        </Text>
                        <Text color="#E88E15" fontSize="0.75rem">
                          {data?.champion?.max_champion_id}
                        </Text>
                      </Box>
                    </Flex>
                  </Td>
                  <Td>{findVehicleLocation(locationData, data?.maintenance?.location_id)}</Td>
                  <Td>{data?.maintenance?.status}</Td>
                  <Td>
                    <Moment format="DD/MM/YYYY hh:mm">{data?.start_date}</Moment>
                  </Td>
                  <Td>{data?.maintenance?.odometer?.toLocaleString()}</Td>
                  <Td>{data?.maintenance?.distance?.toLocaleString()}</Td>
                </Tr>
              ))}
            />
          )}
        </Box>
      </Box>
      <ReportHistoryModal
        isLoading={isLoadingReportHistoryById}
        isOpen={isOpen}
        onClose={onClose}
        modalData={reportHistoryById}
      />
    </Box>
  );
};
