import { useState, useEffect } from 'react';
// import { FaFileDownload } from 'react-icons/fa';
import styled from '@emotion/styled';
import { FILTER_CONSTANT } from 'util/constants';
import AsyncCustomSelect from 'components/custom-select/AsyncSelect';
import FilterContainer from 'components/filter-container/filter-container.component';
import CustomSelect from 'components/custom-select';
// import { CustomButton } from 'components/custom-button/custom-button';
import './vehicles-nav.styles.scss';

type filterData = {
  allBatch: Array<any>;
  // allLocations: Array<any>;
  allVehicleTypes: Array<any>;
};

type ReadyForActivationNavProps = {
  handleDownload: () => void;
  handleDownloadAll: () => void;
  filterHandler: (filterName: string, filterValue: string) => void;
  filterData: filterData;
  hideButton?: boolean;
  getSubCitiesWithSearch: (value: string) => void;
};

const ReadyForActivationNav = (props: ReadyForActivationNavProps) => {
  // TODO Return handleDownload, handleDownloadAll back when its fixed
  const { filterHandler, filterData, hideButton, getSubCitiesWithSearch } = props;
  const [locationFilter, setlocationFilter] = useState('');
  const [vehicleTypes, setvehicleTypes] = useState('');

  // const [showExport, setshowExport] = useState(false);
  //TODO: We need to refactor/merge all instances of the getManufactures function

  const onLocationChange = (option: any) => {
    if (option) {
      setlocationFilter(option.name);
    } else {
      setlocationFilter('');
    }
  };
  const onVehicleTypeChange = (option: any) => {
    if (option) {
      setvehicleTypes(option.name);
      // filterHandler(FILTER_CONSTANT.VEHICLE_TYPE, option.value);
    } else {
      setvehicleTypes('');
    }
  };

  useEffect(() => {
    filterHandler(FILTER_CONSTANT.LOCATION, locationFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationFilter]);

  useEffect(() => {
    filterHandler('vehicle_type', vehicleTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleTypes]);

  return (
    <ReadyForActivationNav.Wrapper>
      <div>
        <FilterContainer pageName="Vehicles Ready For Activation">
          {/* <SearchBox
            isDisabled={hideButton}
            placeholder="Manufacturers"
            searchOptions={data}
            onChange={manufactureChanged}
          /> */}
          <AsyncCustomSelect
            onChange={onLocationChange}
            loadOptions={getSubCitiesWithSearch}
            placeholder="All Sub Cities"
            name="All Locations"
            isDisabled={hideButton}
            cacheOptions
            defaultOptions
            onInputChange={(data) => {
              console.log(data);
            }}
          />

          <CustomSelect
            placeholder="All Vehicle Types"
            onChange={onVehicleTypeChange}
            options={[...filterData.allVehicleTypes]}
            name="All Vehicle Types"
            isDisabled={hideButton}
          />

          {/* <div className="download-holder">
            <CustomButton
              className={` ${hideButton ? 'hide-buttons' : ''}`}
              onClick={() => setshowExport(!showExport)}
              leftIcon={<FaFileDownload className="mr-4" style={{ fontSize: '18px' }} />}
              borderColor="#A9A9A9"
              backgroundColor="#ffcc00"
              hoverBgColor="#3d3d3d"
              hoverColor="#fff"
              children="Export"
            />

            {showExport && (
              <div className="download-option">
                <button
                  onClick={() => {
                    handleDownload();
                    setshowExport(false);
                  }}
                >
                  Current View
                </button>
                <button
                  onClick={() => {
                    handleDownloadAll();
                    setshowExport(false);
                  }}
                >
                  All View
                </button>
              </div>
            )}
          </div> */}
        </FilterContainer>
      </div>
    </ReadyForActivationNav.Wrapper>
  );
};
ReadyForActivationNav.Wrapper = styled.div`
  .ui.icon.input.search-input > input {
    height: 38px;
    border: 1px solid #dbe3ee;
  }
  .download-holder {
    position: relative;
    .download-option {
      position: absolute;
      margin-top: 0.5rem;
      background-color: white;
      border-radius: 5px;
      z-index: 10;
      button {
        display: block;
        font-size: 14px;
        padding: 1rem;
        width: 100%;
        &:hover {
          background-color: #fab52a;
          color: #fff;
        }
      }
    }
  }
`;
export default ReadyForActivationNav;
