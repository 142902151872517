import React, { useState } from 'react';
import style from './index.module.scss';
import Search from './search';
import { SearchIcon } from '@chakra-ui/icons';
// import { useQueryContext } from '../../contexts/query-context';

export const SearchFilterWrapper = ({
  title,
  toggleBlock,
  filterBlock,
  // hasDateFilter,
  searchPlaceholder,
  filterTitle,
  exportBlock,
  setSearchQuery
}) => {
  const [showSearch, setShowSearch] = useState(false);

  const handleSearchSubmit = (searchValue) => {
    setSearchQuery(searchValue);
  };
  return (
    <div className={style['wrapper']}>
      <div className={style['title-wrapper']}>
        <span className={style['titles']}>{title}</span>
      </div>

      <div className={style['filter-wrapper']}>
        {toggleBlock && <div className={style['toggle-block']}>{toggleBlock}</div>}

        {showSearch && (
          <Search
            handleSubmit={handleSearchSubmit}
            handleCloseSearch={() => {
              setSearchQuery('');
              setShowSearch(!showSearch);
            }}
            placeholder={searchPlaceholder || 'Search Table'}
            className
          />
        )}

        {/* <span className={style['titles']}>{filterTitle || 'Filter Table'}</span> */}
        {filterBlock && <div className={style['filter-block']}>{filterBlock}</div>}

        <div className={style['search-icon-wrapper']}>
          <button onClick={() => setShowSearch(!showSearch)}>
            <SearchIcon />
          </button>
        </div>
      </div>

      {exportBlock && <div className={style['export-as-csv-wrapper']}>{exportBlock}</div>}
    </div>
  );
};
