import styled from 'styled-components';

const SelectContainer = styled.div`
  border-left:2px solid #EEEEEE;
  padding:10px 12px;
  display:flex;
  align-items:center;
   .select__option__container { 
     margin-right:22px;
   }
   .country__text {
     font-family:'TT Medium';
     font-size:14px;
     color:#808080;
   }
   .country__option__select {
     font-family:Montserrat;
     font-weight:700;
     font-size:18px;
     color:#3E3E3E;
   }
   .country__select__button {
     padding:6px;
     background-color:#F2F2F2;
     border-radius:5px;
     color:#3E3E3E;
     font-family:'TT Bold';
     font-weight:700;
     font-size:10px;
     text-transform:uppercase;
     cursor:pointer;
     display:flex;
     align-items:center;
   }
   .country__select__button__icon {
     margin-left:4px;
   }
   .country__options__container {
     position:fixed;
     top:13%;
     background-color:white;
     margin-left:-13px;
     width:100%;
     padding:18px 19px;
     box-shadow:0px 5px 15px #0000001A;
   }
   .country__options__wrapper {
     display:flex;
     flex-direction:column;
   }
   .country__options {
     display:flex;
     align-items:center;
     margin-bottom:10px;
   }
   .country__options__text {
     width:15px;
     height:15px;
     margin-right:18px;
   }
   .country__options__label {
    font-family:'TT medium';
    color:#3E3E3E;
    font-size:14px;
   }
  .country__saveselect__button {
    background-color:#FCDD18;
    color:#3E3E3E;
    width:15%;
    text-align:center;
    padding:10px 15px;
    font-family:'TT Bold';
    font-size:14px;
    cursor:pointer;
    text-transform:uppercase;
  }
`;
const SearchInputContainer = styled.div`

.search_table_input {
  width:90%;
  background:transparent;
  height:100%;
  margin-right:20px;
  border:none;
  outline:none
}
`;


export {SelectContainer, SearchInputContainer};
   