import { Modal, ModalOverlay, ModalContent, ModalBody, Button } from '@chakra-ui/react';

import { FaCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router';

interface IncidentSuccessfulModalProps {
  isOpen: boolean;
  onClose: () => void;
  closeOnOverlayClick?: boolean;
  showBackIcon?: boolean;
  backIcon?: string;
}

export default function IncidentSuccessfulModal({
  isOpen,
  onClose,
  closeOnOverlayClick = true
}: IncidentSuccessfulModalProps) {
  const navigate = useNavigate();
  return (
    <Modal
      size={'sm'}
      closeOnOverlayClick={closeOnOverlayClick}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      blockScrollOnMount
    >
      <ModalOverlay />
      <ModalContent bg="#FAFAFA" p={'20px'}>
        <ModalBody>
          <div className="flex flex-col justify-center items-center">
            <div className="flex justify-center items-center w-[34px] h-[34px] rounded-[50%] bg-[#fcdd18]">
              <FaCheck color="#FAF9F5" size={25} />
            </div>
            <p className="text-center mt-[20px] mb-[10px] comm-m-14">Incident status was updated successfully</p>
            <Button
              type="button"
              onClick={() => {
                onClose();
                navigate(`/ticket-management`);
              }}
              // state={ButtonStates.primary}
              // size={ButtonSize.md}
              className="mt-[25px]"
            >
              Dismiss
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
