import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

import { MaintenanceReportTab } from './MaintenanceReportTab';
import { JobCardTab } from './JobCardTab';
import { ReportHistoryTab } from './ReportHistoryTab';

export const MaintenanceDetailSection = ({ refetch }) => {
  const tabsInfo = [
    {
      name: 'Maintenance Report',
      component: <MaintenanceReportTab refetch={refetch} />
    },
    { name: 'Job Card', component: <JobCardTab refetch={refetch} /> },
    { name: 'Report History', component: <ReportHistoryTab /> }
  ];

  const tabHeaders = tabsInfo.map((header) => header.name.toUpperCase());
  const tabComponent = tabsInfo.map((comp) => comp.component);

  return (
    <>
      <Tabs variant="unstyled">
        <TabList bg="hsla(0, 0%, 99%, 1)" borderRadius="10px" px={5}>
          {tabHeaders.map((tab, idx) => (
            <Tab
              borderX={idx === 1 ? '1px solid #E6E6E6' : 'none'}
              borderColor={idx === 1 ? 'gray.200' : 'none'}
              color="hsla(0, 0%, 24%, 0.3)"
              _focus={{ boxShadow: 'none' }}
              fontSize="0.75rem"
              fontWeight="bold"
              key={idx}
              px={10}
              py={5}
              _selected={{ color: '#3E3E3E', fontWeight: 'bold' }}
            >
              {tab}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabComponent.map((item, idx) => (
            <TabPanel key={idx}>{item}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
};
