import { FC } from 'react';
import { Box, Button, Center, Divider, Flex, Text, Textarea } from '@chakra-ui/react';
import { useQuery, useQueryClient } from 'react-query';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { Formik, Field, Form } from 'formik';

import { convertParamsToString } from 'helpers/auth-helpers';
import { maintenanceRequest } from 'services/request';
import axiosInstance from 'services/httpService';
import { KEY_LS_MAX_USER_VAMS, QUERY_KEYS } from 'util/constants';
import { postRequest, useMutationWrapper } from 'services/apiHelper';

import Modal from 'components/BaseModal';

type DueForMaintenanceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  dueForMaintenanceVehicleId: string;
};

type CreateDueForMaintenanceCommentType = {
  due_for_maintenance_id: string;
  user_account_id: string;
  user_name: string;
  comment: string;
};

const userObj = JSON.parse(localStorage.getItem(KEY_LS_MAX_USER_VAMS) || '{}');

export const DueForMaintenanceCommentsModal: FC<DueForMaintenanceModalProps> = ({
  isOpen,
  onClose,
  dueForMaintenanceVehicleId
}) => {
  const queryClient = useQueryClient();

  const initialValues: CreateDueForMaintenanceCommentType = {
    due_for_maintenance_id: dueForMaintenanceVehicleId,
    user_account_id: userObj.user_account_id,
    user_name: userObj.first_name + ' ' + userObj.last_name,
    comment: ''
  };

  const getAllDueForMaintenanceComments = async ({ queryKey }) => {
    if (!dueForMaintenanceVehicleId) return;
    const url = convertParamsToString(maintenanceRequest.DUE_FOR_MAINTENANCE_COMMENTS, {
      due_for_maintenance_vehicle_id: queryKey[1]
    });
    const res = await axiosInstance.get(url);
    return res?.data?.data?.results;
  };

  const onSuccess = (data) => {
    toast.success(data?.message);
    queryClient.invalidateQueries(QUERY_KEYS.DUE_FOR_MAINTENANCE);
    onClose();
  };

  const { mutate, isLoading: isLoadingCreateComment } = useMutationWrapper(postRequest, onSuccess);

  const handleSubmit = (fields: CreateDueForMaintenanceCommentType) => {
    return mutate({
      url: maintenanceRequest.DUE_FOR_MAINTENACE_COMMENT_CREATE,
      data: fields
    });
  };

  const { data: dueForMaintenanceComments, isLoading }: any = useQuery(
    [QUERY_KEYS.DUE_FOR_MAINTENANCE, dueForMaintenanceVehicleId],
    getAllDueForMaintenanceComments
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Comments" size="xl" subTitle="View and add comments to this entry">
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Box px={5} pt={5}>
              <Field as={Textarea} name="comment" placeholder="Type new comment here" />
            </Box>

            <Box px={5} py={2} maxH="500px" overflowY="scroll">
              {isLoading && (
                <Center mt={5}>
                  <Text>Loading...</Text>
                </Center>
              )}
              {dueForMaintenanceComments?.map((comment: any) => (
                <Box
                  bgColor="#fff"
                  border="1px"
                  borderColor="rgba(238, 238, 238, 1)"
                  borderRadius="10px"
                  key={comment?.id}
                  my={5}
                >
                  <Flex justifyContent="space-between" px={4} py={2}>
                    <Text color="rgba(128, 128, 128, 1)" fontSize="0.75rem" fontWeight="bold">
                      {comment?.user_name}
                    </Text>
                    <Text color="rgba(128, 128, 128, 1)" fontSize="0.75rem" fontWeight="bold">
                      <Moment format="DD/MMM/YYYY hh:mm">{comment?.created_at}</Moment>
                    </Text>
                  </Flex>
                  <Divider />
                  <Text color="#707070" px={5} py={3}>
                    {comment?.comment}
                  </Text>
                </Box>
              ))}
            </Box>
            <Flex justifyContent="space-between" px={5} pt={4}>
              <Button
                bgColor="#FFCC00"
                borderRadius="4px"
                fontSize="0.8125rem"
                isDisabled={isLoadingCreateComment || isSubmitting}
                px={6}
                py={2}
                type="submit"
              >
                Save comment
              </Button>
              <Button bgColor="#EEEEEE" borderRadius="4px" fontSize="0.8125rem" px={6} py={2} onClick={onClose}>
                CANCEL
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
