import { FC } from 'react';
import { Avatar, Box, Divider, Flex, Grid, GridItem, Spacer, Text } from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { nanoid } from 'nanoid';

import { StatusDisplay } from 'components/StatusDisplay/StatusDisplay';
import { RenderLoader } from 'components/Loaders/RenderLoader';
import { RenderEmptyData } from 'components/EmptyData/EmptyData';

import ChampionHelmetIcon from 'assets/images/vehicle-activity/champion-helmet.svg';

type CurrentChampionProps = {
  contractData: any;
  currentChampion: any;
  isLoading: boolean;
  trancheData: any;
};

export const CurrentChampion: FC<CurrentChampionProps> = ({
  contractData,
  currentChampion,
  isLoading,
  trancheData
}) => {
  const fullName = `${currentChampion?.champion?.user?.first_name} ${currentChampion?.champion?.user?.last_name}`;

  const addOnServices = [
    {
      title: "Driver's License Fee",
      value: false
    },
    {
      title: 'Health Insurance (HMO)',
      value: currentChampion?.contract?.has_insurance
    },
    {
      title: 'License Fee',
      value: false
    }
  ];

  const championStats = [
    {
      title: 'Contract Duration (Days)',
      value: contractData?.duration_in_days,
      currency: false
    },
    {
      title: 'HP Amount',
      value: contractData?.hp_value,
      currency: true
    },
    {
      title: 'Daily Remittance Amount',
      value: contractData?.daily_remit,
      currency: true
    },
    {
      title: 'Total Amount Overdue',
      value: contractData?.amount_overdue,
      currency: true
    },
    {
      title: 'Total Amount Outstanding',
      value: contractData?.total_amount_outstanding,
      currency: true
    },
    {
      title: 'Total Days Outstanding',
      value: contractData?.days_outstanding,
      currency: false
    },
    {
      title: 'Total Amount Remitted',
      value: contractData?.total_amount_remitted,
      currency: true
    },
    {
      title: 'Platform Commission',
      value: contractData?.champion_platform_fee,
      currency: true
    }
  ];

  const RenderCurrentChampion = () => (
    <Flex direction="column" gap={4}>
      <Box p={5} border="1px solid rgba(238, 238, 238, 1)" borderRadius="10px">
        <Flex gap={2}>
          <Avatar src={ChampionHelmetIcon} bg="#fae8d0" />
          <Box direction="column">
            <Text color="rgba(62, 62, 62, 1)" fontSize="1.25rem" fontWeight={600}>
              {fullName || 'N/A'}
            </Text>
            <Text color="#E88E15" fontSize="0.875rem" fontWeight={600}>
              {currentChampion?.champion?.max_champion_id || 'N/A'}
            </Text>
          </Box>
          <Box justifyContent="center">
            <StatusDisplay borderRadius="none" status={currentChampion?.champion?.champion_status} fontSize="12px" />
          </Box>
        </Flex>
      </Box>
      <Flex align="stretch" gap={5}>
        {trancheData?.map((tranche: any, idx: number) => (
          <Box border="1px solid rgba(238, 238, 238, 1)" borderRadius="10px" flex="1" key={nanoid()}>
            <Text fontSize="0.75rem" color="#808080" px={4} py={2}>
              {idx === 0 ? 'Active Tranche' : 'Upcoming Tranche'}
            </Text>
            <Divider />
            <Flex px={4} py={5}>
              <Box>
                <Text fontSize="0.9rem" fontWeight={600} color="#3E3E3E">
                  {tranche?.tranche_name}
                </Text>
                <Text color="rgba(62, 62, 62, 0.5)" fontSize="0.875rem">
                  Daily remittance (&#x20A6;)
                </Text>
              </Box>
              <Spacer />
              <Text color="rgba(62, 62, 62, 1)" fontSize="1.5rem" fontWeight={700}>
                {parseInt(tranche?.daily_remittance, 10).toLocaleString() ?? 'N/A'}
              </Text>
            </Flex>
            <Box px={4}>
              <Divider />
            </Box>
            <Flex gap={3} justify="space-between" px={4} py={2}>
              <Box>
                <Text fontSize="0.85rem" color="rgba(62, 62, 62, 0.5)">
                  Duration (Days)
                </Text>
                <Text color="rgba(62, 62, 62, 1)" fontSize="0.85rem" fontWeight={600}>
                  {tranche?.duration_in_days}
                </Text>
              </Box>
              <Box>
                <Text fontSize="0.85rem" color="rgba(62, 62, 62, 0.5)">
                  Duration (Months)
                </Text>
                <Text color="rgba(62, 62, 62, 1)" fontSize="0.85rem" fontWeight={600} textAlign="right">
                  {tranche?.duration_in_months}
                </Text>
              </Box>
            </Flex>
          </Box>
        ))}
        <Box border="1px solid rgba(238, 238, 238, 1)" borderRadius="10px" flex="1">
          <Text fontSize="0.75rem" color="#808080" px={4} py={2}>
            Add-on Services
          </Text>
          <Divider />
          {addOnServices.map((service) => (
            <Box key={nanoid()}>
              <Flex justify="space-between" align="center" color="#3E3E3E" px={4} py={2}>
                <Text fontSize="0.875rem" fontWeight={600}>
                  {service.title}
                </Text>
                {service.value ? <CheckCircleIcon color="green.500" /> : <WarningIcon color="red.500" />}
              </Flex>
              <Divider />
            </Box>
          ))}
        </Box>
      </Flex>
      <Grid border="1px solid rgba(238, 238, 238, 1)" templateColumns="repeat(4, 1fr)">
        {championStats.map((stat) => (
          <GridItem w="100%" p={5} border="1px solid rgba(238, 238, 238, 1)" key={nanoid()}>
            <Text fontSize="1.2rem" fontWeight={600}>
              {stat.currency && <span>&#x20A6;</span>}
              {Number(stat.value).toLocaleString() ?? 'N/A'}
            </Text>
            <Text color="rgba(62, 62, 62, 0.5)" fontSize="0.875rem" fontWeight={600}>
              {stat.title}
            </Text>
          </GridItem>
        ))}
      </Grid>
    </Flex>
  );

  return (
    <>
      {isLoading ? (
        <RenderLoader />
      ) : !currentChampion?.champion ? (
        <RenderEmptyData description="No current champion was found." />
      ) : (
        <RenderCurrentChampion />
      )}
    </>
  );
};
