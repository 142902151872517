import { FC } from 'react';
import { Avatar, Box, Flex, Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import Skeleton from 'react-loading-skeleton';
import { nanoid } from 'nanoid';

import CarIcon from 'assets/images/vehicle-activity/car-icon.svg';
import MotorcycleIcon from 'assets/images/vehicle-activity/motorcycle-icon.svg';
import TricycleIcon from 'assets/images/vehicle-activity/tricycle-icon.svg';

import 'react-loading-skeleton/dist/skeleton.css';

import { StatusDisplay } from 'components/StatusDisplay/StatusDisplay';

type VehicleSummaryProps = {
  vehicleData: any;
  isLoading: boolean;
};

const switchAvatarIcons = {
  Car: CarIcon,
  Tricycle: TricycleIcon,
  Motorcycle: MotorcycleIcon
};

export const VehicleSummary: FC<VehicleSummaryProps> = ({ vehicleData, isLoading }) => {
  const summaryCardData = [
    {
      title: 'Location',
      value: vehicleData?.city
    },
    {
      title: 'Manufacturer',
      value: vehicleData?.manufacturer
    },
    {
      title: 'Trim',
      value: vehicleData?.trim
    },
    {
      title: 'Model',
      value: vehicleData?.model
    },
    {
      title: 'Asset Class',
      value: vehicleData?.vehicle_type
    },
    {
      title: 'Plate Number',
      value: vehicleData?.plate_number
    }
  ];

  return (
    <>
      <VStack>
        <Flex direction="column" justify="center" align="center">
          <Avatar bg="#fae8d0" name="Tricycle" size="lg" src={switchAvatarIcons[vehicleData?.vehicle_type]} />
          <Text color="rgba(62, 62, 62, 1)" mt={3} fontWeight={600}>
            {isLoading ? <Skeleton /> : `${vehicleData?.vehicle_type || 'N/A'} - ${vehicleData?.manufacturer || 'N/A'}`}
          </Text>
          <Text color="rgba(128, 128, 128, 1)" fontSize="0.75rem">
            {isLoading ? <Skeleton /> : vehicleData?.max_vehicle_id || 'N/A'}
          </Text>
          <Flex justifyContent="center" mt={3}>
            <StatusDisplay borderRadius="none" status={vehicleData?.vehicle_status} fontSize="12px" />
          </Flex>
        </Flex>
      </VStack>
      <Box bg="rgba(247, 247, 247, 1)" borderRadius="10px" mt={5} px={5} py={3}>
        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
          {summaryCardData?.map((vehicleInfo: any) => (
            <GridItem w="100%" key={nanoid()}>
              <Text fontSize="0.75rem" color="rgba(62, 62, 62, 0.5)">
                {vehicleInfo.title}
              </Text>
              <Text color="rgba(62, 62, 62, 1)" fontSize="0.875rem" fontWeight={600}>
                {isLoading ? <Skeleton /> : vehicleInfo.value || 'N/A'}
              </Text>
            </GridItem>
          ))}
        </Grid>
      </Box>
    </>
  );
};
