import { KEY_LS_MAX_TOKEN, KEY_LS_MAX_USER, KEY_LS_MAX_USER_VAMS, KEY_SIZE } from '../util/constants';
import { IMaxUser } from '../types/user';
// import { signOut, getAuth } from 'firebase/auth';

// const auth = getAuth()
export const store = {
  save(key: string, item: any) {
    switch (true) {
      case typeof item === 'string':
      case typeof item === 'number':
        localStorage.setItem(key, item);
        break;
      case typeof item === 'object':
      case Array.isArray(item):
        const value = JSON.stringify(item);
        localStorage.setItem(key, value);
        break;
    }
  },

  getString(key: string): string {
    return String(localStorage.getItem(key));
  },

  getJson(key: string): object | any[] {
    const value = String(localStorage.getItem(key));
    return JSON.parse(value);
  },

  saveMaxToken(token: string) {
    this.save(KEY_LS_MAX_TOKEN, token);
  },

  saveMaxAdmin(user: IMaxUser) {
    this.save(KEY_LS_MAX_USER, user);
  },

  loginMaxAdmin(user: IMaxUser, token: string) {
    this.saveMaxToken(token);
    this.saveMaxAdmin(user);
  },

  getMaxAdmin(): IMaxUser {
    return this.getJson(KEY_LS_MAX_USER) as IMaxUser;
  },

  logout() {
    localStorage.removeItem(KEY_LS_MAX_USER);
    localStorage.removeItem(KEY_LS_MAX_TOKEN);
    localStorage.removeItem(KEY_SIZE);
    localStorage.removeItem(KEY_LS_MAX_USER_VAMS)
  }
};
