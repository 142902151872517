import axiosInstance from "./httpService";

const configService = 'configurations/api';
const documents = 'documents/v1';
const userMgt = 'vehicles/v1/user-managment';
const vehicles = 'vehicles/v1';
const vehicleReport = 'vehicles/v1/reporting';
const accountService = 'accounts';
const maintenanceReport = 'maintenance/v1';
const vehicleServicesdk = 'vehicle-service';

export const accountServiceRequest = {
  CREATE_PLATFORM: `${accountService}/platform`
};

export const authRequest = {
  LOGIN: `${userMgt}/login`,
  GET_USER_FROM_ACCOUNT_SERVICE: '/accounts/:userId',
  GET_USER_ON_VAMS: `${userMgt}/getUser/:userId`,
  FORGOT_PASSWORD: `${accountService}/auth/forgot-password`,
  RESET_PASSWORD: `${accountService}/auth/reset-password`
};

export const championContractRequests = {
  GET_CHAMPION_AMORTIZATION_SCHEDULE: `collection/v1/repayments/amortization-schedule/champions`,
  GET_ACTIVE_CHAMPION_CONTRACT_DETAILS: `collection/v1/contracts/active`,
  GET_CHAMPION_TRANCHE: `collection/v1/repayments/tranche-info/champions/:championContractId`,
  GET_CHAMPION_CONTRACT_DETAILS: `collection/v1/contracts/champions/:championId`
};

export const configServiceRequest = {
  GET_ALL_LOCATIONS: `${configService}/city/all`,
  GET_ALL_PLATFORM: `${configService}/platform/all`,
  GET_ALL_REASON: `${vehicles}/vehicle/movement/reasons`,
  GET_ALL_SUB_LOCATIONS: `${configService}/sub-city/all`,
  GET_ALL_SUB_REASON: `${configService}/sub_reason/all`,
  GET_STATUS_CHECKLIST: `${vehicles}/checklist`,
  GET_SUB_REASON: `${configService}/sub_reason/get_by_reason/:reasonId`,
  GET_ALL_COUNTRIES: `${configService}/country/all`
};

export const dashboardRequest = {
  LOCATIONS: `${configService}/sub-city/all`,
  SUMMARY: `${vehicles}/vehicle/summary/details?location_id=:location_id`
};

export const documentRequest = {
  UPLOAD_CONTRACT_SIGNED_PAGE: `${documents}/prospect-verification/upload`
};

export const maintenanceRequest = {
  ALL_MAINTENANCE_VEHICLES: `${maintenanceReport}/maintenance`,
  ALL_ACTIVE_USERS: `${maintenanceReport}/users/get-active-user-by-role`,
  ALL_JOB_CARDS: `${maintenanceReport}/job-card`,
  ASSIGN_QA_TESTER: `${maintenanceReport}/maintenance/assign-tester`,
  ASSIGN_TECHNICIAN: `${maintenanceReport}/maintenance/assign-technician`,
  DUE_FOR_MAINTENANCE: `${maintenanceReport}/due-for-maintenance/all?page=:page&location=:location&search_query=:search_query&start_date=:start_date&end_date=:end_date`,
  DUE_FOR_MAINTENANCE_COMMENTS: `${maintenanceReport}/due-for-maintenance-comments/maintenance-comment/:due_for_maintenance_vehicle_id?page=:page&size=:size`,
  DUE_FOR_MAINTENACE_COMMENT_CREATE: `${maintenanceReport}/due-for-maintenance-comments/create-due-for-maintenance-comment`,
  DUE_FOR_MAINTENANCE_COUNT: `${maintenanceReport}/due-for-maintenance/counts`,
  REASSIGN_TECHNICIAN: `${maintenanceReport}/maintenance/reassign-technician`,
  REASSIGN_QA_TESTER: `${maintenanceReport}/maintenance/reassign-tester`,
  GET_JOB_DETAILS_BY_MAINTENANCE_SCHEDULE: `${maintenanceReport}/job-card/fetch-by-maintenance-schedule-id/:maintenanceScheduleID`,
  GET_MAINTENANCE_CHECKLIST: `${maintenanceReport}/maintenance-report/check-list`,
  GET_MAINTENANCE_VEHICLE: `${maintenanceReport}/maintenance/:vehicleUUID`,
  GET_PAUSE_REASONS: `${maintenanceReport}/job-card/status/pause-reasons`,
  GET_PENDING_REASONS: `${maintenanceReport}/job-card/status/pending-reasons`,
  MAINTENANCE_REPORT: `${maintenanceReport}/maintenance-report`,
  MAINTENANCE_SCHEDULE: `${maintenanceReport}/maintenance-schedule`,
  MAINTENANCE_SCHEDULE_HISTORY: `${maintenanceReport}/maintenance-schedule/maintenance-schedule-history/get-all?page=:page&size=:size`,
  MAINTENANCE_SCHEDULE_HISTORY_BY_VEHICLE_ID: `${maintenanceReport}/maintenance-schedule/maintenance-schedule-history/:vehicleID?page=:page&size=:size`,
  REPORT_HISTORY_BY_ID: `${maintenanceReport}/maintenance-schedule/maintenance-schedule-history/:maintenance_vehicle_id`,
  SCHEDULE_USERS: `${maintenanceReport}/maintenance-schedule/maintenance-users`,
  UPDATE_JOB_CARD_STATUS: `${maintenanceReport}/job-card/:maintenanceReportID/status/update`,
  MAINTENANCE_FEAT_CITIES: `${maintenanceReport}/maintenance/get-maintenance/locations`,
  GET_USER_ROLE_ID: `${maintenanceReport}/users/get-users-role_id`
};

export const reportRequest = {
  ACTIVE_VEHICLES: `${vehicleReport}/active-vehicle/detail-list?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  ACTIVE_VEHICLES_BY_DATE: `${vehicleReport}/active-vehicles/group?city=:city&vehicleType=:vehicleType`,
  ALL_CITIES: `${configService}/city/all`,
  ALL_SUB_CITIES: `${configService}/sub-city/all`,
  ALL_VEHICLES: `${vehicleReport}/vehicle/download-report?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  ALL_VEHICLES_COUNT: `${vehicleReport}/all-vehicles/category?subcity=:subcity&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&city=:city`,
  ALL_VEHICLES_PROD: `${vehicleReport}/vehicle/download-report`,
  ALL_VEHICLES_SUBCITY: `${vehicleReport}/vehicle/group-by-sub-city`,
  CHECKING_IN: `${vehicleReport}/vehicle/checkin?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  CHECKING_OUT: `${vehicleReport}/vehicle/checkout?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  CHECKING_IN_BY_DATE: `${vehicleReport}/check-in-vehicles/group?city=:city&vehicleType=:vehicleType`,
  CHECKING_OUT_BY_DATE: `${vehicleReport}/check-out-vehicles/group?city=:city&vehicleType=:vehicleType`,
  GET_INACTIVE_REASONS: `${vehicleReport}/in-active-vehicles?reason=true`,
  GET_CHECKIN_REASONS: `${vehicleReport}/check-in-vehicles?reason=true`,
  HP_COMPLETED: `${vehicleReport}/hp-complete-vehicles?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  HP_COMPLETE_BY_DATE: `${vehicleReport}/hp-complete-vehicles/group?city=:city&vehicleType=:vehicleType`,
  INACTIVE_VEHICLES: `${vehicleReport}/inactive-vehicle/detail-list?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize&reason=:reason`,
  INACTIVE_VEHICLES_BY_DATE: `${vehicleReport}/in-active-vehicles/group?city=:city&vehicleType=:vehicleType`,
  LOST_VEHICLES: `${vehicleReport}/lost-vehicle/list?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  LOST_VEHICLES_BY_DATE: `${vehicleReport}/missing-vehicles/group?city=:city&vehicleType=:vehicleType`,
  NOT_REMITTED: `${vehicleReport}/vehicle/non-remitted?days=:days&subcity=:subcity&city=:city&vehicleType=:vehicleType&page=:page&limit=:pageSize&platform=:platform&manufacturer=:manufacturer`,
  NOT_REMITTED_BY_DATE: `${vehicleReport}/not-remitted-vehicles/group?city=:city&vehicleType=:vehicleType`,
  SCRAPPED_VEHICLES: `${vehicleReport}/scrapped-vehicles/detail-list?startDate=:startDate&endDate=:endDate&subcity=:subcity&city=:city&vehicleType=:vehicleType&page=:page&limit=:pageSize&platform=:platform&manufacturer=:manufacturer`,
  SCRAPPED_VEHICLES_BY_DATE: `${vehicleReport}/scrapped-vehicles/group?city=:city&vehicleType=:vehicleType`,
  ONLY_ACTIVE_NOT_REMITTED: `${vehicleReport}/not-remitted-vehicles/category?subcity=:subcity&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&city=:city`,
  FIREBASE_ACTIVE_VEHICLES: `${vehicles}/firestore/reporting/active-vehicle/detail-list?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  FIREBASE_INACTIVE_VEHICLES: `${vehicles}/firestore/reporting/inactive-vehicle/detail-list?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  FIREBASE_MISSING_VEHICLES: `${vehicles}/firestore/reporting/lost-vehicle/list?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  FIREBASE_SCRAPPED_VEHICLES: `${vehicles}/firestore/reporting/scrapped-vehicles/detail-list?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  FIREBASE_NOTREMITTED_VEHICLES: `${vehicles}/firestore/reporting/vehicle/non-remitted?days=:days&subcity=:subcity&city=:city&vehicleType=:vehicleType&page=:page&limit=:pageSize&platform=:platform&manufacturer=:manufacturer`,
  FIREBASE_CHECKIN_VEHICLES: `${vehicles}/firestore/reporting/vehicle/checkin?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  FIREBASE_CHECKEDOUT_VEHICLES: `${vehicles}/firestore/reporting/vehicle/checkout?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  FIREBASE_HP_COMPLETE: `${vehicles}/firestore/reporting/hp-complete-vehicles?subcity=:subcity&city=:city&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&page=:page&limit=:pageSize`,
  FIREBASE_VEHICLE_COUNT: `${vehicles}/firestore/reporting/all-vehicles/category?subcity=:subcity&vehicleType=:vehicleType&startDate=:startDate&endDate=:endDate&platform=:platform&manufacturer=:manufacturer&city=:city`,
  FIREBASE_ACTIVE_BY_DATE: `${vehicles}/firestore/reporting/active-vehicles/group?city=:cityvehicleType=:vehicleType&startDate=:startDate&endDate=:endDate`,
  FIREBASE_INACTIVE_DATE: `${vehicles}/firestore/reporting/in-active-vehicles/group?city=:city&vehicleType=:vehicleType`,
  FIREBASE_HP_COMPLETE_DATE: `${vehicles}/firestore/reporting/hp-complete-vehicles/group?city=:cityvehicleType=:vehicleType`,
  FIREBASE_MISSING_DATE: `${vehicles}/firestore/reporting/missing-vehicles/group?city=:city&vehicleType=:vehicleType`,
  FIREBASE_CHECKIN_DATE: `${vehicles}/firestore/reporting/check-in-vehicles/group?city=:city&vehicleType=:vehicleType`,
  FIREABASE_CHECKOUT_DATE: `${vehicles}/firestore/reporting/check-out-vehicles/group?city=:city&vehicleType=:vehicleType`,
  FIREBASE_SCRAPPED_DATE: `${vehicles}/firestore/reporting/scrapped-vehicles/group?city=:city&vehicleType=:vehicleType`,
  FIREBASE_UNREMITEED_DATE: `${vehicles}/firestore/reporting/not-remitted-vehicles/group?city=:city&vehicleType=:vehicleType`
};

export const userRequest = {
  ADD_MULTIPLE_USERS: `${userMgt}/save-multiple`,
  ADD_NEW_USER: `${userMgt}/save`,
  DEACTIVATE_USER: `${userMgt}/`,
  EDIT_USER: `${userMgt}/`,
  GET_ALL_USERS: `${userMgt}/`,
  GET_ALL_USERS_PAGINATED: `${userMgt}/all?page=:pageNo&limit=:pageSize&search_query=:search`,
  GET_ONE_USER: `${userMgt}/getUser`,
  GET_USER_ROLES: `${vehicles}/role/all`,
  UPDATE_USER_ROLE: `${userMgt}/update-user-role`,
  UPDATE_USER_STATUS: `${userMgt}/update-status`
};

export const vehicleActivity = {
  ALL_VEHICLES: `${vehicles}/vehicle-activity-report?page=:pageNo&city=:city&vehicleType=:vehicleType&manufacturer=:manufacturer&vehicle_status=:vehicle_status&startDate=:startDate&endDate=:endDate&size=:size&search_query=:search`,
  GET_VEHICLE_BY_ID: `${vehicles}/vehicle-activity-report/vehicle/`,
  ALL_VEHICLES_CSV_DOWNLOADABLE: `${vehicles}/vehicle?size=:size`
};

export const vehicleAssignment = {
  ALL_SERVICE_NAME: `${vehicles}/vehicle/services/list`,
  ALL_VEHICLE_OPTIONS: `${vehicles}/vehicle/vehicle-options/list`,
  SEARCH_PROSPECT: '/champions/prospective/prospect-and-guarantor-details/:prospectIdOrPhoneNumber',
  ASSIGN_VEHICLE: `${vehicles}/vehicle/assign-vehicle/activation`
};

export const vehiclePageRequest = {
  SUMMARY: `${vehicles}/vehicle?include_related=true&page=:pageNo&size=:pageSize&vehicle_movement=:vmovement&manufacturer_id=:manuId&batch_id=:batch_id&location_id=:loc&vehicle_type_id=:vType&search_column=:column&search_value=:search`,
  SUMMARY_ALL: `${vehicles}/vehicle?include_related=true&page=:pageNo&size=100000`,
  GET_FINANCIAL_PARTNERS: `${vehicles}/financial-partners/all`,
  ALL_MANUFACTURES: `${vehicles}/manufacturer`,
  MOVEMENT_HISTORY: `${vehicles}/vehicle-movement/vehicle`,
  VEHICLE_TYPE: `${vehicles}/vehicle-type`,
  LIST_OF_ASSETS: `${vehicles}/vehicle/vehicle-movement/last-vehicle-movement-with-reason?include_related=true&vehicle_type_id=:vtypeId&reason_id=:reasonId&movement_type=:vehicle_movement&location_id=:locId&search_query=:search&page=:pageNo&size=:pageSize`,
  UN_PRICED_VEHICLES: `${vehicles}/vehicle/not-priced/vehicles?vehicle_type_id=:vehId&search_query=:search&manufacturer_id=:manuId&location_id=:locId&platform_id=:platformId&page=:pageNo&size=:pageSize`,
  PRICED_VEHICLES: `${vehicles}/vehicle/priced/vehicles?vehicle_type_id=:vehId&search_query=:search&manufacturer_id=:manuId&location_id=:locId&platform_id=:platformId&page=:pageNo&size=:pageSize`,
  ALL_MODEL: `${vehicles}/model`,
  ALL_TRIM: `${vehicles}/trim`,
  ALL_BATCHES: `${vehicles}/batch`,
  ALL_PARTENERS: `/accounts/partners`,
  ALL_STATUS: `${vehicles}/vehicle-status`,
  CONFIG_ALL_VEHICLE_STATUS: `${configService}/status/all/vehicle`,
  ALL_PLATFORMS: `${configService}/platform/all`,
  ALL_SERVICE: '/v1/go/services?limit=50',
  CONFIG_ALL_SERVICE_TYPES: `${configService}/service_type/all`,
  VEHICLE: `${vehicles}/vehicle`,
  CREATE_INBOUND_VEHICLE: `${vehicles}/vehicle?is_in_bound=true`,
  UPDATE_INBOUND_VEHICLE: `${vehicles}/vehicle/update_vehicle/details`,
  CONFIG_ALL_LOCATIONS: `${configService}/sub-city/all`,
  ADD_VEHICLE_MODEL: `${vehicles}/model`,
  ADD_VEHICLE_TRIM: `${vehicles}/trim`,
  VEHICLE_HISTORY: `${vehicles}/vehicle-movement/vehicle`,
  ALL_REASONS: `${configService}/reason/all/vehicle`,
  UPDATE_VEHICLE_STATUS: `${vehicles}/vehicle/update_status`,
  GET_VEHICLE_DURATION: `${vehicles}/vehicle-movement/get-duration/by-vehicle-id`,
  UPDATE_REASON: `${vehicles}/vehicle-movement/update_reason`,
  UPDATE_VEHICLE_MOVEMENT: `${vehicles}/vehicle/movement/change`,
  VEH_MOVEMENT_HISTORY: `${vehicles}/vehicle-movement/vehicle/movement-with-reason`,
  VEHICLE_BY_ID: `${vehicles}/vehicle`,
  READY_FOR_ACTIVATION: `${vehicleServicesdk}/vehicle/ready-for-activation`,
};




interface PostRequestParams {
  route?: string;
  payload?: FormData | unknown;
}

interface PatchRequestParams {
  route?: string;
  payload?: FormData | unknown;
}

interface DeleteRequestParams {
  route?: string;
}

export async function postMethod(params: PostRequestParams) {
  const { route, payload } = params;
  const res = await axiosInstance.post(`${route}`, payload);
  return res.data;
}

export async function patchMethod(params: PatchRequestParams) {
  const { route, payload } = params;
  const res = await axiosInstance.patch(`${route}`, payload);
  return res.data;
}

export async function putMethod(params: PatchRequestParams) {
  const { route, payload } = params;
  const res = await axiosInstance.put(`${route}`, payload);
  return res.data;
}

export async function deleteMethod(params: DeleteRequestParams) {
  const { route } = params;
  const res = await axiosInstance.delete(`${route}`);
  return res.data;
}

export const getMethod = async ({ queryKey, }: { queryKey: [string, { route: string; params: Record<string, unknown> }]; }) => {
  const { route, params } = queryKey[1];
  const { data } = await axiosInstance.get(`${route}`, {
    params,
  });
  return data.data;
};
