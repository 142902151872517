import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { FaFileDownload } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { motion } from 'framer-motion';

import { downloadCsv } from 'util/downloadAsCsv';
import DatePicker from 'react-datepicker';
import FilterContainer from 'components/filter-container/filter-container.component';
import { Input } from '@chakra-ui/input';
import { ActionBar } from 'components/ActionBar/ActionBar';
import {
  getAllCities,
  getFilteredMissingVehicle,
  getFireBaseMissingVehicles,
  getLocations,
  getLostVehicles
} from '../reportApi';
import Paginator, { PageChangeType } from 'components/Paginator';
import PageWrapper from 'components/page-wrapper';
import './lost-vehicles.styles.scss';
import { vehicleTypes } from 'constants/vehicleTypes';
import BasicTable from 'components/BasicTable';
import CustomSelect from 'components/custom-select';
import { toast } from 'react-toastify';
import { collection, limit, onSnapshot, query } from 'firebase/firestore';
import { firebaseDb } from 'config/firebase';
import ClearFilter from 'components/clear-filter';
import { useAppContext } from 'global/AppContext';
export const LostVehicleReport: React.FC = () => {
  const {
    setVehicleType,
    vehicleType,
    location_id: subCity,
    setLocationId: setSubCity,
    platform,
    setStartDate,
    setEndDate,
    manufacturer,
    city,
    setCity,
    startDate,
    endDate
  }: any = useAppContext();
  const [pageNo, setPageNo] = useState(0);
  const [pages, setPages] = useState(1);
  const [pageSize, setpageSize] = useState('20');
  const [cities, setCities] = useState<any>([]);
  const [subCities, setSubcities] = useState([]);
  const [subCityFilters, setSubCityFilters] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedStartDate, selectedEndDate] = dateRange;
  const [missingVehicle, setMissingVehicle] = useState([]);
  const [missingVehCopy, setMissingVehCopy] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const missingVehRef = collection(firebaseDb, 'missing_vehicle');
  const missVehQuery = query(missingVehRef, limit(Number(pageSize)));

  useEffect(() => {
    const cancelLostSub = onSnapshot(missVehQuery, (snapshot) => {
      setIsLoading(true);
      let missingVeh: any = [];
      snapshot.docs.forEach((doc) => {
        missingVeh.push({ ...doc.data(), id: doc.id });
      });
      setMissingVehicle(missingVeh);
      setMissingVehCopy(missingVeh);
      setIsLoading(false);
    });
    return () => {
      cancelLostSub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getFilteredMissing = async () => {
      try {
        setIsLoading(true);
        const res = await getFilteredMissingVehicle(
          subCity,
          vehicleType,
          startDate,
          endDate,
          pages,
          pageSize,
          city,
          platform,
          manufacturer
        );
        setMissingVehicle(res.data.results);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
      }
    };
    if (
      startDate ||
      endDate ||
      city ||
      subCity ||
      vehicleType ||
      pages !== 1 ||
      platform ||
      manufacturer ||
      pageSize !== '20'
    ) {
      getFilteredMissing();
    } else {
      setIsLoading(true);
      setMissingVehicle(missingVehCopy);
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, endDate, pageSize, pages, startDate, subCity, vehicleType, platform, manufacturer]);

  useEffect(() => {
    getAllCities()
      .then((res) =>
        setCities(
          res?.payload?.message?.objects?.map((el: any) => ({
            label: el.name,
            value: el.id
          }))
        )
      )
      .catch((err) => toast.error(err.message));

    getLocations()
      .then((res) => {
        setSubcities(
          res?.payload?.message?.objects?.map((el: any) => ({
            label: el.name,
            value: el.id,
            city_id: el.city_id
          }))
        );
        setSubCityFilters(
          res?.payload?.message?.objects?.map((el: any) => ({
            label: el.name,
            value: el.id,
            city_id: el.city_id
          }))
        );
      })
      .catch((err) => toast.error(err.message));
  }, []);

  const totalLostFirebase = useQuery(
    ['all-lost-firebase-vehicles', startDate, endDate, subCity, vehicleType, pages, pageSize, '', '', city],
    getFireBaseMissingVehicles
  );

  const locations = useQuery('new_locations', getLocations);

  const history = useNavigate();
  const handlePageReturn = (): void => {
    history(-1);
  };

  const allConfigCities: any = useQuery('allcities-on-vams', getAllCities);

  const onVehicleChange = (e: any) => {
    if (e) {
      setVehicleType(e.value);
    } else {
      setVehicleType('');
    }
  };

  const onPageChange = (page: PageChangeType) => {
    setPageNo(page.selected);
    setPages(page.selected + 1);
  };

  const onPageSizeChange = (e: any) => {
    setpageSize(e);
  };

  const columns = [
    {
      Header: 'Time Stamp',
      accessor: 'updated_at',
      Cell: ({ value }: any) => (value ? format(new Date(value), 'hh:mma   dd-MM-yyyy') : 'N/A')
    },
    {
      Header: 'Asset Type',
      accessor: 'vehicle_type'
    },
    {
      Header: 'Vehicle ID',
      accessor: 'max_vehicle_id'
    },
    {
      Header: 'Ignition No.',
      accessor: 'engine_number',
      Cell: ({ value }: any) => value || 'N/A'
    },
    {
      Header: 'Location',
      accessor: 'city'
    }
  ];
  const allPagesInactiveVehicles: any = useQuery(
    ['all-pages-vehicles', '', '', '', '', '', totalLostFirebase.data?.data?.pagination?.total, '', '', ''],
    getLostVehicles
  );

  const onDateRangeChanged = (selectedDates) => {
    const [startDate, endDate] = selectedDates;
    const DATE_FORMAT = 'yyyy-MM-dd';
    if (startDate && endDate) {
      const startDateValue = format(new Date(startDate), DATE_FORMAT);
      const endDateValue = format(new Date(endDate), DATE_FORMAT);
      setStartDate(startDateValue);
      setEndDate(endDateValue);
    } else {
      setEndDate('');
      setStartDate('');
    }
  };
  const onLocationChange = (e: any) => {
    if (e) {
      setSubCity(e.value);
    } else {
      setSubCity('');
    }
  };

  const onCityChange = (option: any) => {
    if (option) {
      setSubCity('');
      setCity(option.value);
      const filteredSubCities = subCities.filter((loc: any) => loc.city_id === option.value);
      setSubCityFilters(filteredSubCities);
    } else {
      setCity('');
      setSubCityFilters(subCities);
    }
  };
  return (
    <>
      <FilterContainer>
        <ClearFilter />
        <DatePicker
          selectsRange={true}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          onChange={(update: any) => {
            setDateRange(update);
            onDateRangeChanged(update);
          }}
          isClearable={true}
          maxDate={new Date()}
          locale="en"
          placeholderText="FROM - TO"
          customInput={<Input fontSize="13px" w="180px" />}
        />

        <CustomSelect
          options={vehicleTypes}
          name="Report-by-vehicletype"
          placeholder="Vehicle"
          onChange={onVehicleChange}
          value={vehicleTypes?.filter((veh) => veh.value === vehicleType)}
        />

        <CustomSelect
          options={cities}
          name="Report-by-location"
          placeholder="Cities"
          onChange={onCityChange}
          isLoading={allConfigCities.isLoading}
          value={cities?.filter((loc) => loc.value === city)}
        />

        <CustomSelect
          options={subCityFilters}
          name="Report-by-location"
          placeholder="Location"
          onChange={onLocationChange}
          isLoading={locations.isLoading}
          value={subCities?.filter((loc: any) => loc.value === subCity)}
        />
      </FilterContainer>

      <PageWrapper>
        <ActionBar>
          <div className="action-bar__page-title__wrapper">
            <ArrowLeftIcon width={35} onClick={handlePageReturn} className="action-bar__page-title__title-icon" />
            <p className="action-bar__page-title__title">Missing Vehicles</p>
          </div>
          <div className="action-bar__btn-group">
            <button
              className="export-vehicle"
              hidden={allPagesInactiveVehicles?.isLoading}
              onClick={(e) => downloadCsv(allPagesInactiveVehicles?.data?.results, 'Missing Vehicles')}
            >
              <FaFileDownload className="mr-4" style={{ fontSize: '18px' }} />
              Export
            </button>
          </div>
        </ActionBar>
        <>
          <motion.div className="table-holder m-5" initial={{ x: -30 }} animate={{ x: 0 }}>
            <BasicTable data={missingVehicle} loading={isLoading} columns={columns} />
          </motion.div>
          {!isLoading && (
            <Paginator
              pageCount={totalLostFirebase.data?.data?.pagination?.page_count}
              onPageChange={onPageChange}
              pageNo={pageNo}
              pageSize={pageSize}
              onSizeChange={onPageSizeChange}
            />
          )}
        </>
      </PageWrapper>
    </>
  );
};
