import { KEY_LS_MAX_TOKEN, KEY_SIZE } from '../util/constants';
import { store } from '../data/store';

export function isMaxAdminAuthenticated(): boolean {
  let tk: any = localStorage.getItem(KEY_LS_MAX_TOKEN);
  return typeof tk === 'string' && tk !== '';
}

export function getMaxToken(): string {
  return String(localStorage.getItem(KEY_LS_MAX_TOKEN) || '');
}

export function pageSize(): string {
  return String(localStorage.getItem(KEY_SIZE) || '1000');
}

export function isUserMaxAdmin(): boolean {
  const user = store.getMaxAdmin();
  return !!user.role && user.role === 'admin';
}
export function convertParamsToString(url: string, urlVariables: Record<string, string>): string {
  let finalURL = url;

  for (const [key, value] of Object.entries(urlVariables)) {
    finalURL = finalURL.replace(`:${key}`, value);
  }

  return finalURL
}