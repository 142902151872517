import { FC, useState } from 'react';
// TODO: Import Button and Image onces export is fixed
import { Avatar, Box, Flex, HStack, Select, StackDivider, Square, Text, useDisclosure } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';
import { nanoid } from 'nanoid';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';
import { useQuery, useQueryClient } from 'react-query';
import { debounce } from 'lodash';

import { convertParamsToString } from 'helpers/auth-helpers';
import { vehicleActivity } from 'services/request';
import axiosInstance from 'services/httpService';
import { dateFormatter } from 'util/dateFormat';
import { FILTER_CONSTANT, QUERY_KEYS } from 'util/constants';
// import { downloadCsv } from 'util/downloadAsCsv';
import { postRequest, useMutationWrapper } from 'services/apiHelper';
import { championContractRequests } from 'services/httpService';

import FilterContainer from 'components/filter-container/filter-container.component';
import PageWrapper from 'components/page-wrapper';
import { Card } from 'components/card/Card';
import { StatusDisplay } from 'components/StatusDisplay/StatusDisplay';
import { NewTable } from 'components/Table/Table';
import { VehicleActivityModal } from 'components/VehicleActivity/VehicleActivityModal';
import { TableLoader } from 'components/Loaders/NewSkeletonTableLoader';

// import ExcelIcon from 'assets/images/vehicle-activity/ms-excel-logo.svg';
import CarIcon from 'assets/images/vehicle-activity/car-icon.svg';
import MotorcycleIcon from 'assets/images/vehicle-activity/motorcycle-icon.svg';
import TricycleIcon from 'assets/images/vehicle-activity/tricycle-icon.svg';

import { SearchInputContainer } from './styles';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

type VehicleActivityModalDataType = {
  asset_class: string;
  city: string;
  manufacturer: string;
  plate_number: string;
  vehicle_status: string;
  total_amount_collected: number | string;
  total_number_of_contacts: number | string;
  trim: string;
  max_vehicle_id: string;
  model: string;
  vehicle_id: string;
  vehicle_type: string;
};

type AssignedChampionType = {
  championID: string;
  championName: string;
};

export const statusData = [
  { name: 'Active Vehicle', value: 'Active Vehicle' },
  { name: 'Inactive Vehicle', value: 'Inactive Vehicle' },
  { name: 'HP Completed Vehicle', value: 'HP Completed Vehicle' }
];

export const countryData = [
  { name: 'All countries', value: 'All countries' },
  { name: 'Nigeria', value: 'Nigeria' },
  { name: 'Ghana', value: 'Ghana' },
  { name: 'Kenya', value: 'Kenya' },
  { name: 'Eygpt', value: 'Egypt' }
];

export const VehicleActivity: FC = () => {
  const [dateValue, onChange] = useState([new Date(), new Date()]);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizes, setPageSizes] = useState(10);
  // const [csvPageSize] = useState(1577);
  const [city, setCity] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [brand, setBrand] = useState('');
  const [status, setStatus] = useState('');
  const [championContractDetails, setChampionContractDetails] = useState<any>([]);
  const [vehicleActivityModalData, setVehicleActivityModalData] = useState<VehicleActivityModalDataType>({
    asset_class: '',
    city: '',
    manufacturer: '',
    plate_number: '',
    vehicle_status: '',
    total_amount_collected: 0,
    total_number_of_contacts: 0,
    trim: '',
    max_vehicle_id: '',
    model: '',
    vehicle_id: '',
    vehicle_type: ''
  });
  const [assignedChampionInfo, setAssignedChampionInfo] = useState<AssignedChampionType>({
    championID: '',
    championName: ''
  });
  const [filterOptions, setfilterOptions] = useState({
    search: ''
  });

  const { isOpen, onClose, onOpen } = useDisclosure();

  const queryClient = useQueryClient();
  const onChampionDetailsFetched = ({ data }) => {
    queryClient.invalidateQueries(QUERY_KEYS.GET_VEHICLE_ACTIVITY_CHAMPION_DETAILS);
    setChampionContractDetails(data);
  };

  const { mutate: getChampionDetails, isLoading: isGetChampionLoading } = useMutationWrapper(
    postRequest,
    onChampionDetailsFetched
  );
  const viewChampionDetails = (id: string) => {
    const data = {
      vehicle_ids: [id]
    };
    getChampionDetails({
      url: championContractRequests.GET_ACTIVE_CHAMPION_CONTRACT_DETAILS,
      data
    });
  };

  const onRowSelect = (selectedRow: any) => {
    onOpen();
    setVehicleActivityModalData({
      asset_class: selectedRow?.asset_class,
      city: selectedRow?.city,
      manufacturer: selectedRow?.manufacturer,
      plate_number: selectedRow?.plate_number,
      vehicle_status: selectedRow?.vehicle_status,
      total_amount_collected: selectedRow?.total_amount_collected,
      total_number_of_contacts: selectedRow?.total_number_of_contacts,
      trim: selectedRow?.trim,
      max_vehicle_id: selectedRow?.max_vehicle_id,
      model: selectedRow?.model,
      vehicle_id: selectedRow?.vehicle_id,
      vehicle_type: selectedRow?.vehicle_type
    });
    setAssignedChampionInfo({
      championID: selectedRow?.current_champion?.id,
      championName: selectedRow?.current_champion?.name
    });
    viewChampionDetails(selectedRow?.vehicle_id);
  };

  const startDate = dateFormatter(dateValue[0]);
  const endDate = dateFormatter(dateValue[1]);

  const allCities = queryClient.getQueryData<any>(QUERY_KEYS.ALL_CITIES);
  const vehicleTypes = queryClient.getQueryData<any>(QUERY_KEYS.ALL_VEHICLE_TYPE);
  const Manufacturers = queryClient.getQueryData<any>(QUERY_KEYS.ALL_MANUFACTURERS);

  const getVehicleActivityData = async (params) => {
    const { queryKey } = params;

    const url = convertParamsToString(vehicleActivity.ALL_VEHICLES, {
      pageNo: queryKey[1],
      city: queryKey[2],
      vehicleType: queryKey[3],
      manufacturer: queryKey[4],
      vehicle_status: queryKey[5],
      startDate: queryKey[6],
      endDate: queryKey[7],
      size: queryKey[8],
      search: queryKey[9].search
    });
    const response = await axiosInstance.get(url);
    setPageCount(response?.data?.data?.pagination?.page_count);
    setCurrentPage(response?.data?.data?.pagination?.current_page);
    setPageSizes(response?.data?.data?.pagination.size);
    return response?.data?.data?.results;
  };

  const { data, isLoading } = useQuery(
    [
      'max_get_vehicle_activity',
      pageNumber,
      city,
      vehicleType,
      brand,
      status,
      startDate,
      endDate,
      pageSizes,
      filterOptions
    ],
    getVehicleActivityData
  );

  const filterHandler = debounce((filterType: string, value: string) => {
    setfilterOptions({
      ...filterOptions,
      [filterType]: value
    });
  }, 3000);

  // const getVehicleCsvData = async (params) => {
  //   const { queryKey } = params;

  //   const url = convertParamsToString(vehicleActivity.ALL_VEHICLES_CSV_DOWNLOADABLE, {
  //     size: queryKey[1]
  //   });

  //   const response = await axiosInstance.get(url);
  //   return response?.data?.data?.results;
  // };

  // const { data: csvData, isSuccess } = useQuery(['csv_vehicle_data', csvPageSize], getVehicleCsvData);

  // const checkDownloadable = async () => {
  //   if (isSuccess) {
  //     return downloadCsv(csvData, 'Vehicle Activity Report');
  //   }
  // };

  const COLUMNS = [
    {
      Header: 'Vehicle Type',
      accessor: 'avatar',
      Cell: ({ cell }) => {
        let vehicleType = cell.row.original.vehicle_type;

        const switchAvatarIcons = {
          Car: CarIcon,
          Tricycle: TricycleIcon,
          Motorcycle: MotorcycleIcon
        };

        const rowAvatar = switchAvatarIcons[vehicleType] || 'Car';

        return (
          <Flex align="center" gap={2}>
            <Avatar size="sm" name={vehicleType} src={rowAvatar} bg="#fae8d0" />
            <Text fontWeight={600}>{vehicleType}</Text>
          </Flex>
        );
      }
    },
    {
      Header: 'Manufacturer',
      accessor: 'manufacturer'
    },
    {
      Header: 'Vehicle Model',
      accessor: 'model'
    },
    {
      Header: 'Vehicle ID',
      accessor: 'max_vehicle_id'
    },
    {
      Header: 'Plate Number',
      accessor: 'plate_number'
    },
    {
      Header: 'Mileage',
      accessor: 'mileage'
    },
    {
      Header: 'City (Location)',
      accessor: 'city',
      Cell: ({ cell }) => {
        const { city } = cell.row.original;
        return <Text textTransform="capitalize">{city}</Text>;
      }
    },
    {
      Header: 'Total Amount Collected',
      accessor: 'total_amount_collected',
      Cell: ({ cell }) => {
        const { total_amount_collected } = cell.row.original;
        return <Text>{Number(total_amount_collected).toLocaleString()}</Text>;
      }
    },
    {
      Header: 'Total Number of Contacts',
      accessor: 'number_of_contract',
      Cell: ({ cell }) => {
        const total_number_of_contacts = cell.row.original.number_of_contract;
        return <Text>{total_number_of_contacts}</Text>;
      }
    },
    {
      Header: 'Status',
      accessor: 'vehicle_status',
      Cell: ({ cell }) => {
        const vehicle_status = cell.row.original.vehicle_status;
        return (
          <Flex justify="center">
            <StatusDisplay borderRadius="none" status={vehicle_status} fontSize="12px" />
          </Flex>
        );
      }
    }
  ];

  return (
    <>
      <FilterContainer pageName="Vehicle Activity">
        <Box borderLeft="1px" borderColor="gray.200" pl={4}>
          <Text fontSize="0.875rem" color="rgb(128, 128, 128)">
            Country
          </Text>
          <Text color="rgb(62, 62, 62)" fontSize="1.125rem" fontWeight="bold">
            Nigeria
          </Text>
        </Box>
      </FilterContainer>
      <PageWrapper>
        <Box mt="16px">
          <Card>
            {isLoading ? (
              <TableLoader />
            ) : (
              <>
                <Flex py={2} px={6} gap={2}>
                  <Square flex="2" gap={2}>
                    <Text fontSize="sm">TABLE FILTER</Text>

                    {!showSearchInput && (
                      <Select
                        placeholder={city ? city : 'Select a city'}
                        color="#707070"
                        focusBorderColor="#ffdd00"
                        fontSize="sm"
                        onChange={(e) => setCity(e.target.value)}
                      >
                        {allCities?.map((role) => (
                          <option key={nanoid()} value={role.name}>
                            {role.name}
                          </option>
                        ))}
                      </Select>
                    )}

                    {!showSearchInput && (
                      <Select
                        placeholder={vehicleType ? vehicleType : 'Vehicle type'}
                        color="#707070"
                        focusBorderColor="#ffdd00"
                        fontSize="sm"
                        onChange={(e) => setVehicleType(e.target.value)}
                      >
                        {vehicleTypes?.map((role) => (
                          <option key={nanoid()} value={role.name}>
                            {role.name}
                          </option>
                        ))}
                      </Select>
                    )}

                    {!showSearchInput && (
                      <Select
                        placeholder={brand ? brand : 'Select brand'}
                        color="#707070"
                        focusBorderColor="#ffdd00"
                        fontSize="sm"
                        onChange={(e) => setBrand(e.target.value)}
                      >
                        {Manufacturers?.map((role) => (
                          <option key={nanoid()} value={role.name}>
                            {role.name}
                          </option>
                        ))}
                      </Select>
                    )}

                    {!showSearchInput && (
                      <Select
                        placeholder={status ? status : 'Select status'}
                        color="#707070"
                        focusBorderColor="#ffdd00"
                        fontSize="sm"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        {statusData?.map((role) => (
                          <option key={nanoid()} value={role.name}>
                            {role.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  </Square>
                  <Flex flex="1" justify="flex-end">
                    <HStack divider={<StackDivider borderColor="gray.200" />} spacing={5}>
                      {!showSearchInput && <DateRangePicker onChange={onChange} value={dateValue} />}

                      {showSearchInput ? (
                        <SearchInputContainer>
                          <Flex bg="#EEEEEE" w="64rem" align="center" h="45px" px="10px">
                            <Search2Icon style={{ marginRight: '5px' }} />
                            <input
                              type="text"
                              placeholder="search table"
                              className="search_table_input"
                              onChange={(e) => {
                                filterHandler(FILTER_CONSTANT.SEARCH, e.target.value);
                              }}
                            />
                            <Flex
                              align="center"
                              fontSize="0.875rem"
                              color="#3e3e3e"
                              textTransform="uppercase"
                              onClick={() => setShowSearchInput(false)}
                            >
                              <Text as="p" mr="10px">
                                X
                              </Text>
                              <Text>Cancel</Text>
                            </Flex>
                          </Flex>
                        </SearchInputContainer>
                      ) : (
                        <Box px={2} _hover={{ cursor: 'pointer' }}>
                          <Search2Icon onClick={() => setShowSearchInput(true)} />
                        </Box>
                      )}

                      {/* <Button
                        leftIcon={<Image src={ExcelIcon} alt="" w={10} />}
                        pl={0}
                        pr="10px"
                        bg="#fff"
                        fontWeight={600}
                        _hover={{ backgroundColor: '#fff' }}
                        onClick={checkDownloadable}
                      >
                        Export to CSV
                      </Button> */}
                    </HStack>
                  </Flex>
                </Flex>
                <NewTable
                  emptyDataDescription="There is no record of this vehicle"
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  pageCount={pageCount}
                  currentPage={currentPage}
                  setPageSizes={setPageSizes}
                  pageSizes={pageSizes}
                  tableData={data || []}
                  tableColumns={COLUMNS || []}
                  selectedRow={onRowSelect}
                />
              </>
            )}
          </Card>
        </Box>
      </PageWrapper>
      <VehicleActivityModal
        isOpen={isOpen}
        onClose={onClose}
        vehicleInfo={vehicleActivityModalData}
        championInfo={assignedChampionInfo}
        currentContractInfo={championContractDetails}
        isGetChampionLoading={isGetChampionLoading}
      />
    </>
  );
};
