import { FC, useState } from 'react';
import { Box, Button, Flex, InputLeftElement, InputGroup, Text } from '@chakra-ui/react';
import { Search2Icon, WarningIcon } from '@chakra-ui/icons';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useMaintenanceContext } from 'pages/maintenance/context'; //!1
import { KEY_LS_MAX_USER_VAMS, QUERY_KEYS } from 'util/constants';

import Modal from 'components/BaseModal';
import axiosInstance, { maintenanceRequest } from 'services/httpService';
import SearchBoxTechinicianAndQA from 'pages/vehicles/vehicles-nav/SearchBoxTechinicianAndQA';

interface AssignTechnicianModalI {
  isOpen: boolean;
  onClose: () => void;
  setReassignTechnician: any;

  refetch: any;
  reassignTechnician: {
    shouldReassign: boolean;
    currentTechnicianId: string;
  };
}

interface TechnicianDataI {
  user_account_id: string;
  id: string;
  first_name: string;
  last_name: string;
  role_id?: string;
  email?: string;
  phone?: string;
}

export const AssignTechnicianModal: FC<AssignTechnicianModalI> = ({ isOpen, onClose, reassignTechnician, refetch }) => {
  const fleetOfficerData = JSON.parse(localStorage.getItem(KEY_LS_MAX_USER_VAMS) || '{}');

  const { vehicleData, technicianRoleId } = useMaintenanceContext();
  const [searchText, setSearchText] = useState('');
  //const [datas, setDatas] = useState([]);

  const handleClose = () => {
    onClose();
    setSearchText('');
  };

  const [selectedTechnician, setSelectedTechnician] = useState<TechnicianDataI>({
    user_account_id: '',
    id: '',
    first_name: '',
    last_name: '',
    role_id: '',
    email: ''
  });

  const assignTechnicianPayload = {
    username: `${selectedTechnician?.first_name} ${selectedTechnician?.last_name}`,
    email: selectedTechnician?.email,
    role_id: selectedTechnician?.role_id,
    phone_number: selectedTechnician?.phone,
    maintenance_schedule_id: vehicleData?.maintenance_schedule?.id,
    maintenance_id: vehicleData?.id,
    fleetOfficerName: `${fleetOfficerData?.first_name} ${fleetOfficerData?.last_name}`,
    fleetOfficerLocation: fleetOfficerData?.role?.city_id
  };

  const queryClient = useQueryClient();

  // handle assign tester
  const assignTechnicianMutation = useMutation(
    //mutation are used to create,update or delete data
    (data: any) => {
      return axiosInstance.post(`${maintenanceRequest.ASSIGN_TECHNICIAN}`, data); //!    (url,data)
    },
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message);
        queryClient.invalidateQueries(QUERY_KEYS.TECHNICIANS_HISTORY);
        queryClient.invalidateQueries(QUERY_KEYS.GET_MAINTENANCE_VEHICLE_BY_ID);
        refetch();
        handleClose();
      },

      onError: (err: any) => {
        toast.error(err?.response?.data?.message);
      }
    }
  );
  const handleAssignTechnician = () => {
    assignTechnicianMutation.mutate({ ...assignTechnicianPayload, user_id: selectedTechnician?.user_account_id });
  };

  // handle reassign technician
  const reAssignTechnicianMutation = useMutation(
    (data: any) => {
      return axiosInstance.post(`${maintenanceRequest.REASSIGN_TECHNICIAN}`, data); //!push to DB reassigned
    },
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message);
        queryClient.invalidateQueries(QUERY_KEYS.TECHNICIANS_HISTORY);
        queryClient.invalidateQueries(QUERY_KEYS.GET_MAINTENANCE_VEHICLE_BY_ID);
        handleClose();
        refetch();
      },

      onError: (err: any) => {
        toast.error(err?.response?.data?.message);
      }
    }
  );
  const handleReassignTechnician = () => {
    reAssignTechnicianMutation.mutate({
      //Unlike queries, mutations are typically used to create/update/delete data or perform server side-effects
      ...assignTechnicianPayload, //the index 0 and index 1
      account_user_id: selectedTechnician?.user_account_id,
      initial_assigned_user_id: reassignTechnician?.currentTechnicianId // uuid of past technicians
    });
  };

  const searchUser = async (params: any) => {
    const url = `${maintenanceRequest.ALL_ACTIVE_USERS}/${technicianRoleId}?role=technician&status=active${
      searchText && `&search_query=${searchText}`
    }`;
    const response = await axiosInstance.get(url);
    // setDatas(response.data.data.users);
    return response.data.data;
  };
  const allUsers = useQuery([QUERY_KEYS.TECHNICIANS, searchText], searchUser, {
    enabled: !!technicianRoleId
  });
  const temp = allUsers.data?.users;

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={`${reassignTechnician.shouldReassign ? 'Reassign' : 'Assign'} Technician`}
      size="lg"
      subTitle="Please select the technician to work on this assignment."
    >
      <Text color="#707070" fontSize="0.875rem" px={5} py={4}>
        Select Technician
      </Text>
      <Box bgColor="#EEEEEE" borderRadius="8px" px={5} py={4}>
        <InputGroup bgColor="#fff" borderRadius="8px" _focus={{ color: 'yellow.200' }}>
          <InputLeftElement pointerEvents="none" children={<Search2Icon color="gray.300" />} />

          <SearchBoxTechinicianAndQA
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={'Search for technicians'}
          />

          {/* check1 */}
        </InputGroup>
        <Flex
          flexDirection="column"
          bgColor="#fff"
          borderRadius="8px"
          gap={1}
          mt={3}
          maxH="40vh"
          overflowY="scroll"
          px={5}
          py={2}
        >
          {/* old techinian search */}
          {!temp?.length ? (
            <Box textAlign="center" p={10}>
              <WarningIcon w={5} color="gray.400" mb={2} />
              <Text maxW="md">We have no record of that technician.</Text>
            </Box>
          ) : (
            temp?.map((technician, idx) => (
              <Box
                onClick={() => setSelectedTechnician(technician)}
                border="1px"
                borderColor={
                  selectedTechnician?.user_account_id === technician?.user_account_id ? 'yellow.400' : 'gray.200'
                }
                borderRadius="2px"
                cursor="pointer"
                key={idx}
                px={5}
                py={3}
              >
                <Text>
                  {technician.first_name} {technician.last_name}
                </Text>
              </Box>
            ))
          )}
        </Flex>
      </Box>
      <Flex justifyContent="space-between" mt={5}>
        <Button
          onClick={reassignTechnician.shouldReassign ? handleReassignTechnician : handleAssignTechnician}
          bgColor="#FFCC00"
          borderRadius="4px"
          fontSize="0.8125rem"
          px={6}
          py={2}
        >
          {reassignTechnician.shouldReassign ? 'REASSIGN TECHNICIAN' : 'ASSIGN TECHNICIAN'}
        </Button>
        <Button bgColor="#EEEEEE" borderRadius="4px" fontSize="0.8125rem" px={6} py={2} onClick={handleClose}>
          CANCEL
        </Button>
      </Flex>
    </Modal>
  );
};
