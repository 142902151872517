import { FC } from 'react';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

import { StatusDisplay } from 'components/StatusDisplay/StatusDisplay';
import { RenderLoader } from 'components/Loaders/RenderLoader';
import { RenderEmptyData } from 'components/EmptyData/EmptyData';

import ChampionHelmetIcon from 'assets/images/vehicle-activity/champion-helmet.svg';

type PastChampionDataProps = {
  pastChampionData: any;
  isLoading: boolean;
};

export const PreviousChampions: FC<PastChampionDataProps> = ({ pastChampionData, isLoading = true }) => {
  const pastChampions = pastChampionData?.past_champions;

  const RenderPastChampions = () => {
    return pastChampions?.map((champion) => (
      <Box bg="#F7F7F7" border="1px solid #EEEEEE" borderRadius="10px" p={5} key={nanoid()}>
        <Flex gap={2}>
          <Avatar src={ChampionHelmetIcon} bg="#fae8d0" />
          <Box direction="column">
            <Text color="rgba(62, 62, 62, 1)" fontSize="1.25rem" fontWeight={600}>
              {champion?.name ?? 'N/A'}
            </Text>
            <Text color="#E88E15" fontSize="0.875rem" fontWeight={600}>
              {champion?.max_champion_id ?? 'N/A'}
            </Text>
          </Box>
          <Box justifyContent="center">
            <StatusDisplay borderRadius="none" status={champion?.champion_status ?? 'N/A'} fontSize="12px" />
          </Box>
        </Flex>
      </Box>
    ));
  };

  return (
    <Flex direction="column" gap={4}>
      {isLoading ? (
        <RenderLoader />
      ) : pastChampions?.length === 0 ? (
        <RenderEmptyData description="No previous champion was found." />
      ) : (
        <RenderPastChampions />
      )}
    </Flex>
  );
};
