export const platforms = [
  { value: 'Max', label: 'Max' },
  { value: 'Max Private', label: 'Max Private' },
  { value: 'Bolt Cars', label: 'Bolt Cars' },
  { value: 'Bolt Food', label: 'Bolt Food' },
  { value: 'Gokada', label: 'Gokada' },
  { value: 'Glovo', label: 'Glovo' },
  { value: 'Yandex', label: 'Yandex' }
];


export const unremittedDays = [
  { value: 'today', label: 'Today' },
  { value: '2', label: '2' },
  { value: '7', label: '7' },
  { value: '12', label: '12' },
  { value: '14', label: '14' }
];

export const inactiveReasons = [
  { value: 'inter_city', label: 'Inter City' },
  { value: 'unscheduled_maintenance', label: 'Unscheduled Maintenance' },
  { value: 'scheduled_maintenance', label: 'Scheduled Maintenance' },
  { value: 'financial_default', label: 'Financial Default' },
  { value: 'good_standing', label: 'Good standing' },
  { value: 'accident', label: 'Accident' },
  { value: 'intra_city', label: 'Intra City' },
  { value: 'pick_up_papers', label: 'Pick up Papers' },
  { value: 'theft', label: 'Theft' },
  { value: 'travel', label: 'Travel' },
  { value: 'sick', label: 'Sick' },
  { value: 'complete_hP', label: 'Complete HP' },
  { value: 'violation', label: 'Violation' }
];

export const manufacturers = [
  { value: 'yamaha', label: 'Yamaha' },
  { value: 'bajaj', label: 'Bajaj' },
  { value: 'tvs', label: 'TVS' },
  { value: 'qlink', label: 'Qlink' },
  { value: 'denstar', label: 'Denstar' },
  { value: 'motobi', label: 'Motobi' },
  { value: 'degab', label: 'Degab' },
  { value: 'twinco', label: 'Twinco' },
  { value: 'sinoki', label: 'Sinoki' }
];