import React, { createContext, useState, useContext } from 'react';

export const MaintenanceContext = createContext();

const MaintenanceContextProvider = (props) => {
  const [vehicleData, setVehicleData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pastQaTesters, setPastQaTesters] = useState([]);
  const [pastTechnicians, setPastTechnicians] = useState([]);
 const [testersReport, setTestersReport] = useState({});
  const [techniciansReport, setTechniciansReport] = useState({});
  const [allJobCards, setAllJobCards] = useState([]);
  const [jobCardDetails, setJobCardDetails] = useState([]);
  const [maintenanceReportID, setMaintenanceReportID] = useState([]);
  const [technicianDetails, setTechnicianDetails] = useState([]);
  const [qaTesterRoleId, setQaTesterRoleId] = useState('');
  const [technicianRoleId, setTechnicianRoleId] = useState('');

  return (
    <MaintenanceContext.Provider
      value={{
        isLoading,
        setIsLoading,
        pastQaTesters,
        setPastQaTesters,
        pastTechnicians,
        setPastTechnicians,
        testersReport,
        setTestersReport,
        techniciansReport,
        setTechniciansReport,
        allJobCards,
        setAllJobCards,
        jobCardDetails,
        setJobCardDetails,
        maintenanceReportID,
        setMaintenanceReportID,
        technicianDetails,
        setTechnicianDetails,
        vehicleData,
        setVehicleData,
        qaTesterRoleId,
        setQaTesterRoleId,
        technicianRoleId,
        setTechnicianRoleId,
      
      }}
    >
      {props.children}
    </MaintenanceContext.Provider>
  );
};

export default MaintenanceContextProvider;

export const useMaintenanceContext = () => {
  return useContext(MaintenanceContext);
};
