import { Table as ChakraTable, Tbody, Thead, Th, Tr } from '@chakra-ui/react';

export const Table = ({ columns, tableData }) => {
  return (
    <ChakraTable>
      <Thead bg="#FFF8CE">
        <Tr>
          {columns.map((column, index) => (
            <Th key={index} fontSize="9px">
              {column?.title}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>{tableData}</Tbody>
    </ChakraTable>
  );
};
