import { FC } from 'react';
import { Text, Divider, Grid, GridItem } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

type VehicleInformationProps = {
  title: string;
  vehicleInformation: any;
};

export const VehicleInformationBlock: FC<VehicleInformationProps> = ({ title, vehicleInformation }) => {
  return (
    <>
      <Text fontSize="0.75rem" color="#808080" px={4} py={2}>
        {title}
      </Text>
      <Divider />
      <Grid templateColumns="repeat(2, 1fr)" gap={3} px={5} py={2}>
        {vehicleInformation.map((vehicleInfo) => (
          <GridItem w="100%" key={nanoid()}>
            <Text fontSize="0.75rem" color="rgba(62, 62, 62, 0.5)">
              {vehicleInfo.title}
            </Text>
            <Text color="rgba(62, 62, 62, 1)" fontSize="0.875rem" fontWeight={600}>
              {vehicleInfo.value ?? 'N/A'}
            </Text>
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
