import { FC, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

type CardProps = {
  children: ReactNode;
  padding?: string | number;
};

export const Card: FC<CardProps> = ({ children, padding }) => {
  return (
    <Box w="100%" bg="#fff" border="1px solid #eeeeee" borderRadius="10px" p={padding}>
      {children}
    </Box>
  );
};
