import { prepareForCSV } from "pages/reports/reportApi";
import { JSONToCSVConvertor } from "../util";

export const downloadCsv = (arr: Array<any>, reportTitle: string) => {
  try {
    const reArrangedData = prepareForCSV(arr);
    JSONToCSVConvertor(reArrangedData, reportTitle, true);
  } catch (error) {
    // TODO handle error
  }
};
