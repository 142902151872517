import React, { FC } from 'react';
import { Box, Button, Flex, Grid, Textarea } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import axiosInstance, { maintenanceRequest } from 'services/httpService';
import { useCheckListUpdate } from './hooks';
import { QUERY_KEYS } from 'util/constants';

import Modal from 'components/BaseModal';
import { SelectDropdown } from 'components/UiElements/SelectDropdown/select-dropdown';
import { useMaintenanceContext } from 'pages/maintenance/context';
import { SelectDropdownCheckbox } from 'components/UiElements/SelectDropdown/select-dropdown-checkbox';

interface TechnicianReportEntryModalI {
  isOpen: boolean;
  onClose: () => void;
  refetch: any;
}

export const TechnicianReportEntryModal: FC<TechnicianReportEntryModalI> = ({ isOpen, onClose, refetch }) => {
  const { vehicleData, setTechniciansReport } = useMaintenanceContext();

  const queryClient = useQueryClient();

  const initialValues = {
    code: '',
    comment: '',
    response: '',
    response_by_user_id: ''
  };

  const getMaintenanceChecklist = async () => {
    const url = `${maintenanceRequest.GET_MAINTENANCE_CHECKLIST}`;
    const data = await axiosInstance.get(url);
    return data;
  };
  const { data: checklist = [] }: any = useQuery(['report-checklist'], () => getMaintenanceChecklist(), {
    // enabled: !!vehicleData?.maintenance_schedule?.maintenance_id
  });
  const checklistData = checklist && checklist?.data?.data;

  const {
    checklistMetaData,
    updateChecklistMetaData,
    disableOilField,
    updateOilValue,
    literOptions,
    setOilInLiters,
    oilInLiters,
    setCheckListData
  } = useCheckListUpdate();

  const getDropdownDefaultValue = () => {
    return { label: 'Sounds well', value: 'ok' };
  };

  const mutation = useMutation((data) => axiosInstance.post(`${maintenanceRequest.MAINTENANCE_REPORT}`, data), {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      setTechniciansReport(data?.data?.data);
      setOilInLiters(null);
      refetch();
      queryClient.invalidateQueries(QUERY_KEYS.GET_JOB_CARD_BY_ID);
    },
    onError: (error: any) => {
      toast.error(error?.message);
      setOilInLiters(null);
    },
    onSettled: () => {
      onClose();
    }
  });

  React.useEffect(() => {
    setCheckListData(checklistData);
    // eslint-disable-next-line
  }, [checklistData]);

  const handleSubmitReport = (values) => {
    values = {
      ...values,
      maintenance_id: vehicleData?.maintenance_schedule?.maintenance_id,
      maintenance_schedule_id: vehicleData?.maintenance_schedule?.id,
      category: 'technician',
      checklist_metadata: checklistMetaData
    };
    const { code, ...otherValues } = values;

    mutation.mutate(otherValues);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Technician's Report"
        size="2xl"
        subTitle="Please tick the appropriate boxes corresponding to the technician’s report"
      >
        <Formik initialValues={initialValues} onSubmit={(values) => handleSubmitReport(values)} validateOnMount>
          {(formik) => (
            <Form>
              <Flex direction="column" gap={1} px={5} py={3}>
                <Box py={3} border="1px" borderColor="#DEE1E6" borderRadius="md">
                  <Box paddingBottom={5} maxH="40vh" overflowY="auto">
                    {checklistData.map((item, idx) => (
                      <Grid px={10} pb={4} key={idx} templateColumns="repeat(5, auto)">
                        {/* this part i have added radio button  */}
                        <Grid gap={2} templateColumns={item.name.toLowerCase() === 'oil' ? '1fr 1fr' : '1fr'}>
                          <SelectDropdownCheckbox
                            isClearable={false}
                            defaultValue={getDropdownDefaultValue}
                            handleChange={(selectedOption) => {
                              updateChecklistMetaData({
                                name: item.name,
                                data: selectedOption,
                                options: item.statusOptions
                              });
                            }}
                            name={item?.name}
                            options={item?.statusOptions.map((el) => ({
                              label: el.text,
                              value: el.status
                            }))}
                          />

                          {item.name.toLowerCase() === 'oil' && (
                            <SelectDropdown
                              isDisabled={disableOilField}
                              isClearable={true}
                              value={literOptions.find(({ value }) => value === oilInLiters)}
                              defaultValue={{ label: 'Select quantity ', value: '' }}
                              handleChange={(selectedOption) => {
                                updateOilValue(selectedOption);
                              }}
                              name="oilInLiters"
                              options={literOptions}
                            />
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Box>
                <Field as={Textarea} mt={2} borderRadius="md" name="comment" placeholder="Type your comments here" />

                <Flex justifyContent="space-between" mt={8}>
                  <Button
                    bgColor="#FFCC00"
                    borderRadius="4px"
                    fontSize="0.8125rem"
                    isDisabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
                    px={6}
                    py={2}
                    type="submit"
                  >
                    SUBMIT REPORT
                  </Button>
                  <Button bgColor="#EEEEEE" borderRadius="4px" fontSize="0.8125rem" onClick={onClose} px={6} py={2}>
                    CANCEL
                  </Button>
                </Flex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
