import React from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { QUERY_KEYS } from '../vehicles/queryKeys';
import { QUERY_KEYS as U_QUERY_KEYS } from 'util/constants';
import { getVehicleType } from 'util/filtersHelper';
import axiosInstance, { configServiceRequest, vehiclePageRequest } from 'services/httpService';
import PageWrapper from 'components/page-wrapper';
import BasicTable from 'components/BasicTable';
import Paginator, { PageChangeType } from 'components/Paginator';
import { Box } from '@chakra-ui/react';
import ReadyForActivationNav from './nav/ready-for-activation-nav';
import { JSONToCSVConvertor } from 'util/index';

const VehiclesReadyForActivations = () => {
  const [CSVData, setCSVData] = React.useState([]);
  const [pageNo, setPageNo] = React.useState(1);
  const [pageForPaginator, setpageForPaginator] = React.useState(0);
  const [pageSize, setpageSize] = React.useState('20');
  const [filterOptions, setfilterOptions] = React.useState({
    location: '',
    vehicle_type: ''
  });

  const getBatch = async () => {
    const response = await axiosInstance.get(vehiclePageRequest.ALL_BATCHES);
    const allTrims = response.data.data.map((loc: any) => {
      return {
        value: loc.id,
        name: loc.name
      };
    });
    return allTrims;
  };

  const allVehicles = useQuery([U_QUERY_KEYS.ALL_VEHICLE_TYPE], getVehicleType);
  const { data: allBatch = [] } = useQuery([QUERY_KEYS.ALL_BATCH], getBatch);

  const filterData = {
    allBatch,
    allVehicleTypes: allVehicles.data || []
  };

  const getMethod = async ({ queryKey }) => {
    let { route, params } = queryKey[1];
    try {
      const { data } = await axiosInstance.get(`${route}`, {
        params
      });
      return data;
    } catch (error) {
      throw error;
    }
  };
  const { data, isLoading } = useQuery(
    [
      'get-interviews',
      {
        route: `${process.env.REACT_APP_AWS_SDK_VEHICLE_SERVICE_URL}/vehicle-service/vehicle/ready-for-activation`,
        params: {
          location: filterOptions.location,
          vehicle_type: filterOptions.vehicle_type,
          page: pageNo,
          limit: pageSize
        }
      }
    ],
    getMethod,
    {
      onSuccess: (data) => {
        setCSVData(prepareForCSV(data));
      }
    }
  );

  const columns = [
    {
      Header: 'Vehicle Type',
      accessor: 'vehicleType'
    },
    {
      Header: 'Vehicle Manufacturer',
      accessor: 'vehicleManufacturer'
    },
    {
      Header: 'Plate Number',
      accessor: 'plateNumber'
    },
    {
      Header: "Receiver's Name",
      accessor: 'recieverName'
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber'
    },
    {
      Header: 'Trim',
      accessor: 'vehicleTrim'
    },

    {
      Header: 'Location',
      accessor: 'vehicleCity'
    },
    {
      Header: 'Platform',
      accessor: 'platformInfo',
      Cell: ({ value }: any) => value || 'N/A'
    },
    {
      Header: 'Status',
      accessor: 'vehicleStatus'
    }
  ];

  const pageChanged = (page: PageChangeType) => {
    setpageForPaginator(page.selected);
    if (page.selected === 0) {
      setPageNo(1);
    } else if (page.selected > pageForPaginator) {
      setPageNo(page.selected + 1);
    } else {
      setPageNo(page.selected - 1);
    }
  };

  const handleDownloadAll = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_AWS_SDK_VEHICLE_SERVICE_URL}/vehicle-service/vehicle/ready-for-activation`
      );
      const reArrangedData = prepareForCSV(response.data);
      JSONToCSVConvertor(reArrangedData, 'all vehicles ready for activation data', true);
    } catch (error) {
      // TODO handle error
    }
  };

  const filterHandler = (filterType: string, value: string) => {
    setfilterOptions({
      ...filterOptions,
      [filterType]: value
    });
  };

  const getSubCitiesWithSearch = async (inputValue) => {
    const response = await axiosInstance.get(configServiceRequest.GET_ALL_SUB_LOCATIONS, {
      params: {
        include_search: true,
        search: inputValue
      }
    });
    const mappedLocation = response.data.payload.message.objects.map((loc: any) => {
      return {
        value: loc.id,
        label: loc.name,
        name: loc.name
      };
    });

    const filteredLoc = mappedLocation.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    return [...filteredLoc];
  };

  return (
    <VehiclesReadyForActivations.Wrapper>
      <ReadyForActivationNav
        handleDownload={() => JSONToCSVConvertor(CSVData, 'Vehicles_Ready_For_Activation', true)}
        handleDownloadAll={handleDownloadAll}
        filterHandler={filterHandler}
        filterData={filterData}
        getSubCitiesWithSearch={getSubCitiesWithSearch}
      />
      <PageWrapper>
        <div className="table-wrap">
          <BasicTable
            data={data?.data?.result || []}
            columns={columns}
            loading={isLoading}
            startIndex={+pageSize * pageForPaginator + 1}
          />
          <Box mr="4">
            {!isLoading && data?.data?.result?.length > 0 && (
              <Paginator
                pageCount={data?.data?.total / data?.data?.limit}
                onPageChange={pageChanged}
                pageNo={pageForPaginator}
                pageSize={pageSize}
                onSizeChange={(selectedPage) => {
                  setpageSize(selectedPage);
                  setPageNo(1);
                  setpageForPaginator(0);
                }}
              />
            )}
          </Box>
        </div>
      </PageWrapper>
    </VehiclesReadyForActivations.Wrapper>
  );
};

VehiclesReadyForActivations.Wrapper = styled.div`
  .pagination-holder {
    margin-top: 1rem;
    align-items: center;
    float: right;
    display: flex;
    align-items: center;
  }
  .select {
    background-color: #fff;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .table-holder {
    overflow-x: scroll;
  }
`;

export default VehiclesReadyForActivations;

function prepareForCSV(response: any) {
  return response.data.result.map((item: any, index: number) => {
    return {
      'S/N': index + 1,
      'Vehicle Type': item.vehicleType,
      'Vehicle Manufacturer': item.vehicleManufacturer,
      'Vehicle Model': item.vehicleModel,
      'Max Vehicle ID': item.vehicle_id,
      'Plate Number': item.plateNumber,
      'Tracker IMEI': item.device_IMEI,
      'Date Added': item.createdTime,
      Batch: item.batch,
      Location: item.location,
      Status: item.vehicleStatus
    };
  });
}
