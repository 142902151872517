import { FC, ReactNode } from 'react';
import { chakra, Container } from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';

type PageWrapperProps = {
  children: ReactNode;
};

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children'
});

const PageHolder: FC<PageWrapperProps> = ({ children }) => {
  return (
    <ChakraBox
      initial={{ opacity: 0, x: -30 }}
      animate={{ opacity: 1, x: 0 }}
      // @ts-ignore no problem in operation, although type error appears.
      transition={{
        ease: 'easeInOut'
      }}
    >
      <Container maxW="1320px" px={2} py={4} h="100%">
        {children}
      </Container>
    </ChakraBox>
  );
};

export default PageHolder;
