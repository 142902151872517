import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Heading,
  Text,
  Box
} from '@chakra-ui/react';

export type BaseModalProps = {
  isOpen: boolean;
  onClose: () => any;
};

interface Props extends BaseModalProps {
  isOpen: boolean;
  onClose: () => any;
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  footerChildren?: React.ReactNode;
  closeOnOverlayClick?: boolean;
  size?: (string & {}) | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full';
}

function BaseModal({
  isOpen,
  onClose,
  title,
  subTitle,
  children,
  footerChildren,
  size,
  closeOnOverlayClick = true
}: Props) {
  return (
    <>
      <Modal size={size} closeOnOverlayClick={closeOnOverlayClick} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="#F6F6FC" borderRadius="md">
            <Box ml="4" my="4">
              <Heading fontWeight="600" fontSize="20px" color="#2B2D42">
                {title}
              </Heading>
              <Text fontSize="16px" color="#8D99AE" fontWeight="normal">
                {subTitle}{' '}
              </Text>
            </Box>
          </ModalHeader>
          <ModalBody>{children}</ModalBody>
          <ModalFooter>{footerChildren}</ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BaseModal;
