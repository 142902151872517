import React from 'react';

export const useCheckListUpdate = () => {
  const [checklistMetaData, setChecklistMetaData] = React.useState([]);
  const [disableOilField, setDisableOilField] = React.useState(true);
  const [oilInLiters, setOilInLiters] = React.useState(null);
  const [literOptions, setLiterOptions] = React.useState([]);
  const [checklistData, setCheckListData] = React.useState([]);
  
  React.useEffect(() => {
    if (checklistData?.length) {
      let containerObj = checklistData.filter((item) => item.name.toLowerCase() === 'oil');
      setLiterOptions(
        containerObj[0].statusOptions[2].litres.map((item) => ({
          label: item,
          value: item
        }))
      );
    }
  }, [checklistData]);

  React.useEffect(() => {
    let oilObj = checklistMetaData.filter((item) => item.name.toLowerCase() === 'oil');
    if (oilObj.length > 0) {
       if ((oilObj[0].currentStatus.text === 'To be changed' && oilObj[0].currentStatus.status === 'not_applicable')) {
        setDisableOilField(false);
        
      } else {
        setDisableOilField(true);
        setOilInLiters('');
      }
    }
  }, [checklistMetaData]);

  const updateOilValue = (selectedOption = {}) => {
     if (!selectedOption) return setOilInLiters('');
    setOilInLiters(selectedOption.value);
  };

  React.useEffect(() => {
    if (oilInLiters || oilInLiters === '') {
      const returnIndex = (element) => element?.name.toLowerCase() === 'oil';
      const index = checklistMetaData.findIndex(returnIndex);
      if (index !== -1) {
        setChecklistMetaData((prevState) => {
          return [
            ...prevState.slice(0, index),
            { ...prevState[index], oil_litres: oilInLiters, litres: null },
            ...prevState.slice(index + 1)
          ];
        });
       }
    }
    // eslint-disable-next-line
  }, [oilInLiters]);

  const updateChecklistMetaData = ({ data, name, options }) => {
    const returnIndex = (element) => name === element?.name;
    const index = checklistMetaData.findIndex(returnIndex);
   
    if (index === -1) {
      setChecklistMetaData((prevState) => [
        ...prevState,
        {
          name: name,
          currentStatus: {
            text: data.label,
            status: data.value
          },
          statusOptions: options
        }
      ]);
    
    } else {
      setChecklistMetaData((prevState) => [
        ...prevState.slice(0, index),
        {
          name: name,
          currentStatus: {
            text: data.label,
            status: data.value
          },
          statusOptions: options
        },
        ...prevState.slice(index + 1)
      ]);
    
    }
  };
  return {
    checklistMetaData,
    updateChecklistMetaData,
    disableOilField,
    setDisableOilField,
    oilInLiters,
    setOilInLiters,
    updateOilValue,
    setLiterOptions,
    literOptions,
    setCheckListData,
   
  };
};
