import React from 'react'

import './ActionBar.scss'

interface Props {
    children: React.ReactNode
}

export const ActionBar: React.FC<Props> = ({ children }) => {
    return (
        <>
            <div className="action-bar__container">
                {children}
            </div>
        </>
    )
}
