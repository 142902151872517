import Select from 'react-select';

export const SelectDropdown = (props) => {
  const {
    isDisabled = false,
    isClearable,
    defaultValue,
    handleChange,
    name,
    placeholder,
    options = [{ value: 'sampleValue', label: 'sampleLabel' }]
  } = props;

  return (
    <Select
      isDisabled={isDisabled}
      classNamePrefix="vams-custom-select"
      defaultValue={defaultValue}
      isClearable={isClearable}
      menuPortalTarget={document.body}
      name={name}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );
};
