import { extendTheme } from '@chakra-ui/react';
import { ButtonStyles as Button } from './components/buttonStyles';

// custom themes in chakra UI
// https://chakra-ui.com/docs/theming/customize-theme
//https://www.easyreact.com/articles/chakra-ui-customisations
//https://blog.logrocket.com/advanced-techniques-chakra-ui/

const brandColors = {
  primary: '#ffdd00',
  secondary: '#2FA07224',
  highlight: '#00C9A7',
  warning: '#FFC75F',
  danger: '#C34A36',
  text: '#454545',
  light: '#334DBA24'
};

const variantOutlined = () => ({
  field: {
    _focus: {
      borderColor: 'var(--chakra-ui-focus-ring-color)',
      boxShadow: '0 0 0 2px var(--chakra-ui-focus-ring-color)'
    }
  }
});

const variantFilled = () => ({
  field: {
    _focus: {
      borderColor: 'var(--chakra-ui-focus-ring-color)',
      boxShadow: '0 0 0 1px var(--chakra-ui-focus-ring-color)'
    }
  }
});

const variantFlushed = () => ({
  field: {
    _focus: {
      borderColor: 'var(--chakra-ui-focus-ring-color)',
      boxShadow: '0 1px 0 0 var(--chakra-ui-focus-ring-color)'
    }
  }
});

const VAMSTheme = extendTheme({
  styles: {
    global: {
      body: {
        bg: '#F4F6FA'
      },
      ':host,:root': {
        '--chakra-ui-focus-ring-color': brandColors.primary
      }
    }
  },
  fonts: {
    heading: `'Montserrat', sans-serif`,
    body: `'Source Sans Pro', sans-serif`
  },
  colors: {
    primary: brandColors.primary,
    secondary: brandColors.secondary,
    highlight: brandColors.highlight,
    warning: brandColors.warning,
    danger: brandColors.danger,
    text: brandColors.text,
    light: brandColors.light
  },
  shadows: {
    outline: '0 0 0 3px var(--chakra-ui-focus-ring-color)'
  },
  components: {
    Button,
    Input: {
      variants: {
        outline: variantOutlined,
        filled: variantFilled,
        flushed: variantFlushed
      }
    },
    Select: {
      variants: {
        outline: variantOutlined,
        filled: variantFilled,
        flushed: variantFlushed
      }
    },
    Radio: {
      variants: {
        outline: variantOutlined,
        filled: variantFilled,
        flushed: variantFlushed
      }
    },
    Textarea: {
      variants: {
        outline: () => variantOutlined().field,
        filled: () => variantFilled().field,
        flushed: () => variantFlushed().field
      }
    }
  }
});

export default VAMSTheme;
