import { FC, useEffect, useState } from 'react';
import { Avatar, Box, Center, Flex, Text, Tr, Td, useDisclosure } from '@chakra-ui/react';
// import { CSVLink } from 'react-csv';
import { useQuery } from 'react-query';
import Moment from 'react-moment';
import DatePicker from 'react-datepicker';

import { maintenanceRequest, reportRequest } from 'services/request';
import axiosInstance from 'services/httpService';
import { QUERY_KEYS } from 'util/constants';
import { convertParamsToString } from 'helpers/auth-helpers';

// import { ReactComponent as Excel } from 'assets/images/excel.svg';
import ChampionHelmet from 'assets/images/champion-helmet.svg';

import FilterContainer from 'components/filter-container/filter-container.component';
import PageWrapper from 'components/page-wrapper';
import { MaintenanceSummary } from 'components/Maintenance/MaintenanceSummary';
import Pagination from 'components/UiElements/Pagination/Pagination';
import { SearchFilterWrapper } from 'components/UiElements/SearchFilter';
import { Table } from 'components/Maintenance/Table';
import { DueForMaintenanceCommentsModal } from 'components/Maintenance/DueForMaintenanceCommentsModal';
import { SelectDropdown } from 'components/UiElements/SelectDropdown/select-dropdown';

export const DueForMaintenance: FC = () => {
  const [page, setPage] = useState(1);
  const [dueForMaintenanceId, setDueForMaintenanceId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filterLocation, setFilterLocation] = useState('');
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // const [rowsPerPage] = useState(25); // Leave for future use in case user wants to change number of rows per page

  const { isOpen, onOpen, onClose } = useDisclosure();

  const getAllDueForMaintenanceVehicles = async ({ queryKey }) => {
    try {
      const url = convertParamsToString(maintenanceRequest.DUE_FOR_MAINTENANCE, {
        page: queryKey[1],
        location: queryKey[2],
        search_query: queryKey[3],
        start_date: queryKey[4],
        end_date: queryKey[5]
      });
      const res = await axiosInstance.get(url);

      setPageCount(res?.data?.data?.pagination?.page_count);
      setCurrentPage(res?.data?.data?.pagination?.current_page);

      return res?.data?.data?.results;
    } catch (err) {
      console.error('All due for maintenance vehicles::', err);
    }
  };

  const getAllDueForMaintenanceCounts = async () => {
    try {
      const url = `${maintenanceRequest.DUE_FOR_MAINTENANCE_COUNT}`;
      const res = await axiosInstance.get(url);
      return res?.data?.data;
    } catch (err) {
      console.error('All due for maintenance counts::', err);
    }
  };

  const getAllLocations = async () => {
    const url = reportRequest.ALL_SUB_CITIES;
    const data = await axiosInstance.get(url);
    return data?.data;
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  function handleRowClick(id) {
    setDueForMaintenanceId(id);
    onOpen();
  }

  const { data: dueForMaintenanceVehicleData, isLoading: isLoadingDueForMaintenanceVehicleData }: any = useQuery(
    [
      QUERY_KEYS.DUE_FOR_MAINTENANCE,
      page,
      filterLocation ? filterLocation : '',
      searchQuery ? searchQuery : '',
      startDate ? startDate : '',
      endDate ? endDate : ''
    ],
    getAllDueForMaintenanceVehicles
  );

  const { data: dueForMaintenanceCounts }: any = useQuery(
    QUERY_KEYS.DUE_FOR_MAINTENANCE_COUNTS,
    getAllDueForMaintenanceCounts
  );
  const { data: locationData }: any = useQuery(['locations'], getAllLocations);

  const _location_data =
    locationData &&
    locationData?.payload?.message?.objects?.map((el) => ({
      label: el.name,
      value: el.name
    }));

  useEffect(() => {
    if (searchQuery) {
    } else {
    }
  }, [searchQuery]);

  const mockStats = [
    {
      name: '2 wheelers',
      value: dueForMaintenanceCounts?.two_wheeler ?? 0,
      navlink: '#'
    },
    {
      name: '3 wheelers',
      value: dueForMaintenanceCounts?.three_wheeler ?? 0,
      navlink: '#'
    },
    {
      name: '4 wheelers',
      value: dueForMaintenanceCounts?.four_wheeler ?? 0,
      navlink: '#'
    }
  ];

  const tableHeaders = [
    { title: 'Champion' },
    { title: 'Vehicle Type' },
    { title: 'Phone Number' },
    { title: 'Location' },
    { title: 'Odometer' },
    { title: 'Due Date' },
    { title: 'Overdue (Days)' },
    { title: 'View Comments' }
  ];

  enum MaintenanceVehicleTypeEnum {
    '2 Wheeler' = 1,
    '3 Wheeler' = 2,
    '4 Wheeler' = 3
  }

  return (
    <>
      <FilterContainer canNavigate pageName="Due for Maintenance" />
      <PageWrapper>
        <Flex mt={5}>
          <Box w="300px">
            <MaintenanceSummary
              indexData={mockStats}
              title="Total vehicles due for maintenance"
              totalVehicles={dueForMaintenanceCounts?.total}
            />
          </Box>
          <Box height="700px" overflow="hidden" position={'relative'} background="#fff" width="100%" ml="4">
            <Box height="100%" paddingBottom="81px" position="relative" overflow="scroll">
              <SearchFilterWrapper
                filterTitle=""
                searchPlaceholder="Search for something"
                setSearchQuery={setSearchQuery}
                title="Due for Maintenance"
                toggleBlock={<></>}
                filterBlock={
                  <form className="table-filter-form">
                    <div className="strip-select-style">
                      <SelectDropdown
                        handleChange={(e) => {
                          setFilterLocation(e?.value);
                        }}
                        isClearable
                        name=""
                        options={_location_data}
                        placeholder="Location"
                      />
                    </div>
                    <div className="filter-date-range">
                      {/* <Date */}
                      <DatePicker
                        isClearable
                        selected={startDate}
                        onChange={onDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        placeholderText="Select A Date"
                        // inline
                      />

                      {/* <DatePicker selected={endDate} onChange={(date: Date) => setStartDate(date)} /> */}
                    </div>
                  </form>
                }
                exportBlock={
                  <button>
                    {/* <CSVLink filename="due-for-maintenance-vehicle-list.csv" data={dueForMaintenanceVehicleData || ''}>
                      <Excel style={{ width: '14px' }} /> <span>EXPORT TO CSV</span>
                    </CSVLink> */}
                  </button>
                }
              />
              {isLoadingDueForMaintenanceVehicleData ? (
                <Center pt={5}>Loading...</Center>
              ) : (
                <Table
                  columns={tableHeaders}
                  tableData={dueForMaintenanceVehicleData?.map((data: any) => (
                    <Tr cursor="pointer" fontSize="14px" key={data?.id} onClick={() => handleRowClick(data?.id)}>
                      <Td>
                        <Flex gap={3}>
                          <Avatar bg="#fae8d0" size="sm" src={ChampionHelmet} />
                          <Flex flexDirection="column">
                            <Text color="#3E3E3E" fontWeight="bold">
                              {data?.champion_name}
                            </Text>
                            <Text color="#E88E15" fontSize="0.75rem">
                              {data?.max_champion_id}
                            </Text>
                          </Flex>
                        </Flex>
                      </Td>
                      <Td>{MaintenanceVehicleTypeEnum[data?.vehicle_type]}</Td>
                      <Td>{data?.phone_number}</Td>
                      <Td>{data?.location_name}</Td>
                      <Td>{data?.odometer}</Td>
                      <Td>
                        <Moment format="DD/MM/YYYY">{data?.due_date}</Moment>
                      </Td>
                      <Td>{data?.days_overdue_count}</Td>
                      <Td fontSize="0.75rem">
                        <u>View Comments</u>
                      </Td>
                    </Tr>
                  ))}
                />
              )}
            </Box>
            {dueForMaintenanceVehicleData?.length > 10 && (
              <Box background={'#fff'} bottom={0} width={'100%'} position="absolute">
                <Pagination page={currentPage} pageCount={pageCount} setPage={setPage} />
              </Box>
            )}
          </Box>
        </Flex>
      </PageWrapper>
      <DueForMaintenanceCommentsModal
        isOpen={isOpen}
        onClose={onClose}
        dueForMaintenanceVehicleId={dueForMaintenanceId}
      />
    </>
  );
};
