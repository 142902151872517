import UnAuthenticated from './UnAuthenticated';
import Authenticated from './Authenticated';
import { isMaxAdminAuthenticated } from '../helpers/auth-helpers';

const Pages = () => {
  if (isMaxAdminAuthenticated()) {
    return <Authenticated />
  }

  return <UnAuthenticated />
}

export default Pages
