export const MaintenanceTableColumn = [
  { title: 'Champion Data' },
  { title: 'Plate Number' },
  { title: 'Vehicle Trim' },
  { title: 'Checkin Type' },
  { title: 'Odometer' },
  { title: 'Status' }
];

// export const getTechnicianRoleId = () => {
//   if (process.env.NODE_ENV === 'production') {
//     return '72d2a5d0-0574-47d8-bbd1-5161d7731358';
//   } else {
//     return 'cb23900e-2022-4aea-9aa9-85868ae86fb3';
//   }
// };

// export const getTesterRoleId = () => {
//   if (process.env.NODE_ENV === 'production') {
//     return 'a61df10b-0c7d-4fc0-b689-31ca951a7a0f';
//   } else {
//     return 'b7799351-eb92-4bca-b4f7-e7ac0953a1e8';
//   }
// };
