import styled from 'styled-components'

const GridLayout = ({ children }) => {
  return (
    <GridLayout.Wrapper>
      {children}
    </GridLayout.Wrapper>
  )
}
GridLayout.Wrapper = styled.div`
display:grid;
grid-template-columns:auto 22%;
gap: 2rem;
height:518px;
overflow:hidden;
`
export default GridLayout
