import { IObjectKeys } from '../types/types.d';

export const PROTECTED_PATHS: IObjectKeys = {
  DASHBOARD: '/dashboard',
  VEHICLES: '/vehicles',
  READY_FOR_ACTIVATION: '/ready-for-activation',
  TICKET_MANAGEMENT: '/ticket-management',
  ASSET_MANAGEMENT: '/asset-management',
  REPORTS: '/reports',
  USER_MANAGEMENT: '/user-management',
  MAINTENANCE: '/maintenance',
  UNSCHEDULED_MAINTENANCE: '/maintenance/unscheduled',
  SCHEDULED_MAINTENANCE: '/maintenance/scheduled',
  DUE_FOR_MAINTENANCE: '/maintenance/due-for-maintenance',
  MAINTENANCE_DETAIL: '/maintenance/vehicle/:id',
  REPORT: '/report',
  // VEHICLE_ASSIGNMENT: '/vehicle-assignment',
  NOTIFICATION: '/notification',
  VEHICLE_ACTIVITY: '/vehicle-activity',
  CONTACT_CENTER: '/contact-center',
};

export const PUBLIC_PATHS: IObjectKeys = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/password-reset'
};
