import { FC } from 'react';
import Moment from 'react-moment';
import { Avatar, Box, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import Skeleton from 'react-loading-skeleton';
import { nanoid } from 'nanoid';

import { getVehicleType, capitalizeWords } from 'util/index';

import CarIcon from 'assets/images/vehicle-activity/car-icon.svg';
import MotorcycleIcon from 'assets/images/vehicle-activity/motorcycle-icon.svg';
import TricycleIcon from 'assets/images/vehicle-activity/tricycle-icon.svg';

import { StatusDisplay } from 'components/StatusDisplay/StatusDisplay';
import { useMaintenanceContext } from 'pages/maintenance/context';

export const MaintenanceDetailSummary: FC = () => {
  const { vehicleData, isLoading } = useMaintenanceContext();

  const championFullName = `${
    (vehicleData?.champion?.user?.first_name ?? 'N/A') + ' ' + (vehicleData?.champion?.user?.last_name ?? 'N/A')
  }`;

  const switchAvatarIcons = {
    Car: CarIcon,
    Tricycle: TricycleIcon,
    Motorcycle: MotorcycleIcon
  };

  const vehicleInfoData = [
    { name: 'Location', value: vehicleData?.champion?.location_data?.city.name },
    { name: 'Vehicle type', value: getVehicleType(vehicleData?.vehicle?.vehicle_type_id) },
    { name: 'Trim', value: vehicleData?.vehicle?.trim?.name },
    { name: 'Model', value: vehicleData?.vehicle?.model?.name },
    { name: 'Asset Class', value: vehicleData?.vehicle?.vehicle_type?.name },
    { name: 'Odometer', value: `${vehicleData?.odometer}KM` }
  ];

  const maintenanceDetailsData = [
    {
      name: 'Check-in Time',
      value: <Moment date={vehicleData?.maintenance_schedule?.start_date} format=" h:mm:ss a" />
    },
    { name: 'Check-in type', value: vehicleData?.status },
    {
      name: 'Last Check-in',
      value: <Moment date={vehicleData?.maintenance_schedule?.updated_at} format="Do MMMM YYYY h:mm:ss a" />
    },
    { name: 'KM Covered', value: vehicleData?.distance.toFixed(2) }
  ];

  const RenderLoading = ({ arr }) => {
    return arr.map(() => (
      <GridItem key={nanoid()}>
        <Skeleton height="8px" />
        <Skeleton />
      </GridItem>
    ));
  };

  const RenderStats = ({ arr }) => {
    return arr.map((item) => (
      <GridItem key={nanoid()}>
        <Text color="rgba(62, 62, 62, 0.5)" fontSize="0.8125rem">
          {item.name}
        </Text>
        <Text color="#3E3E3E" fontSize="0.85rem" fontWeight="semibold">
          {item?.value}
        </Text>
      </GridItem>
    ));
  };

  return (
    <>
      <Flex gap={2} py={2}>
        <Avatar bg="#fae8d0" src={switchAvatarIcons['Motorcycle']} />
        <Box>
          <Text color="#3E3E3E" fontWeight="bold">
            {isLoading ? <Skeleton /> : capitalizeWords(championFullName)}
          </Text>
          <Text color="#808080" fontSize="0.825rem" mb={2}>
            Check in:{' '}
            {isLoading ? (
              <Skeleton />
            ) : (
              <Moment date={vehicleData?.maintenance_schedule?.start_date} format="Do MMMM YYYY" />
            )}
          </Text>
          <StatusDisplay borderRadius="none" status={vehicleData?.status} fontSize="12px" />
        </Box>
      </Flex>
      <Box bgColor="#fff" border="1px" borderColor="rgba(238, 238, 238, 1)" borderRadius="10px" mt={4}>
        <Box px={4} py={2}>
          <Text color="rgba(128, 128, 128, 1)" fontSize="0.75rem">
            Vehicle Information
          </Text>
        </Box>
        <Divider />
        <Grid templateColumns="repeat(2, 1fr)" gap={3} px={5} py={4}>
          {isLoading ? <RenderLoading arr={vehicleInfoData} /> : <RenderStats arr={vehicleInfoData} />}
        </Grid>
      </Box>
      <Box bgColor="#fff" border="1px" borderColor="rgba(238, 238, 238, 1)" borderRadius="10px" mt={4}>
        <Box px={4} py={2}>
          <Text color="rgba(128, 128, 128, 1)" fontSize="0.75rem">
            Maintenance Details
          </Text>
        </Box>
        <Divider />
        <Grid templateColumns="repeat(2, 1fr)" gap={3} px={5} py={4}>
          {isLoading ? <RenderLoading arr={maintenanceDetailsData} /> : <RenderStats arr={maintenanceDetailsData} />}
        </Grid>
      </Box>
    </>
  );
};
