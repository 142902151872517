import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

export type PageChangeType = {
  selected: number;
};

type PaginatorProps = {
  pageCount: number;
  onPageChange: (selected: PageChangeType) => void;
  pageNo: number;
  pageSize: string;
  onSizeChange: (size: string) => void;
};

const Paginator = (props: PaginatorProps) => {
  const { pageCount, onPageChange, pageNo, pageSize, onSizeChange } = props;
  return (
    <Paginator.Wrapper>
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        previousClassName="pagination-prev-btn"
        pageCount={pageCount}
        onPageChange={onPageChange}
        forcePage={pageNo}
        containerClassName={'pagination-container'}
        disabledClassName={'paginationDisabled'}
        activeClassName={'paginationActive'}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
      />
      <div className="ml-4">
        <select
          className="select"
          id="paginator"
          onChange={(value) => onSizeChange(value.target.value)}
          value={pageSize}
        >
          <option value="10">10 / page</option>
          <option value="20">20 / page</option>
          <option value="50">50 / page</option>
          <option value="100">100 / page</option>
          <option value="250">250 / page</option>
          <option value="500">500 / page</option>
          <option value="1000">1000 / page</option>
        </select>
      </div>
    </Paginator.Wrapper>
  );
};
Paginator.Wrapper = styled.div`
  margin-top: 1rem;
  align-items: center;
  float: right;
  display: flex;
  align-items: center;
  .select {
    background-color: #fff;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  /* -----Pagination styles */
  .pagination-container {
    margin-top: 1rem;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: flex-end;
  }
  .pagination-container a {
    margin: 0 2px 8px;
    border-radius: 6px;
    background: #fff;
    cursor: pointer;
    border: 1px solid #cccccc;
    font-size: 14px;
    padding: 10px 14px;
    height: 24px;
    color: black;
  }
  .pagination-container a:hover {
    color: white;
    background-color: #ffcc00;
  }
  .paginationActive a {
    color: white;
    background-color: #ffcc00;
  }
  .paginationDisabled a {
    opacity: 0.3;
    cursor: not-allowed;
    pointer-events: none;
    background-color: transparent;
  }
`;
export default Paginator;
