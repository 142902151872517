import { FC } from 'react';
import { Avatar, Box, Divider, Flex, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Moment from 'react-moment';

import { RenderLoader } from 'components/Loaders/RenderLoader';
import { RenderEmptyData } from 'components/EmptyData/EmptyData';
import { StatusDisplay } from 'components/StatusDisplay/StatusDisplay';

import ChampionHelmetIcon from 'assets/images/vehicle-activity/champion-helmet.svg';

type PastContractTypes = {
  pastContracts: any;
  isLoading: boolean;
};

export const PastContracts: FC<PastContractTypes> = ({ pastContracts, isLoading }) => {
  const pastContractsData = pastContracts?.past_contracts;
  const singleDigitIncrement = 1;

  function createContractNumber(number: number, totalLength: number): string {
    const incrementValue = number + singleDigitIncrement;
    return String(incrementValue).padStart(totalLength, '0');
  }

  return (
    <>
      {isLoading ? (
        <RenderLoader />
      ) : pastContractsData?.length === 0 ? (
        <RenderEmptyData description="No past contract was found." />
      ) : (
        pastContractsData?.map((contract, idx = 0) => (
          <Box direction="column" key={nanoid()} bg="#F7F7F7" borderRadius="10px" px={10} py={7} mb={4}>
            <Box py={3}>
              <Text color="#3E3E3E" fontSize="1.3rem" fontWeight={700} pb={2}>
                Contract {createContractNumber(idx, 2)}
              </Text>
              <Text color="rgba(62, 62, 62, 1)" fontSize="sm" fontWeight={600}>
                <Text as="span" color="rgba(62, 62, 62, 0.5)" fontSize="sm" pr={1}>
                  {' '}
                  Duration
                </Text>{' '}
                {contract?.activated_at ? <Moment format="D MMM YYYY">{contract?.activated_at}</Moment> : 'N/A'} -{' '}
                {contract?.deactivated_at ? <Moment format="D MMM YYYY">{contract?.deactivated_at}</Moment> : 'N/A'}
              </Text>
            </Box>
            <Box py={3}>
              <Divider />
            </Box>
            <Flex py={3} gap={10} justify="space-between">
              <Flex gap={4}>
                <Avatar src={ChampionHelmetIcon} bg="#fae8d0" />
                <Box direction="column">
                  <Text color="rgba(62, 62, 62, 1)" fontSize="1rem" fontWeight={600}>
                    {contract?.champion_name ?? 'N/A'}
                  </Text>
                  <Text color="#E88E15" fontSize="0.875rem" fontWeight={600}>
                    {contract?.max_champion_id ?? 'N/A'}
                  </Text>
                </Box>
                <Box justifyContent="center">
                  <StatusDisplay borderRadius="none" status={contract?.champion_status ?? 'N/A'} fontSize="12px" />
                </Box>
              </Flex>
              <Box>
                <Text color="rgba(62, 62, 62, 0.5)" fontSize="0.875rem">
                  Daily Remittance (&#x20A6;)
                </Text>
                <Text color="rgba(62, 62, 62, 1)" fontSize="1rem" fontWeight={600}>
                  {Number(contract?.daily_remit).toLocaleString() ?? 'N/A'}
                </Text>
              </Box>
              <Box>
                <Text color="rgba(62, 62, 62, 0.5)" fontSize="0.875rem">
                  HP Duration (Months)
                </Text>
                <Text color="rgba(62, 62, 62, 1)" fontSize="1rem" fontWeight={600}>
                  {Number(contract?.duration).toLocaleString() ?? 'N/A'}
                </Text>
              </Box>
              <Box>
                <Text color="rgba(62, 62, 62, 0.5)" fontSize="0.875rem">
                  Total HP Amount (&#8358;)
                </Text>
                <Text color="rgba(62, 62, 62, 1)" fontSize="1rem" fontWeight={600}>
                  {Number(contract?.hp_value).toLocaleString() ?? 'N/A'}
                </Text>
              </Box>
            </Flex>
          </Box>
        ))
      )}
    </>
  );
};
