/* eslint-disable import/no-anonymous-default-export */
export const LS_PREFIX = '__maxadmn';
export const KEY_LS_WS_TOKEN = `${LS_PREFIX}wstkn__`;
export const KEY_LS_MAX_TOKEN = `${LS_PREFIX}tkn__`;
export const KEY_LS_MAX_USER = `${LS_PREFIX}usr__`;
export const KEY_LS_MAX_USER_VAMS = `${LS_PREFIX}role__`;
export const KEY_LS_REQUESTED_ADDR = 'requested_addr';
export const KEY_SIZE = 'vehicle_size';
export const ZONE_DEFAULT = 'default';

export const PAGE_SIZE_OPTIONS = [
  {
    label: '100',
    value: '100'
  },
  {
    label: '250',
    value: '250'
  },
  {
    label: '500',
    value: '500'
  },
  {
    label: '750',
    value: '750'
  },
  {
    label: '1000',
    value: '1000'
  }
];

export const maintenance_reasons = ['scheduled maintenance', 'unscheduled maintenance'];

export const FILTER_CONSTANT = {
  BATCH: 'batch',
  LOCATION: 'location',
  SEARCH: 'search',
  COLUMN: 'column',
  VEHICLE_TYPE: 'vtype'
};

export const QUERY_KEYS = {
  ALL_ASSET: 'get-asset-list',
  ALL_CITIES: 'get-all-cities',
  ALL_JOB_CARDS: 'get-all-job-cards',
  ALL_MAINTENANCE_VEHICLES: 'get-all-maintenance-vehicles',
  ALL_MANUFACTURERS: 'getAllManufactureres',
  ALL_PLATFORM: 'get-all-platforms-1',
  ALL_ROLES: 'get-roles',
  ALL_SUB_CITIES: 'get-all-sub-cities',
  ALL_SUB_REASON: 'get-all-sub-reasons',
  ALL_VEHICLE_TYPE: 'asset-type-or-vehicle-type',
  DUE_FOR_MAINTENANCE: 'due-for-maintenance',
  DUE_FOR_MAINTENANCE_COMMENTS: 'due-for-maintenance-comments',
  DUE_FOR_MAINTENANCE_COUNTS: 'due-for-maintenance-counts',
  DUE_FOR_MAINTENANCE_COUNTS_MAINTENANCE_INDEX: 'due-for-maintenance-counts-maintenance',
  GET_ALL_USERS: 'all-users-usermanagement',
  GET_CHAMPION_AMORTIZATION_SCHEDULE: 'get-champion-amortization-schedule',
  GET_CHAMPION_CONTRACT: 'get-champion-contract-details',
  GET_CHAMPION_CONTRACT_TRANCHE_DETAILS: `get-champion-contract-tranche-details`,
  GET_CURRENT_CHAMPION: 'get-current-champion',
  GET_JOB_CARD_BY_ID: 'get-job-card-by-id',
  GET_MAINTENANCE_VEHICLE_BY_ID: 'get-maintenance-vehicle-by-id',
  GET_TESTER_REPORT: 'technicians_history',
  GET_VEHICLE_ACTIVITY_CHAMPION_DETAILS: 'get-vehicle-activity-champion-details',
  GET_VEHICLE_ACTIVITY_ID: 'get-vehicle-activity-id',
  GET_VEHICLE_BY_ID: 'get-vehicle-by-id',
  GET_USER_ROLES: 'get-user-roles',
  GET_VEHICLE_PAST_CONTRACTS: 'get-vehicle-past-contracts',
  LOCATIONS: 'locations',
  MAINTENANCE: 'maintenance',
  MAINTENANCE_SCHEDULE_HISTORY: 'maintenance-schedule-history',
  MAINTENANCE_REPORT_HISTORY: 'maintenance-report-history',
  MAINTENANCE_REPORT_HISTORY_BY_ID: 'maintenance-report-history-by-id',
  PAUSE_REASONS: 'pause-reasons',
  PENDING_REASONS: 'pending-reasons',
  QA_TESTER: 'qa_tester',
  TECHNICIANS: 'technicians',
  TECHNICIANS_HISTORY: 'technicians_history',
  TESTERS_HISTORY: 'testers_history',
  UPDATE_JOB_CARD_STATUS: 'update-job-card-status'
};
