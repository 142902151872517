import { Link } from 'react-router-dom';
import style from './style.module.scss';
import classNames from 'classnames';

export enum ButtonStates {
  primary = 'PRIMARY',
  secondary = 'SECONDARY',
  warning = 'WARNING',
  danger = 'DANGER',
  unstyled = 'UNSTYLED',
  unstyledWarning = 'UNSTYLED_WARNING',
  unstyledDanger = 'UNSTYLED_DANGER',
}

export enum ButtonType {
  link,
  button,
}

export enum ButtonSize {
  sm,
  md,
}

export default function CustomButton({
  disabled = false,
  inverted = false,
  state,
  type = ButtonType.button,
  url = '',
  customStyle,
  children,
  handleClick,
  size,
  buttonType = "button"
}: CustomButtonProps) {
  
  var btnClass = classNames([`${style.button_style}`], customStyle, { 
    [`${style.button_sm}`]: size === ButtonSize.sm,
    [`${style.button_md}`]: size === ButtonSize.md,
    [`${style.primary_inverted}`]: inverted && state === ButtonStates.primary,
    [`${style.primary}`]: !inverted && state === ButtonStates.primary,
    [`${style.warning_inverted}`]: inverted && state === ButtonStates.warning,
    [`${style.warning}`]: !inverted && state === ButtonStates.warning,
    [`${style.danger}`]: !inverted && state === ButtonStates.danger,
    [`${style.unstyled}`]: !inverted && state === ButtonStates.unstyled,
    [`${style.unstyledDanger}`]:
      !inverted && state === ButtonStates.unstyledDanger,
    [`${style.unstyled_warning}`]:
      !inverted && state === ButtonStates.unstyledWarning,
  });

  if (type === ButtonType.button)
    return (
      <button disabled={disabled} onClick={handleClick} className={btnClass} type={buttonType}>
        {children}
      </button>
    );
  return (
    <Link aria-disabled={disabled} className={btnClass} to={url}>
      {children}
    </Link>
  );
}

type CustomButtonProps = {
  children: React.ReactNode | string | JSX.Element;
  inverted?: boolean;
  state?: ButtonStates;
  type?: ButtonType;
  url?: string;
  customStyle?: string;
  handleClick?: () => void;
  size?: ButtonSize;
  disabled?: boolean;
  buttonType?: "button" | "submit" | "reset" | undefined;
};
