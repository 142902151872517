import React from 'react';
import { FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';
import classNames from 'classnames';
import style from './style.module.scss';

export enum CustomInputSize {
  sm,
  md
}

const CustomInput = React.forwardRef(
  (
    {
      label,
      name,
      placeholder,
      errors,
      size,
      customStyle,
      customInputStyle,
      register,
      type = 'text',
      value,
      pattern,
      ...props
    }: InputProps,
    ref
  ) => {
    var inputClass = classNames([`${style.input}`], customInputStyle, {
      [`${style.sm}`]: size === CustomInputSize.sm,
      [`${style.md}`]: size === CustomInputSize.md
    });

    var labelClass = classNames([`${style.label}`]);

    const numberInputOnWheelPreventChange = (e: any) => {
      // Prevent the input value change
      e.target.blur();

      // Prevent the page/container scrolling
      e.stopPropagation();

      // Refocus immediately, on the next tick (after the current function is done)
      setTimeout(() => {
        e.target.focus();
      }, 0);
    };

    return (
      <div className={customStyle}>
        <FormLabel className={labelClass} htmlFor={name}>
          {label}
        </FormLabel>
        {value ? (
          <Input ref={ref} type={type} id={name} className={inputClass} value={value} {...props} />
        ) : (
          <Input
            onWheel={numberInputOnWheelPreventChange}
            ref={ref}
            type={type}
            id={name}
            className={inputClass}
            placeholder={placeholder}
            pattern={pattern}
            {...props}
          />
        )}

        <FormErrorMessage as="div">
          <span className="comm-r-12">{errors && errors}</span>
        </FormErrorMessage>
      </div>
    );
  }
);

type InputProps = {
  label?: string;
  name?: string;
  placeholder?: string;
  errors?: any;
  size?: CustomInputSize;
  customStyle?: string;
  customInputStyle?: string;
  ref?: any;
  type?: string;
  register?: any;
  value?: string | number;
  pattern?: string;
  editedPhone?: string;
  onChange?: any;
  onBlur?: any;
};

export default CustomInput;
