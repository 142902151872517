import { Button } from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';
import React from 'react';
import styled from 'styled-components';

import { useAppContext } from 'global/AppContext';
const ClearFilter = () => {
  const { setFiltersToNull, location_id, city, manufacturer, platform, vehicleType, startDate, endDate }: any =
    useAppContext();

  return (
    <ClearFilter.Wrapper>
      <Button
        variant={'secondary'}
        onClick={setFiltersToNull}
        hidden={!location_id && !city && !manufacturer && !platform && !vehicleType && !startDate && !endDate}
      >
        Clear Filters
        <FaTimes style={{ marginLeft: '5px' }} />
      </Button>
    </ClearFilter.Wrapper>
  );
};

ClearFilter.Wrapper = styled.div`
  font-size: 13px;
  .select-item {
    width: 150px;
  }
  .full-width {
    width: 100%;
  }
`;
export default ClearFilter;
