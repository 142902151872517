import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

import { CurrentChampion } from 'components/VehicleActivity/VehicleDetails/VehicleChampionDetails/CurrentChampion';
import { PreviousChampions } from 'components/VehicleActivity/VehicleDetails/VehicleChampionDetails/PreviousChampions';
import { PastContracts } from 'components/VehicleActivity/VehicleDetails/VehicleChampionDetails/PastContracts';

type VehicleChampionDetailsProps = {
  vehicleData: any;
  currentChampion: any;
  contractData: any;
  isError?: boolean;
  isLoading: boolean;
  trancheData: any;
};

const tabHeaders = ['CURRENT CHAMPION', 'PREVIOUS CHAMPIONS', 'PAST CONTRACTS'];

export const VehicleChampionDetails: FC<VehicleChampionDetailsProps> = ({
  vehicleData,
  currentChampion,
  contractData,
  isError,
  isLoading,
  trancheData
}) => {
  const location = useLocation();
  const tabNumber = Number(location.hash.charAt(1));

  return (
    <>
      <Tabs isFitted colorScheme="yellow" defaultIndex={tabNumber}>
        <TabList>
          {tabHeaders.map((header) => (
            <Tab
              fontSize="0.875rem"
              color="rgba(62, 62, 62, 0.3)"
              fontWeight={600}
              _selected={{
                color: 'rgba(62, 62, 62, 1)',
                bg: '#fff',
                borderBottom: '5px solid',
                borderBottomColor: 'rgba(252, 221, 24, 1)',
                fontWeight: 700
              }}
              key={nanoid()}
            >
              {header}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel overflowY="scroll" maxHeight="90vh">
            <CurrentChampion
              contractData={contractData}
              currentChampion={currentChampion}
              isLoading={isLoading}
              trancheData={trancheData}
            />
          </TabPanel>
          <TabPanel overflowY="scroll" maxHeight="90vh">
            <PreviousChampions pastChampionData={vehicleData} isLoading={isLoading} />
          </TabPanel>
          <TabPanel>
            <PastContracts pastContracts={vehicleData} isLoading={isLoading} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
