import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Box, Divider, Flex, Square, Text } from '@chakra-ui/react';

import ChampionHelmetIcon from 'assets/images/vehicle-activity/champion-helmet.svg';

type AssignedChampionBlockProps = {
  championName: string;
  title: string;
  vehicleID: string;
};

export const AssignedChampionBlock: FC<AssignedChampionBlockProps> = ({
  championName,
  title,
  vehicleID
}) => {
  return (
    <>
      <Text fontSize="0.75rem" color="#808080" px={4} py={2}>
        {title}
      </Text>
      <Divider />
      {!championName ? (
        <Box p={4}>
          <Text color="rgba(62, 62, 62, 1)" fontSize="0.875rem" fontWeight={600}>
            No champion assigned
          </Text>
        </Box>
      ) : (
        <Flex px={5} py={4} align="center" gap={2}>
          <Avatar src={ChampionHelmetIcon} bg="#fae8d0" alt="" size="sm" />
          <Text color="rgba(62, 62, 62, 1)" fontSize="0.875rem" fontWeight={600}>
            {championName ?? 'N/A'}
          </Text>
        </Flex>
      )}
      <Divider />
      <Square>
        <Link to={`/vehicle-activity/vehicle/detail/${vehicleID}#1`}>
          <Text
            as="u"
            color="rgba(232, 142, 21, 1)"
            fontSize="0.75rem"
            textAlign="center"
            py={2}
            fontWeight={600}
            _hover={{ cursor: 'pointer' }}
          >
            SEE PREVIOUSLY ASSIGNED CHAMPIONS
          </Text>
        </Link>
      </Square>
    </>
  );
};
