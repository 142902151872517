import { FC } from 'react';
import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import Moment from 'react-moment';


import { useMaintenanceContext } from 'pages/maintenance/context';
import { StatusDisplay } from 'components/StatusDisplay/StatusDisplay';

export const JobCardSection: FC = () => {
  const { jobCardDetails, vehicleData } = useMaintenanceContext();

  const completedJobCardLogEndDate =
    jobCardDetails[0]?.job_card_status_logs && new Date(jobCardDetails[0]?.job_card_status_logs[0]?.created_at);



  const completedJobCardLogStartDate =
    jobCardDetails[0]?.job_card_status_logs &&
    new Date(jobCardDetails[0]?.job_card_status_logs[jobCardDetails[0]?.job_card_status_logs?.length - 1]?.created_at);

  return (
    <>
      {!vehicleData ? (
        <Box bgColor="#fff" border="1px" borderColor="rgba(238, 238, 238, 1)" borderRadius="10px">
          <Box px={4} py={2}>
            <Text color="rgba(128, 128, 128, 1)" fontSize="0.75rem">
              Job Card
            </Text>
          </Box>
          <Divider />
          <Text color="hsla(0, 0%, 24%, 1)" px={6} py={4}>
            Not Started
          </Text>
        </Box>
      ) : (
        <Box bgColor="#fff" border="1px" borderColor="rgba(238, 238, 238, 1)" borderRadius="10px">
          <Flex alignItems="center" justifyContent="space-between" px={4} py={2}>
            <Text color="rgba(128, 128, 128, 1)" fontSize="0.75rem">
              Job Card Status
            </Text>
            <Flex
              alignItems="center"
              bgColor="#EEEEEE"
              borderRadius="3px"
              _hover={{ cursor: 'pointer' }}
              justifyContent="center"
              px={0}
              py={1}
            >
              <ArrowForwardIcon color="#8D99AE" w={10} />
            </Flex>
          </Flex>
          <Divider />
          <Flex alignItems="center" justifyContent="space-between" px={5} py={3}>
            <StatusDisplay borderRadius="none" status={jobCardDetails[0]?.status} fontSize="12px" />
            {jobCardDetails[0]?.status && (
              <Box>
                {
             
              
                (() => {
                  if ( jobCardDetails[0]?.status === 'completed' ) {
                    return (
                      <>
                        <Moment duration={new Date(completedJobCardLogStartDate)} format='Y[ Year],M[ Month],D[ Day],H[ Hour],m[ Min]' date={new Date(completedJobCardLogEndDate)} />
                      </>
                    );
                  } else if ((jobCardDetails[0]?.job_card_status_logs).length<1&&jobCardDetails[0]?.status !== 'completed') {
                    return (
                      <>
                             <Moment duration={jobCardDetails[0]?.maintenance_start_time}  format='Y[ Year],M[ Month],D[ Day],H[ Hour],m[ Min]' >{new Date()}</Moment>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <Moment duration={jobCardDetails[0]?.job_card_status_logs[jobCardDetails[0]?.job_card_status_logs.length - 1]?.created_at}  format='Y[ Year],M[ Month],D[ Day],H[ Hour],m[ Min]' date={jobCardDetails[0]?.job_card_status_logs[jobCardDetails[0]?.job_card_status_logs.length - 2]?.created_at} />
                      </>
                    );
                  }
                })()
              }
               
            
           
              </Box>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};
