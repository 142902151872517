import { BadgeState } from 'components/badge/Badge';
import TricycleIcon from 'assets/images/tricycle.svg';
import MotorCycleIcon from 'assets/images/tricycle.svg';
import CarIcon from 'assets/images/car.svg';
import { format } from 'date-fns';

export function assignBadgeStyle(caseText: string) {
  caseText = caseText.toLowerCase();
  if (caseText === 'due for retrieval' || caseText === 'churned') return BadgeState.danger;
  if (caseText === 'good standing' || caseText === 'completed') return BadgeState.success;
  if (caseText === 'retrieved' || caseText === 'paused') return BadgeState.primary;
  if (caseText === 'prepaid') return BadgeState.prepaid;
  if (caseText === 'closed') return BadgeState.closed;
  if (caseText === 'active') return BadgeState.active;
  if (caseText === 'overdue' || caseText === 'marked for closure') return BadgeState.warning;
  if (caseText === 'max trf') return BadgeState.maxtrf;
  if (caseText === 'void') return BadgeState.void;
  if (caseText === 'refunded') return BadgeState.refunded;

  return BadgeState.primary;
}

export function incidentBadgeStyle(caseText: string) {
  caseText = caseText?.toLowerCase();
  if (caseText === 'unresolved') return BadgeState.danger;
  if (caseText === 'pending') return BadgeState.prepaid;
  if (caseText === 'resolved') return BadgeState.success;
  if (caseText === 'closed') return BadgeState.success;
  if (caseText === 'escalated') return BadgeState.danger;
  if (caseText === 'open') return BadgeState.active;
  if (caseText === 'in-progress') return BadgeState.prepaid;

  return BadgeState.primary;
}

export function getWheelerIcon(vehicleTypeSlug: string | undefined) {
  if (vehicleTypeSlug === 'tricycle' || vehicleTypeSlug === 'etricycle')
    return <img src={TricycleIcon} alt="tricycle" />;

  if (vehicleTypeSlug === 'motorcycle' || vehicleTypeSlug === 'emotorcycle')
    return <img src={MotorCycleIcon} alt="motorcycle" />;

  return <img src={CarIcon} alt="car" />;
}

export function getWheelerCount(vehicleTypeSlug: string | undefined) {
  if (vehicleTypeSlug === 'tricycle' || vehicleTypeSlug === 'etricycle') return '3 Wheeler';

  if (vehicleTypeSlug === 'motorcycle' || vehicleTypeSlug === 'emotorcycle') return '2 Wheeler';

  return '4 Wheeler';
}

export function objectIsEmpty(obj: {}) {
  return Object.keys(obj).length === 0;
}

export enum NullCheckKeys {
  txt,
  amount,
  date,
  num
}

export function nullCheck(data: number | string | null | undefined, key: NullCheckKeys) {
  if (!data && key === NullCheckKeys.amount) {
    return '0.00';
  } else if (data && key === NullCheckKeys.amount) {
    return Number(data).toLocaleString(undefined, {
      minimumFractionDigits: 2
    });
  } else if (!data && key === NullCheckKeys.num) {
    return '0';
  } else if (data && key === NullCheckKeys.num) {
    return data.toLocaleString();
  } else if (!data && key === NullCheckKeys.txt) {
    return 'Not available';
  } else if (data && key === NullCheckKeys.txt) {
    return data;
  } else if (!data && key === NullCheckKeys.date) {
    return 'Invalid date';
  } else if (data && key === NullCheckKeys.date) {
    return format(new Date(data), 'dd/MMM/yyyy');
  }
}

export function truncateText(text: string, length: number) {
  if (!text) {
    return 'Not available';
  }
}

export const completeContractFootNote = {
  totalPaidAmountEqualHPValue:
    ' Total paid amount is equal to Total HP value of the contract, do you want to HP complete the contract?',
  totalPaidAmountGreaterThanHPValue:
    ' Total paid amount is greater than total HP value of the contract, do you want to HP complete the contract?',
  totalPaidAmountLessThanHPValueFirstPart: ' Total paid amount is less then HP value so an adjustment of ',
  totalPaidAmountLessThanHPValueLastPart: ' will be made in the contract. Do you agree to HP complete the contract?'
};

export function isNumberPresent(str: string) {
  const regex = /\d+/;
  if (regex.test(str)) {
    return true;
  }
  return false;
}

export function checkStringLength(str: string, len: number) {
  if (str && str.length >= len) {
    return true;
  }
  return false;
}

export function checkForUpperCase(str: string) {
  const regex = /[A-Z]/;
  if (regex.test(str)) {
    return true;
  }
  return false;
}

export function checkForSpecialCharacter(str: string) {
  const regex = /[@$%*&^!]/;
  if (regex.test(str)) {
    return true;
  }
  return false;
}

export const isVehicleAssigned = (champId: string | null | undefined) => {
  if (champId) {
    return 'assigned';
  }
  return 'unassigned';
};

export const ucFirst = (str: string | null | undefined) => {
  // Split the string into an array of words
  const words = str?.toLowerCase().split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words?.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the capitalized words back into a string
  const capitalizedString = capitalizedWords?.join(' ');

  return capitalizedString;
};

export const removeUnderScores = (inputString: string | null | undefined) => {
  // Split the string by hyphens and capitalize each word
  const words = inputString?.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words with spaces
  const formattedString = words?.join(' ');

  return formattedString;
};

// checks the different in days from the current date and date of choice
export const calculateDateDifference = (dateString: string): string => {
  const currentDate: Date = new Date();
  const targetDate: Date = new Date(dateString);
  const differenceMs: number = currentDate.getTime() - targetDate.getTime();

  // Convert difference in milliseconds to days
  const differenceDays: number = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  // Format the result
  let formattedDifference: string = '';
  if (differenceDays === 1) {
    formattedDifference = '1 day';
  } else {
    formattedDifference = `${differenceDays} days`;
  }

  return formattedDifference;
};
