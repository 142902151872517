// import axios from "axios";
import { KEY_LS_MAX_TOKEN } from '../util/constants';
import { config } from "../config/app-config";
import axios, { AxiosInstance, AxiosRequestConfig, } from 'axios';
export const baseURL = config.maxApiBaseUrl as string;

export * from "./request";

const cancelTokenSource = axios.CancelToken.source();
const axiosInstance: AxiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
    'Service-Agent': 'VAMS-USER',
    "req-origin": "VAMS"
  },
  timeout: 300000,
  cancelToken: cancelTokenSource.token

});

//  This adds a token before all the requests.
// https://stackoverflow.com/questions/57251719/acquiring-a-new-token-with-axios-interceptors
const addTokenToRequest = (request: AxiosRequestConfig) => {
  request.headers.Authorization = `Bearer ${localStorage.getItem(KEY_LS_MAX_TOKEN)}`;
  return request;
};

axiosInstance.interceptors.request.use(addTokenToRequest);

export default axiosInstance;



