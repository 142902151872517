import CustomButton, { ButtonStates } from '../buttons/Button';

export default function CardTitle({
  title,
  handleClick,
  btnState = ButtonStates.unstyledWarning,
  btnText
}: CardTitleProps) {
  return (
    <div className="flex justify-between w-full px-7 pt-6 pb-4 border-b border-grey01">
      <span className="comm-m-14 whitespace-nowrap text-black-50">{title}</span>
      <div>
        <CustomButton handleClick={handleClick} state={btnState}>
          {btnText}
        </CustomButton>
      </div>
    </div>
  );
}

type CardTitleProps = {
  title: string;
  handleClick?: () => void;
  btnState?: ButtonStates;
  btnText?: string | JSX.Element;
};
