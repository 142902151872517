import { FC } from 'react';

import style from './style.module.scss';

import { ReactComponent as CarIcon } from 'assets/images/vehicle-activity/car-icon.svg';
import { ReactComponent as MotorcycleIcon } from 'assets/images/vehicle-activity/motorcycle-icon.svg';
import { ReactComponent as TricycleIcon } from 'assets/images/vehicle-activity/tricycle-icon.svg';

type VehicleDetailProps = {
  vehicle_type_id?: string;
  first_name?: string;
  last_name?: string;
  max_vehicle_id?: string;
};

const VehicleDetail: FC<VehicleDetailProps> = ({
  vehicle_type_id,
  first_name,
  last_name,
  max_vehicle_id
}) => {
  const getVehicleIcon = () => {
    switch (vehicle_type_id && vehicle_type_id[0]) {
      case '2':
        return <MotorcycleIcon />;
      case '3':
        return <TricycleIcon />;
      case '4':
        return <CarIcon />;
      default:
        return <MotorcycleIcon />;
    }
  };

  return (
    <div className={style.wrapper}>
      <>{getVehicleIcon()}</>
      <div className={style.detail_wrapper}>
        <span className={style.full_name}>
          {first_name} {last_name}
        </span>
        <span className={style.max_id}>{max_vehicle_id}</span>
      </div>
    </div>
  );
};

export default VehicleDetail;
