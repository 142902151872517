import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { FaFileDownload } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { motion } from 'framer-motion';

import { downloadCsv } from 'util/downloadAsCsv';
import {
  getActiveVehicles,
  getAllCities,
  getFilteredActiveVehicles,
  getFireBaseActiveVehicles,
  getLocations
} from '../reportApi';

import FilterContainer from 'components/filter-container/filter-container.component';

import { ActionBar } from 'components/ActionBar/ActionBar';
import Paginator, { PageChangeType } from 'components/Paginator';
import PageWrapper from 'components/page-wrapper';
import { Button, Input } from '@chakra-ui/react';
import './active-vehicles.styles.scss';
import DatePicker from 'react-datepicker';
import { vehicleTypes } from 'constants/vehicleTypes';
import BasicTable from 'components/BasicTable';
import CustomSelect from 'components/custom-select';
import { firebaseDb } from 'config/firebase';
import { onSnapshot, limit, collection, query } from 'firebase/firestore';
import { useAppContext } from 'global/AppContext';
import ClearFilter from 'components/clear-filter';

export const ActiveVehicleReport: React.FC = () => {
  const {
    setVehicleType,
    vehicleType,
    location_id,
    setLocationId,
    platform,
    setStartDate,
    setEndDate,
    manufacturer,
    city,
    setCity,
    startDate,
    endDate
  }: any = useAppContext();
  const [pageNo, setPageNo] = useState(0);
  const [pages, setPages] = useState(1);
  const [pageSize, setpageSize] = useState('20');

  const [cities, setCities] = useState<any>([]);
  const [subCities, setSubcities] = useState([]);
  const [subCityFilters, setSubCityFilters] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedStartDate, selectedEndDate] = dateRange;
  const [activeVehicles, setActiveVehicles] = useState([]);
  const [activeVehiclesCopy, setActiveVehicleCopy] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const allActiveVehiclesRef = collection(firebaseDb, 'active_vehicle');
  const activeVehQuery = query(allActiveVehiclesRef, limit(Number(pageSize)));

  useEffect(() => {
    const getFilteredActive = async () => {
      try {
        setIsLoading(true);
        const res = await getFilteredActiveVehicles(
          location_id,
          vehicleType,
          startDate,
          endDate,
          pages,
          pageSize,
          city,
          platform,
          manufacturer
        );
        setActiveVehicles(res.results);
        setIsLoading(false);
      } catch (error: any) {
        console.log(error.message);
      }
      setIsLoading(false);
    };
    if (startDate || endDate || city || location_id || vehicleType || pages !== 1 || platform || manufacturer) {
      getFilteredActive();
    } else {
      setActiveVehicles(activeVehiclesCopy);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, endDate, pageSize, pages, startDate, location_id, vehicleType, platform, manufacturer]);

  useEffect(() => {
    const cancelActiveSub = onSnapshot(activeVehQuery, (snapshot) => {
      setIsLoading(true);
      let activeVeh: any = [];
      snapshot.docs.forEach((doc) => {
        activeVeh.push({ ...doc.data(), id: doc.id });
      });
      setActiveVehicles(activeVeh);
      setActiveVehicleCopy(activeVeh);
      setIsLoading(false);
    });
    getAllCities()
      .then((res) =>
        setCities(
          res?.payload?.message?.objects?.map((el: any) => ({
            label: el.name,
            value: el.id
          }))
        )
      )
      .catch((err) => console.log(err.message));

    getLocations()
      .then((res) => {
        setSubcities(
          res?.payload?.message?.objects?.map((el: any) => ({
            label: el.name,
            value: el.id,
            city_id: el.city_id
          }))
        );
        setSubCityFilters(
          res?.payload?.message?.objects?.map((el: any) => ({
            label: el.name,
            value: el.id,
            city_id: el.city_id
          }))
        );
      })
      .catch((err) => console.log(err.message));

    return () => {
      cancelActiveSub();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const locations = useQuery('new_locations', getLocations);

  const allFirebaseActiveVehicles = useQuery(
    [
      'all-firebase-active-vehicles',
      startDate,
      endDate,
      location_id,
      vehicleType,
      pages,
      pageSize,
      platform,
      manufacturer,
      city
    ],
    getFireBaseActiveVehicles
  );

  const allConfigCities: any = useQuery('allcities-on-vams', getAllCities);
  const history = useNavigate();

  const handlePageReturn = (): void => {
    history(-1);
  };

  const onVehicleChange = (e: any) => {
    if (e) {
      setVehicleType(e.value);
    } else {
      setVehicleType('');
    }
  };

  const onPageChange = (page: PageChangeType) => {
    setPageNo(page.selected);
    setPages(page.selected + 1);
  };

  const onPageSizeChange = (e: any) => {
    setpageSize(e);
  };

  const columns = [
    {
      Header: 'Time Stamp',
      accessor: 'updated_at',
      Cell: ({ value }: any) => (value ? format(new Date(value), 'hh:mma   dd-MM-yyyy') : 'N/A')
    },
    {
      Header: 'Asset Type',
      accessor: 'vehicle_type'
    },
    {
      Header: 'Vehicle ID',
      accessor: 'max_vehicle_id'
    },
    {
      Header: 'Ignition No.',
      accessor: 'engine_number',
      Cell: ({ value }: any) => value || 'N/A'
    },
    {
      Header: 'Location',
      accessor: 'city'
    },
    {
      Header: 'Odometer',
      accessor: 'odometer',
      Cell: ({ value }: any) => value || 'N/A'
    }
  ];

  const allPagesActiveVehicles: any = useQuery(
    ['all-pages-active', '', '', '', '', '', allFirebaseActiveVehicles.data?.data?.pagination?.total, '', '', ''],
    getActiveVehicles
  );

  const onDateRangeChanged = (selectedDates) => {
    const [startDate, endDate] = selectedDates;
    const DATE_FORMAT = 'yyyy-MM-dd';
    if (startDate && endDate) {
      const startDateValue = format(new Date(startDate), DATE_FORMAT);
      const endDateValue = format(new Date(endDate), DATE_FORMAT);
      setStartDate(startDateValue);
      setEndDate(endDateValue);
    } else {
      setEndDate('');
      setStartDate('');
    }
  };

  const onLocationChange = (e: any) => {
    if (e) {
      setLocationId(e.value);
    } else {
      setLocationId('');
    }
  };

  const onCityChange = (option: any) => {
    if (option) {
      setLocationId('');
      setCity(option.value);
      const filteredSubCities = subCities.filter((loc: any) => loc.city_id === option.value);
      setSubCityFilters(filteredSubCities);
    } else {
      setCity('');
      setSubCityFilters(subCities);
    }
  };
  return (
    <>
      <FilterContainer>
        <ClearFilter />
        <DatePicker
          selectsRange={true}
          startDate={startDate ? new Date(startDate) : selectedStartDate}
          endDate={endDate ? new Date(endDate) : selectedEndDate}
          onChange={(update: any) => {
            setDateRange(update);
            onDateRangeChanged(update);
          }}
          isClearable={true}
          maxDate={new Date()}
          locale="en"
          placeholderText="FROM - TO"
          customInput={<Input fontSize="13px" w="180px" />}
        />
        <CustomSelect
          options={vehicleTypes}
          name="Report-by-vehicletype"
          placeholder="Vehicle"
          onChange={onVehicleChange}
          isLoading={locations.isLoading}
          value={vehicleTypes?.filter((veh) => veh.value === vehicleType)}
        />

        <CustomSelect
          options={cities}
          name="Report-by-location"
          placeholder="Cities"
          onChange={onCityChange}
          isLoading={allConfigCities.isLoading}
          value={cities?.filter((loc) => loc.value === city)}
        />

        <CustomSelect
          options={subCityFilters}
          name="Report-by-location"
          placeholder="Subcities"
          onChange={onLocationChange}
          value={subCities?.filter((loc: any) => loc.value === location_id)}
        />
      </FilterContainer>

      <PageWrapper>
        <ActionBar>
          <div className="action-bar__page-title__wrapper">
            <ArrowLeftIcon width={35} onClick={handlePageReturn} className="action-bar__page-title__title-icon" />
            <p className="action-bar__page-title__title">Active Vehicles</p>
          </div>

          <div className="action-bar__btn-group">
            <Button
              variant="secondaryOutline"
              className="export-vehicle"
              hidden={allPagesActiveVehicles?.isLoading}
              onClick={(e) => downloadCsv(allPagesActiveVehicles?.data?.data?.results, 'Active Vehicles')}
            >
              <FaFileDownload className="mr-4" style={{ fontSize: '18px' }} />
              Export
            </Button>
          </div>
        </ActionBar>

        <motion.div className="table-holder m-5" initial={{ x: -30 }} animate={{ x: 0 }}>
          <BasicTable
            data={activeVehicles}
            columns={columns}
            loading={isLoading || allFirebaseActiveVehicles.isLoading}
          />
        </motion.div>
        {!isLoading && (
          <Paginator
            pageCount={allFirebaseActiveVehicles?.data?.data?.pagination?.page_count}
            onPageChange={onPageChange}
            pageNo={pageNo}
            pageSize={pageSize}
            onSizeChange={onPageSizeChange}
          />
        )}
      </PageWrapper>
    </>
  );
};
