import {
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast
  // Badge
} from '@chakra-ui/react';
import AgentIcon from 'assets/images/max-agent-image.svg';
import { RenderLoader } from 'components/Loaders/RenderLoader';
import Badge, { BadgeStyle } from 'components/badge/Badge';
import CustomButton, { ButtonStates } from 'components/buttons/Button';
import CardTitle from 'components/card/card-title';
import DropDownSelect from 'components/input/drop-down-select';
import ListItem from 'components/list-items/LisItem';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MdOutlineHighlightOff } from 'react-icons/md';
import { useMutation } from 'react-query';
import { putMethod } from 'services/request';
import { TicketInterface } from 'types/tickets';
import { incidentBadgeStyle, removeUnderScores, ucFirst } from 'util/ui-helpers';
import IncidentSuccessModal from './incident-success-modal';

export default function UpdateTicketModal({ isOpen, onClose, ticketId, ticketInfo, refetch }: UpdateTicketProps) {
  const [assignTicketToAgent, setAssignTicketToAgent] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const { mutateAsync: changeStatus, isLoading: isLogginInUser } = useMutation({
    mutationFn: putMethod
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<formValuesTypes>();

  const onSubmit: SubmitHandler<formValuesTypes> = async (data) => {
    // console.log(payload, 'test res');
    try {
      const payload = {
        status: data?.status?.value!
      };
      const response = await changeStatus({
        route: `${process.env.REACT_APP_TMS_BASE_URL}/ticket/${ticketId}/change-status`,
        payload
      });
      refetch();
      setSuccessModal(true);
      onClose();
      toast({
        title: 'Updated successfully',
        description: response?.message,
        position: 'top-right',
        duration: 5000,
        isClosable: true,
        status: 'success'
      });
    } catch (error: any) {
      toast({
        title: 'Sorry an error occurred',
        description: error,
        duration: 5000,
        position: 'top-right',
        isClosable: true,
        status: 'error'
      });
    }
  };

  const toast = useToast();

  const ticketStatus = ticketInfo ? ticketInfo.status : '';

  return (
    <>
      {isLogginInUser ? (
        <RenderLoader />
      ) : (
        <>
          <IncidentSuccessModal
            isOpen={successModal}
            onClose={() => {
              setSuccessModal(!successModal);
            }}
          />
          <Modal isCentered blockScrollOnMount={false} onClose={onClose} size="lg" isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent maxW="359px" borderRadius="1rem" bg="#FAFAFA">
              <form onSubmit={handleSubmit(onSubmit)}>
                <ModalHeader className="p-8">
                  <div className="flex items-end">
                    <div className="mr-8">
                      <ListItem
                        mainTitle={`${ucFirst(ticketInfo?.type)} type`}
                        mainTitleStyle="comm-r-14 text-grey02"
                        subTitle={`${ucFirst(removeUnderScores(ticketInfo?.category))}`}
                        subTitleStyle="text-black comm-d-18"
                      />
                    </div>
                    <div className="ml-2 h-[100%] ">
                      <Badge state={incidentBadgeStyle(ticketStatus)} badgeStyle={BadgeStyle.box}>
                        {ticketInfo?.status}
                      </Badge>
                    </div>
                  </div>
                </ModalHeader>
                <div className="text-grey01 cursor-pointer">
                  <ModalCloseButton as={MdOutlineHighlightOff} />
                </div>
                <div className="border-t border-[#E4E4E4] w-[100%]"></div>
                <ModalBody className="amortization-schedule-summary-modal">
                  <div className="my-8">
                    <p className="p-2 text-[#A8A8A8] text-[14px]">Change status</p>
                    <FormControl isInvalid={!!errors.status}>
                      <div>
                        <Controller
                          name="status"
                          control={control}
                          rules={{
                            required: 'This field is required'
                          }}
                          render={({ field }: { field: any }) => (
                            <DropDownSelect options={status} placeholder="Select status" field={field} />
                          )}
                        />
                      </div>
                    </FormControl>
                  </div>
                  <div className="border border-grey01 rounded-2xl mt-3 bg-white ">
                    <CardTitle title="Assigned Agent" />
                    <div className="p-5">
                      <ListItem
                        mainTitle={`${ucFirst(ticketInfo?.agent_name)}`}
                        mainTitleStyle="comm-d-14 text-black"
                        appendParentClass="items-center"
                        leading={
                          <div className="mr-[15px]">
                            <img src={AgentIcon} alt="Agent Icon" />
                          </div>
                        }
                        trailing={
                          <div
                            className="text-[#E88E15] uppercase text-center comm-b-12 w-full cursor-pointer hover:underline"
                            onClick={() => {
                              setAssignTicketToAgent(!assignTicketToAgent);
                              onClose();
                              // setSelectedIncident(incident);
                            }}
                          >
                            Change agent
                          </div>
                        }
                        trailingStyle="basis-1/2 justify-end items-center"
                      />
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter pb="20px">
                  <CustomButton
                    customStyle="justify-center mt-4 !h-[45px] !text-[12px] !font-bold !rounded-[10px]"
                    buttonType={'submit'}
                    state={ButtonStates.warning}
                    handleClick={() => {}}
                  >
                    SAVE CHANGES
                  </CustomButton>
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}

type UpdateTicketProps = {
  isOpen: boolean;
  onClose: () => void;
  ticketId: string;
  ticketInfo?: TicketInterface | null;
  refetch: any;
};

const status = [
  { value: 'in-progress', label: 'In Progress' },
  { value: 'open', label: 'Open' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'resolved', label: 'Resolved' }
];

type formValuesTypes = {
  status: {
    value: string;
    label: string;
  };
};
