import { Fragment, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Box, Flex, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

import { IMaxUser } from 'types/user';
import { store } from 'data/store';
import { capitalizeWord } from 'util/index';
import { getUserRole, getUserRoleLocation, USER_ROLES } from 'util/customQueryHooks';
import { PROTECTED_PATHS } from 'routes/constants';
import { KEY_LS_MAX_USER_VAMS } from 'util/constants';
import { maintenanceRequest } from 'services/request';
import axiosInstance from 'services/httpService';
import { useQuery } from 'react-query';

import UserImg from 'assets/images/user-img.svg';
import LogoutIcon from 'assets/images/logout.svg';

import 'components/app-nav/app-nav.styles.scss';

const AppNav = () => {
  const [open, setOpen] = useState(false);

  const userDetails = store.getJson(KEY_LS_MAX_USER_VAMS) as IMaxUser;

  const userRole = getUserRole();
  const userRoleLocation = getUserRoleLocation();

  const isVehicleManager = USER_ROLES.VEHICLE_MANAGER;
  const isFleetOfficer = userRole === USER_ROLES.FLEET_OFFICER;
  const isBulkUploadManager = userRole === USER_ROLES.BULK_UPLOAD_MANAGER;
  const isFleetOfficerMaintenance = userRole === USER_ROLES.FLEET_OFFICER_MAINTENANCE;
  const isSuperAdmin = userRole === USER_ROLES.SUPER_ADMIN;
  const isAgent = userRole === USER_ROLES.AGENT;
  const isAdmin = userRole === USER_ROLES.ADMIN;

  // get maintenance feature cities
  const getMaintenanceFeatCities = async () => {
    const url = `${maintenanceRequest.MAINTENANCE_FEAT_CITIES}`;
    const data = await axiosInstance.get(url);
    return data;
  };
  const { data: cities = [] }: any = useQuery(['report-cities'], () => getMaintenanceFeatCities(), {});
  const { data: { data: citiesData = [] } = {} } = cities;

  const handleClick = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const logout = () => {
    store.logout();

    window.location.href = '/login';
  };

  const NAV_ITEMS_VEHICLE_OFFICER = [
    {
      name: 'VEHICLES',
      to: PROTECTED_PATHS.VEHICLES
    },
    {
      name: 'CONTACT CENTER',
      to: PROTECTED_PATHS.CONTACT_CENTER
    },
    {
      name: 'ASSETS MOVEMENT',
      to: PROTECTED_PATHS.ASSET_MANAGEMENT
    },
    {
      name: 'MAINTENANCE',
      to: PROTECTED_PATHS.MAINTENANCE
    },
    {
      name: 'TICKET MANAGEMENT',
      to: PROTECTED_PATHS.TICKET_MANAGEMENT
    },
    {
      name: 'NOTIFICATION',
      to: PROTECTED_PATHS.NOTIFICATION
    }
  ];

  const NAV_ITEMS = [
    {
      name: 'VEHICLES',
      to: PROTECTED_PATHS.VEHICLES
    },
    {
      name: 'READY FOR ACTIVATIONS',
      to: PROTECTED_PATHS.READY_FOR_ACTIVATION
    },
    {
      name: 'TICKET MANAGEMENT',
      to: PROTECTED_PATHS.TICKET_MANAGEMENT
    },
    {
      name: 'CONTACT CENTER',
      to: PROTECTED_PATHS.CONTACT_CENTER
    },
    {
      name: 'ASSETS MOVEMENT',
      to: PROTECTED_PATHS.ASSET_MANAGEMENT
    },

    {
      name: 'USERS',
      to: PROTECTED_PATHS.USER_MANAGEMENT
    }
  ];

  const NAV_ITEMS_Two_Fleet = [
    {
      name: 'ASSETS MOVEMENT',
      to: PROTECTED_PATHS.ASSET_MANAGEMENT
    },
    {
      name: 'READY FOR ACTIVATIONS',
      to: PROTECTED_PATHS.READY_FOR_ACTIVATION
    },
    {
      name: 'TICKET MANAGEMENT',
      to: PROTECTED_PATHS.TICKET_MANAGEMENT
    }
  ];

  const NAV_ITEMS_FLEET_MAINTAINANCE_OFFICER = [
    {
      name: 'VEHICLES',
      to: PROTECTED_PATHS.VEHICLES
    },
    {
      name: 'READY FOR ACTIVATIONS',
      to: PROTECTED_PATHS.READY_FOR_ACTIVATION
    },
    {
      name: 'CONTACT CENTER',
      to: PROTECTED_PATHS.CONTACT_CENTER
    },
    {
      name: 'TICKET MANAGEMENT',
      to: PROTECTED_PATHS.TICKET_MANAGEMENT
    },

    {
      name: 'MAINTENANCE',
      to: PROTECTED_PATHS.MAINTENANCE
    },
    {
      name: 'NOTIFICATION',
      to: PROTECTED_PATHS.NOTIFICATION
    }
  ];

  const RenderVehicleManagerNavItems = () => {
    return (
      <ul className="nav-links active">
        {NAV_ITEMS_VEHICLE_OFFICER.map((el): any => (
          <li key={PROTECTED_PATHS.MAINTENANCE} className="nav-item">
            <NavLink
              to={el.to}
              onClick={closeMenu}
              className={(route) => (route.isActive ? 'nav-link__selected' : 'nav-link')}
            >
              {el.name}
            </NavLink>
          </li>
        ))}
      </ul>
    );
  };
  const RenderFleetOfficerMovementAndMaintenanceNavItems = () => {
    return (
      <ul className="nav-links active">
        <li key={PROTECTED_PATHS.MAINTENANCE} className="nav-item">
          <NavLink
            to={PROTECTED_PATHS.MAINTENANCE}
            onClick={closeMenu}
            className={(route) => (route.isActive ? 'nav-link__selected' : 'nav-link')}
          >
            MAINTENANCE
          </NavLink>
        </li>
      </ul>
    );
  };

  const RenderFleetOfficerMaintenanceNavItems = () => {
    return (
      <ul className="nav-links active">
        {NAV_ITEMS_FLEET_MAINTAINANCE_OFFICER.map((el): any => (
          <li key={el.to} className="nav-item">
            <NavLink
              to={el.to}
              onClick={closeMenu}
              className={(route) => (route.isActive ? 'nav-link__selected' : 'nav-link')}
            >
              {el.name}
            </NavLink>
          </li>
        ))}
      </ul>
    );
  };

  const RenderSuperAdmin = () => {
    return (
      <ul className={open ? 'nav-links active' : 'nav-links'}>
        {NAV_ITEMS.map((navItem) => {
          if (navItem.name === 'USERS') {
            if (userRole === USER_ROLES.SUPER_ADMIN) {
              return (
                <Fragment key={nanoid()}>
                  <li key={navItem.to} className="nav-item">
                    <NavLink
                      to={navItem.to}
                      onClick={closeMenu}
                      className={(route) => (route.isActive ? 'nav-link__selected' : 'nav-link')}
                    >
                      {navItem.name}
                    </NavLink>
                  </li>
                  {/* {citiesData.includes(userRoleLocation) && ( */}
                  <li key={PROTECTED_PATHS.MAINTENANCE} className="nav-item">
                    <NavLink
                      to={PROTECTED_PATHS.MAINTENANCE}
                      onClick={closeMenu}
                      className={(route) => (route.isActive ? 'nav-link__selected' : 'nav-link')}
                    >
                      MAINTENANCE
                    </NavLink>
                  </li>
                  {/* )} */}
                  {citiesData.includes(userRoleLocation) && (
                    <li key={PROTECTED_PATHS.NOTIFICATION} className="nav-item">
                      <NavLink
                        to={PROTECTED_PATHS.NOTIFICATION}
                        onClick={closeMenu}
                        className={(route) => (route.isActive ? 'nav-link__selected' : 'nav-link')}
                      >
                        NOTIFICATION
                      </NavLink>
                    </li>
                  )}
                </Fragment>
              );
            } else {
              return null;
            }
          } else {
            return (
              <li key={navItem.to} className="nav-item">
                <NavLink
                  to={navItem.to}
                  onClick={closeMenu}
                  className={(route) => (route.isActive ? 'nav-link__selected' : 'nav-link')}
                >
                  {navItem.name}
                </NavLink>
              </li>
            );
          }
        })}
      </ul>
    );
  };

  const RenderSuperAdminTwoFleet = () => {
    return (
      <ul className={open ? 'nav-links active' : 'nav-links'}>
        {NAV_ITEMS_Two_Fleet.map((navItem) => {
          if (navItem.name === 'USERS') {
            if (userRole === USER_ROLES.SUPER_ADMIN) {
              return (
                <Fragment key={nanoid()}>
                  <li key={navItem.to} className="nav-item">
                    <NavLink
                      to={navItem.to}
                      onClick={closeMenu}
                      className={(route) => (route.isActive ? 'nav-link__selected' : 'nav-link')}
                    >
                      {navItem.name}
                    </NavLink>
                  </li>
                  {citiesData.includes(userRoleLocation) && (
                    <li key={PROTECTED_PATHS.MAINTENANCE} className="nav-item">
                      <NavLink
                        to={PROTECTED_PATHS.MAINTENANCE}
                        onClick={closeMenu}
                        className={(route) => (route.isActive ? 'nav-link__selected' : 'nav-link')}
                      >
                        MAINTENANCE
                      </NavLink>
                    </li>
                  )}
                </Fragment>
              );
            } else {
              return null;
            }
          } else {
            return (
              <>
                <li key={navItem.to} className="nav-item">
                  <NavLink
                    to={navItem.to}
                    onClick={closeMenu}
                    className={(route) => (route.isActive ? 'nav-link__selected' : 'nav-link')}
                  >
                    {navItem.name}
                  </NavLink>
                </li>
                <li key={PROTECTED_PATHS.MAINTENANCE} className="nav-item">
                  <NavLink
                    to={PROTECTED_PATHS.MAINTENANCE}
                    onClick={closeMenu}
                    className={(route) => (route.isActive ? 'nav-link__selected' : 'nav-link')}
                  >
                    MAINTENANCE
                  </NavLink>
                </li>
              </>
            );
          }
        })}
      </ul>
    );
  };

  return (
    <Box as="nav" className="navbar" h="100%" border="1px solid #EEEEEE">
      <Link to={PROTECTED_PATHS.ASSET_MANAGEMENT}>
        <Flex bg="#3E3E3E" color="white" px="30px" py="15px" _hover={{ cursor: 'pointer', color: '#fcdd18' }}>
          <Text fontSize="md" fontWeight="bold">
            VAMS
          </Text>
        </Flex>
      </Link>
      <div className="nav-group">
        <div onClick={handleClick} className="nav-icon">
          {open ? <XIcon width={18} /> : <MenuIcon width={18} />}
        </div>
        {isVehicleManager && !isSuperAdmin && !isAgent && !isAdmin && !isFleetOfficerMaintenance && (
          <RenderVehicleManagerNavItems />
        )}
        {/* Shared with fleet  maintenance officer  before separatio */}
        {isFleetOfficer && isFleetOfficerMaintenance && <RenderSuperAdminTwoFleet />}
        {/* Maintainance  access only to fleet maintenance officer */}
        {isFleetOfficerMaintenance && <RenderFleetOfficerMaintenanceNavItems />}
        {/* {isFleetOfficer && <RenderFleetOfficerNavItems />} */}
        {isBulkUploadManager && citiesData.includes(userRoleLocation) && (
          <RenderFleetOfficerMovementAndMaintenanceNavItems />
        )}
        {(isSuperAdmin || isAgent || isAdmin) && <RenderSuperAdmin />}

        <Flex alignItems="center">
          <div className="mr-3">
            <Text color="#2c2c2c" fontSize="0.85rem">
              {userDetails.first_name + ' ' + userDetails.last_name}
            </Text>
            <Text color="#ffcc00" fontSize="0.85rem">
              {capitalizeWord(userRole)}
            </Text>
          </div>

          <div className="dropdown">
            <img className="dropbtn" src={UserImg} alt="active user" />

            <div className="dropdown-content">
              <small className="mb-0 cursor" onClick={logout}>
                {' '}
                <img className="mr-2" src={LogoutIcon} alt="logout icon" />
                Logout
              </small>
            </div>
          </div>
        </Flex>
      </div>
    </Box>
  );
};

export default AppNav;
