import  { useState } from 'react';
import style from './search.module.scss';

const Search = ({ handleSubmit, handleCloseSearch, placeholder }) => {
  const [localSearchValue, setLocalSearchValue] = useState('');

  const onSubmitHandler = () => {
    handleSubmit(localSearchValue);
  };

  const handleChange = event => {
    setLocalSearchValue(event.target.value);
  };

  const closeSearch = () => {
    handleSubmit('');
  };

  return (
    <div className={style['search']}>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmitHandler();
        }}
        className={style['search-form']}>
        <input
          value={localSearchValue}
          onChange={handleChange}
          placeholder={placeholder}
          className=''
          autoFocus
        />
        <input className={style['hidden-submit']} type='submit' hidden />
      </form>

      <button
        onClick={() => {
          handleCloseSearch();
          closeSearch();
        }}
        className={style['cancel-btn']}>
        X CANCEL
      </button>
    </div>
  );
};

export default Search;
