import { FC } from 'react';
import { chakra, Flex, Heading, HStack, Spacer } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { motion, isValidMotionProp } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

type Props = {
  canNavigate?: boolean;
  handleFilter?: () => void;
  pageName?: any;
};

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children'
});

const FilterContainer: FC<Props> = ({ canNavigate, children, pageName }) => {
  const navigate = useNavigate();
  return (
    <ChakraBox
      initial={{ opacity: 0, y: -30 }}
      animate={{ opacity: 1, y: 0 }}
      // @ts-ignore no problem in operation, although type error appears.
      transition={{
        ease: 'easeInOut'
      }}
      pos="sticky"
      top="50px"
      zIndex={2}
    >
      <Flex backgroundColor="#fff" borderBlock="1px solid #dbdbdb" px="50px" py="10px" h="55px" align="center" w="100%">
        {canNavigate && (
          <Flex borderRight="1px" borderColor="gray.200" pr={5} mr={5} alignItems="center">
            <button onClick={() => navigate(-1)}>
              <ArrowBackIcon w={5} h={5} color="#000" />
            </button>
          </Flex>
        )}
          <Heading fontSize="18px">{pageName}</Heading>
        <Spacer />
        <HStack>{children}</HStack>
      </Flex>
    </ChakraBox>
  );
};

export default FilterContainer;
