import { Box, Flex, Td, Tr, useDisclosure } from '@chakra-ui/react';
import { ReactComponent as UserIcon } from 'assets/images/helmet-md.svg';
import { RenderLoader } from 'components/Loaders/RenderLoader';
import NoData from 'components/NoData';
import Search from 'components/Search';
import Badge, { BadgeStyle } from 'components/badge/Badge';
import AsyncCustomSelect from 'components/custom-select/AsyncSelect';
import ListItem from 'components/list-items/LisItem';
import BasicTable from 'components/tables/basic-table';
import { store } from 'data/store';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';
import ReactPaginate from 'react-paginate';
import { useQuery } from 'react-query';
import axiosInstance, { configServiceRequest } from 'services/httpService';
import { TicketInterface } from 'types/tickets';
import { IMaxUser } from 'types/user';
import { KEY_LS_MAX_USER_VAMS } from 'util/constants';
import { incidentBadgeStyle, removeUnderScores, ucFirst } from 'util/ui-helpers';
import CallScriptModal from './call-script-modal';
import ViewIncidentDetailsModal from './view-incident-details-modal';
interface PaginationProps {
  pageCount: number;
  currentPage: number;
  onPageChange: (selectedItem: { selected: number }) => void;
}

const Pagination: React.FC<PaginationProps> = ({ pageCount, currentPage, onPageChange }) => {
  return (
    <ReactPaginate
      pageCount={pageCount}
      pageRangeDisplayed={5}
      marginPagesDisplayed={2}
      onPageChange={onPageChange}
      containerClassName="pagination flex space-x-2 mt-4"
      pageClassName="px-5 py-2 bg-white border rounded-[50%] flex justify-center  items-center opacity-[0.4] font-bold text-[14px]"
      activeClassName="bg-[#fff8ce] text-black opacity-[1] "
      previousClassName={`flex justify-center items-center ${
        currentPage === 0 ? 'opacity-[0.2]  cursor-not-allowed' : ''
      }`}
      nextClassName={`flex justify-center items-center ${
        currentPage === pageCount - 1 ? 'opacity-[0.2] cursor-not-allowed' : ''
      }`}
      previousLabel={<FiChevronsLeft color="#f3f3f3" size={28} className="bg-black rounded-[50%] p-[5px]" />}
      nextLabel={<FiChevronsRight color="#f3f3f3" size={28} className="bg-black rounded-[50%] p-[5px]" />}
      initialPage={currentPage}
    />
  );
};
const TicketManagmentOverview = () => {
  const [data, setData] = useState<TicketInterface[]>([]);
  const [stats, setStats] = useState<any>();
  const [totalPages, setTotalPages] = useState();
  const [currentPage] = useState(0);
  const [searchName, setSearchName] = useState('');
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();
  const [location, setLocation] = useState();
  const [showIncidentDetails, setShowIncidentDetails] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState<TicketInterface | null>(null);
  const {
    isOpen: isOpenCallScriptModal,
    onClose: onCloseCallScriptModal,
    onOpen: onOpenCallScriptModal
  } = useDisclosure();

  const userDetails = store.getJson(KEY_LS_MAX_USER_VAMS) as IMaxUser;

  const getMethod = async ({ queryKey }) => {
    let { route, params } = queryKey[1];
    try {
      const { data } = await axiosInstance.get(`${route}`, {
        params
      });
      return data;
    } catch (error) {
      throw error;
    }
  };
  const ticketQuery = useQuery(
    [
      'fetchAllTickets',
      {
        route: `${process.env.REACT_APP_TMS_BASE_URL}/ticket/fetch-all`,
        params: {
          limit: 10,
          location: location,
          page: currentPage + 1,
          search: searchName,
          agent_id: userDetails.user_account_id
        }
      }
    ],
    getMethod
  );
  const getSubCitiesWithSearch = async (inputValue) => {
    const response = await axiosInstance.get(configServiceRequest.GET_ALL_SUB_LOCATIONS, {
      params: {
        include_search: true,
        search: inputValue
      }
    });
    const mappedLocation = response.data.payload.message.objects.map((loc: any) => {
      return {
        value: loc.id,
        label: loc.name,
        name: loc.name
      };
    });

    const filteredLoc = mappedLocation.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    return [...filteredLoc];
  };
  const ticketStatQuery = useQuery(
    [
      'ticket-count',
      {
        route: `${process.env.REACT_APP_TMS_BASE_URL}/ticket/count?type=ticket`,
        params: {
          agent_id: userDetails.user_account_id
        }
      }
    ],
    getMethod
  );
  useEffect(() => {
    setStats(ticketStatQuery.data?.data?.categorized);
  }, [ticketStatQuery.data?.data?.categorized]);

  const cards = [
    {
      title: 'Open Tickets',
      count: (stats && stats[0]?.total) ?? 0
    },
    {
      title: 'Closed Tickets',
      count: (stats && stats[1]?.total) ?? 0
    }
  ];

  const ticketsHeaders = [
    { title: 'Affected Champions' },
    { title: 'Ticket Category' },
    { title: 'Location' },
    { title: 'Assigned Agent' },
    { title: 'Ticket Creator' },
    { title: 'Status' },
    { title: 'Date Created' }
  ];

  useEffect(() => {
    setData(ticketQuery.data?.data?.tickets as TicketInterface[]);
    setTotalPages(ticketQuery.data?.data?.total_pages);
  }, [ticketQuery.data]);

  return (
    <div className="w-screen flex-col items-center flex justify-center">
      <ViewIncidentDetailsModal
        isOpen={showIncidentDetails}
        onClose={() => {
          setShowIncidentDetails(!showIncidentDetails);
        }}
        onOpenCallScriptModal={onOpenCallScriptModal}
        ticketId={selectedIncident?.uuid as string}
        status={selectedIncident?.status}
        refetch={() => ticketQuery.refetch()}
      />
      <CallScriptModal
        onClose={onCloseCallScriptModal}
        isOpen={isOpenCallScriptModal}
        ticketId={selectedIncident?.uuid as string}
        ticketSlug={selectedIncident?.category as string}
        status={selectedIncident?.status}
        refetch={() => ticketQuery.refetch()}
      />
      <div className="h-20 w-full bg-white flex items-center ">
        <p className="text-xl font-bold font-sans ml-12">Ticket Management</p>
      </div>

      <div className="w-full flex gap-[20px] flex-wrap py-[21px] self-start mx-8">
        <div className="flex items-center gap-x-4">
          {cards?.map((card, index) => (
            <div key={index} className="h-[84px] w-[332px] bg-white rounded-[4px] flex items-center justify-center p-6">
              <div className="flex  justify-between w-full items-center">
                <p className="text-[#808080] text-sm w-[49px]">{card?.title}</p>
                <p className="text-[#3e3e3e] text-2xl font-bold">{card?.count}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="h-[670px] bg-white w-[95%] relative">
          <div className="w-full h-[58px] flex items-center justify-between  bg-white pr-4">
            <p className="text-xl font-bold font-sans ml-12">Ticket Management</p>

            <div className="flex gap-x-5">
              {' '}
              <AsyncCustomSelect
                onChange={(e) => {
                  setLocation(e.value);
                }}
                loadOptions={getSubCitiesWithSearch}
                placeholder="All Sub Cities"
                name="All Locations"
                cacheOptions
                defaultOptions
                className="!w-[500px]"
                onInputChange={(data) => {
                  console.log(data);
                }}
              />
              <div>
                <Search
                  onChange={(value) => {
                    setSearchName(value.target.value);
                  }}
                  disabled={false}
                />
              </div>
            </div>
          </div>

          <Box overflowY="scroll">
            {ticketQuery.isLoading ? (
              <div className="flex items-center justify-center h-[500px] w-screen">
                <RenderLoader />
              </div>
            ) : data === undefined || data?.length === 0 ? (
              <NoData />
            ) : (
              <BasicTable header={ticketsHeaders}>
                {data?.map((incident, _i) => (
                  <Tr
                    onClick={() => {
                      setSelectedIncident(incident);
                      setShowIncidentDetails(true);
                    }}
                    role="button"
                    key={_i}
                  >
                    <Td>
                      <ListItem
                        appendParentClass="items-center"
                        mainTitle={incident?.champion_name}
                        subTitle={incident?.champion_max_id}
                        mainTitleStyle="text-[16px] leading-[22px] font-[600] text-primary"
                        subTitleStyle="text-[12px] leading-[20px] font-medium text-[#E88E15]"
                        leading={
                          <div className="mr-6">
                            <UserIcon />
                          </div>
                        }
                      />
                    </Td>
                    <Td>{ucFirst(removeUnderScores(incident?.category))}</Td>
                    <Td>{ucFirst(incident?.location)}</Td>
                    <Td>{ucFirst(incident?.agent_name)}</Td>
                    <Td>{ucFirst(incident?.reporter)}</Td>
                    <Td>
                      <Badge state={incidentBadgeStyle(incident.status!)!} badgeStyle={BadgeStyle.box}>
                        {incident.status!}
                      </Badge>
                    </Td>
                    <Td>
                      {incident?.created_at ? format(new Date(incident?.created_at), 'dd/MM/yyyy') : 'Invalid Date'}
                    </Td>
                  </Tr>
                ))}
              </BasicTable>
            )}
          </Box>
          <Flex mt={'50px'} position={'absolute'} bottom={2} right={10}>
            <Pagination pageCount={totalPages || 1} currentPage={currentPage} onPageChange={(e) => console.log(e)} />
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default TicketManagmentOverview;
export const incidentHeaders = [
  { title: 'Champion' },
  { title: 'Incident' },
  { title: 'Location' },
  { title: 'Incident Agent' },
  { title: 'Incident Creator' },
  { title: 'Status' },
  { title: 'Date Created' },
  { title: ' ' }
];
