import React, { FC } from 'react'
import './vehicle-details.styles.scss'
import Sedan from '../../../assets/images/grey-sedan-car.svg'
import { FaEdit } from "react-icons/fa";
import { IVehicleDetails } from '../../../types/types';
type Props = {
    vehicleObj: {}
    onEdit: (currentProduct: IVehicleDetails) => void
}

const VehiclesDetails: FC<Props> = (props) => {
    const { vehicleObj, onEdit } = props
    const selectedVehicleObj = vehicleObj as IVehicleDetails
    const { vehicle_movement } = selectedVehicleObj;
    return (
        <div className="vehicles-details">
            {Object.entries(selectedVehicleObj).length > 0 && <>
                <div className="details-card m-4 p-3">
                    <div className="flex justify-between items-center">
                        <p className="card-title">Vehicle Details</p>

                        <button onClick={() => {
                            onEdit(selectedVehicleObj)
                        }}>
                            <FaEdit style={{ fontSize: '13px' }} />
                        </button>
                    </div>

                    <hr />

                    <div className="ui grid my-6">
                        <div className="four wide column">
                            <img src={Sedan} alt="Car Icon" />
                        </div>
                        <div className="twelve wide column">
                            <div className="ui grid">
                                <div className="eight wide column">
                                    <p className="list-title">Asset Type</p>
                                    <p className="list-content">{selectedVehicleObj.vehicle_type ? selectedVehicleObj.vehicle_type.name : 'N/A'}</p>
                                </div>
                                <div className="eight wide column">
                                    <p className="list-title">Vehicle Manufacturer</p>
                                    <p className="list-content">{selectedVehicleObj.manufacturer ? selectedVehicleObj.manufacturer.name : 'N/A'}</p>
                                </div>
                            </div>

                            <div className="ui grid">
                                <div className="eight wide column">
                                    <p className="list-title">Plate Number</p>
                                    <p className="list-content">{selectedVehicleObj.plate_number ? selectedVehicleObj.plate_number : 'N/A'}</p>
                                </div>
                                <div className="eight wide column">
                                    <p className="list-title">Vehicle ID</p>
                                    <p className="list-content">{selectedVehicleObj?.max_vehicle_id ? selectedVehicleObj?.max_vehicle_id : 'N/A'}</p>
                                </div>
                            </div>
                            <div className="ui grid">
                                <div className="eight wide column">
                                    <p className="list-title">Batch</p>
                                    <p className="list-content">{selectedVehicleObj.batch ? selectedVehicleObj?.batch?.name : 'N/A'}</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                {
                    vehicle_movement &&

                    <div className="details-card m-4 p-3">
                        <div className="flex justify-between items-center">
                            <p className="card-title">Status</p>
                        </div>

                        <hr />
                        <div className="flex items-center">

                            <label className="switch">
                                <input type="checkbox" readOnly checked={vehicle_movement === "entry"} />
                                <span className="slider round"></span>
                            </label>
                            <span className={`ml-8 ${vehicle_movement === "entry" ? "green" : "red"}-pill`} >
                                {vehicle_movement}
                            </span>
                        </div>
                        <p className="mt-5">Unscheduled Mantenance</p>
                    </div>
                }
            </>}

            {Object.entries(selectedVehicleObj).length === 0 && <div className="mt-16 flex items-center justify-center">
                {/* empty state */}
                < div className="flex justify-center items-center" style={{ flexDirection: 'column' }}>
                    <img src={Sedan} alt="Car Icon" style={{ fontSize: '9px' }} />

                    <h5>Nothing to see here</h5>
                </div>
            </div>
            }
        </div >
    )
}

export default VehiclesDetails
