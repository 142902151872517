import { FC } from 'react';
import { Box, Divider, Text } from '@chakra-ui/react';

interface StatsContainerI {
  children?: React.ReactNode;
  title: string;
}

export const StatsContainer: FC<StatsContainerI> = ({ children, title }) => {
  return (
    <Box bgColor="#fff" border="1px" borderColor="rgba(238, 238, 238, 1)" borderRadius="10px">
      <Box px={4} py={2}>
        <Text color="rgba(128, 128, 128, 1)" fontSize="0.8125rem">
          {title}
        </Text>
      </Box>
      <Divider />
      {children}
    </Box>
  );
};
