import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';

type MaintenanceSummaryProps = {
  totalVehicles?: number | string;
  title?: string;
  indexData?: {
    name: string;
    value: string | number;
    navlink?: string;
  }[];
};

export const MaintenanceSummary: FC<MaintenanceSummaryProps> = ({ title, indexData, totalVehicles }) => {
  const location = useLocation();

  return (
    <Box border="1px" borderColor="#EEEEEE">
      <Flex alignItems="center" bgColor="#fff" gap={3} justifyContent="space-between" p={5}>
        <Text color="#808080" maxW="100px">
          {title}
        </Text>
        <Heading color="#444444" fontSize="2rem" fontWeight="bold">
          {totalVehicles?.toLocaleString('en-US')}
        </Heading>
      </Flex>
      {indexData?.map((item, idx) => (
        <Link key={idx} to={item.navlink ?? ''}>
          <Flex
            alignItems="center"
            bgColor="#FAFAFA"
            borderTop="1px"
            borderColor="#EEEEEE"
            className="maintenance-summary-item"
            justifyContent="space-between"
            p={5}
          >
            <Box>
              <Text fontSize="0.875rem" color="#808080" align="left">
                {item.name}
              </Text>
              <Text color="#444444" fontWeight="bold" align="left">
                {item.value}
              </Text>
            </Box>
            {location.pathname === '/maintenance' && <ChevronRightIcon />}
          </Flex>
        </Link>
      ))}
    </Box>
  );
};
