import axiosInstance from '../services/httpService';
import { configServiceRequest, vehiclePageRequest } from '../services/request';

export const getAllCities = async () => {
  const res = await axiosInstance.get(configServiceRequest.GET_ALL_LOCATIONS);
  const mappedLocation = res.data.payload.message.objects.map((loc: any) => {
    return {
      value: loc.id,
      label: loc.name,
      name: loc.name,
    };
  });
  return [...mappedLocation];
};
export const getAllSubCities = async () => {
  const res = await axiosInstance.get(configServiceRequest.GET_ALL_SUB_LOCATIONS);
  const mappedLocation = res.data.payload.message.objects.map((loc: any) => {
    return {
      value: loc.id,
      label: loc.name,
      name: loc.name,
    };
  });
  return [...mappedLocation];
};

export const getVehicleType = async () => {
  const response = await axiosInstance.get(vehiclePageRequest.VEHICLE_TYPE);
  const allVehicleType = response.data.data.map((loc: any) => {
    return {
      value: loc.id,
      label: loc.name,
      name: loc.name,
    };
  });
  return [...allVehicleType];
};
export const getManufactures = async () => {
  const response = await axiosInstance.get(vehiclePageRequest.ALL_MANUFACTURES);
  const allManufactures = response.data.data.map((maker: any) => {
    return {
      value: maker.id,
      name: maker.name,
      label: maker.name
    };
  });

  return allManufactures;
};

export const getPlatforms = async () => {
  const response = await axiosInstance.get(vehiclePageRequest.ALL_PLATFORMS);
  const allPlatforms = response.data.payload.message.objects.map((maker: any) => {
    return {
      value: maker.id,
      name: maker.name,
      label: maker.name
    };
  });

  return allPlatforms;
};
export const getSubReason = async () => {
  const res = await axiosInstance.get(configServiceRequest.GET_ALL_SUB_REASON);
  const mappedLocation = res.data.payload.message.objects.map((loc: any) => {
    return {
      value: loc.id,
      label: loc.name,
      name: loc.name,
    };
  });
  return [...mappedLocation];
};