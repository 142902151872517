import { FC } from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import { maintenanceRequest } from 'services/request';
import axiosInstance from 'services/httpService';
import { convertParamsToString } from 'helpers/auth-helpers';
import { QUERY_KEYS } from '../../util/constants';
import { useMaintenanceContext } from './context';

import FilterContainer from 'components/filter-container/filter-container.component';
import { MaintenanceDetailSummary } from 'components/Maintenance/MaintenanceDetail';
import { MaintenanceDetailSection } from 'components/Maintenance/MaintenanceDetail/MaintenanceDetailSection';
import PageWrapper from 'components/page-wrapper';

export const MaintenanceDetail: FC = () => {
  const { id } = useParams();

  const {
    qaTesterRoleId,
    technicianRoleId,
    setQaTesterRoleId,
    setTechnicianRoleId,
    setJobCardDetails,
    setIsLoading,
    setMaintenanceReportID,
    setVehicleData,
    vehicleData,
    setPastQaTesters,
    setPastTechnicians
  } = useMaintenanceContext();

  const scheduleID = vehicleData?.maintenance_schedule?.id;

  // get the list of all previous testers assigned to this maintenance
  const getTestersHistory = async () => {
    const url = `${maintenanceRequest.SCHEDULE_USERS}/${vehicleData?.maintenance_schedule?.id}?role_id=${qaTesterRoleId}`;
    const data = await axiosInstance.get(url);
    return data;
  };

  const allTesters = useQuery(QUERY_KEYS.TESTERS_HISTORY, getTestersHistory, {
    enabled: !!vehicleData && !!qaTesterRoleId,
    onSuccess: ({ data: { data = {} } }) => {
      setPastQaTesters(data);
    }
  });

  // get the list of all previous technicians assigned to this maintenance
  const getTechniciansHistory = async () => {
    const url = `${maintenanceRequest.SCHEDULE_USERS}/${vehicleData?.maintenance_schedule?.id}?role_id=${technicianRoleId}`;
    const data = await axiosInstance.get(url);
    return data;
  };

  const allTechnicians = useQuery(QUERY_KEYS.TECHNICIANS_HISTORY, getTechniciansHistory, {
    enabled: !!vehicleData && !!technicianRoleId,
    onSuccess: ({ data: { data = {} } }) => {
      setPastTechnicians(data);
    }
  });

  const getUserRoleID = async () => {
    try {
      const url = `${maintenanceRequest.GET_USER_ROLE_ID}`;
      const response = await axiosInstance.get(url);
      const { data: { data = {} } = {} } = response;
      setTechnicianRoleId(data.technician);
      setQaTesterRoleId(data.qa_tester);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getVehicleData = async ({ queryKey }) => {
    setIsLoading(true);
    try {
      const url = convertParamsToString(maintenanceRequest.GET_MAINTENANCE_VEHICLE, {
        vehicleUUID: queryKey[1]
      });
      const response = await axiosInstance.get(url);
      setVehicleData(response?.data?.data);
      return response?.data?.data;
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getJobCardDetails = async ({ queryKey }) => {
    if (!scheduleID) return;
    setIsLoading(true);
    try {
      const url = convertParamsToString(maintenanceRequest.GET_JOB_DETAILS_BY_MAINTENANCE_SCHEDULE, {
        maintenanceScheduleID: queryKey[1]
      });
      const response = await axiosInstance.get(url);
      setJobCardDetails(response?.data?.data);
      setMaintenanceReportID(response?.data?.data[0]?.id);
      return response?.data?.data;
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const { refetch } = useQuery([QUERY_KEYS.GET_MAINTENANCE_VEHICLE_BY_ID, id], getVehicleData);
  const { refetch: refetchJobDetails } = useQuery([QUERY_KEYS.GET_JOB_CARD_BY_ID, scheduleID], getJobCardDetails);
  const { refetch: refetchUserRole } = useQuery([QUERY_KEYS.GET_USER_ROLES], getUserRoleID);

  const handleRefetch = () => {
    refetch();
    refetchJobDetails();
    refetchUserRole();
    allTechnicians.refetch();
    allTesters.refetch();
  };

  return (
    <>
      <FilterContainer canNavigate pageName="Vehicle Maintenance Details" />
      <PageWrapper>
        <Grid gap={4} mt="16px" templateColumns="repeat(12, 1fr)">
          <GridItem bg="#FAFAFA" borderRadius="10px" colStart={1} colEnd={4} p={5} pos="fixed" w="22%">
            <MaintenanceDetailSummary />
          </GridItem>
          <GridItem borderRadius="10px" colStart={4} colEnd={13} bg="#fff">
            <MaintenanceDetailSection refetch={handleRefetch} />
          </GridItem>
        </Grid>
      </PageWrapper>
    </>
  );
};
