import { FC, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Grid, GridItem } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useQuery, useQueryClient } from 'react-query';

import { QUERY_KEYS } from 'util/constants';
import axiosInstance, { championContractRequests, vehicleActivity, vehiclePageRequest } from 'services/httpService';
import { convertParamsToString } from 'helpers/auth-helpers';
import { postRequest, useMutationWrapper } from 'services/apiHelper';

import FilterContainer from 'components/filter-container/filter-container.component';
import PageWrapper from 'components/page-wrapper';
import { VehicleSummary } from 'components/VehicleActivity/VehicleDetails/VehicleSummary';
import { VehicleChampionDetails } from 'components/VehicleActivity/VehicleDetails/VehicleChampionDetails';

export const VehicleActivityDetail: FC = () => {
  const [championActiveContractId, setChampionActiveContractId] = useState('');
  const [activeChampionId, setActiveChampionId] = useState('');

  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const getVehicleActivityAssetByID = async () => {
    try {
      const url = convertParamsToString(vehicleActivity.GET_VEHICLE_BY_ID + `/${id}`, {});
      const response = await axiosInstance.get(url);
      const updatedData = response?.data?.data[response?.data?.data.length - 1];

      return updatedData;
    } catch (err) {
      console.error(err);
    }
  };

  const getVehicleByID = async () => {
    try {
      const url = convertParamsToString(vehiclePageRequest.VEHICLE + `/${id}`, {});
      const response = await axiosInstance.get(url);
      return response?.data?.data;
    } catch (err) {
      console.error(err);
    }
  };

  const {
    data: vehicleActivityAssetData,
    isLoading: isLoadingVehicleActivityAssetData,
    isError: isErrorVehicleActivityAssetData
  } = useQuery(QUERY_KEYS.GET_VEHICLE_ACTIVITY_ID, getVehicleActivityAssetByID);

  const { data: championAndContractData, isLoading: isLoadingChampionAndContractData } = useQuery(
    QUERY_KEYS.GET_VEHICLE_BY_ID,
    getVehicleByID
  );

  const onChampionContractIDFetched = ({ data }) => {
    queryClient.invalidateQueries(QUERY_KEYS.GET_VEHICLE_ACTIVITY_CHAMPION_DETAILS);
    setChampionActiveContractId(data[0]?.id);
    setActiveChampionId(data[0]?.champion_id);
  };

  const { mutate: getChampionContractID } = useMutationWrapper(postRequest, onChampionContractIDFetched);

  const getChampionActiveContractTranche = async ({ queryKey }) => {
    const url = convertParamsToString(championContractRequests.GET_CHAMPION_TRANCHE, {
      championContractId: queryKey[1]
    });
    const response = await axiosInstance.get(url);
    return response?.data?.data;
  };

  const getChampionActiveContract = async ({ queryKey }) => {
    const url = convertParamsToString(championContractRequests.GET_CHAMPION_CONTRACT_DETAILS, {
      championId: queryKey[1]
    });
    const response = await axiosInstance.get(url);
    return response?.data?.data;
  };

  const { data: trancheData, isLoading: isLoadingTrancheData } = useQuery(
    [QUERY_KEYS.GET_CHAMPION_CONTRACT_TRANCHE_DETAILS, championActiveContractId],
    getChampionActiveContractTranche,
    {
      enabled: Boolean(championActiveContractId)
    }
  );

  const { data: contractData, isLoading: isLoadingContractData } = useQuery(
    [QUERY_KEYS.GET_CHAMPION_CONTRACT, activeChampionId],
    getChampionActiveContract,
    {
      enabled: Boolean(activeChampionId)
    }
  );

  useEffect(() => {
    const viewChampionDetails = () => {
      const data = {
        vehicle_ids: [id]
      };

      getChampionContractID({
        url: championContractRequests.GET_ACTIVE_CHAMPION_CONTRACT_DETAILS,
        data
      });
    };

    viewChampionDetails();
    //eslint-disable-next-line
  }, [championActiveContractId]);

  const isLoadingOptions =
    isLoadingVehicleActivityAssetData ||
    isLoadingChampionAndContractData ||
    isLoadingContractData ||
    isLoadingTrancheData;

  const isErrorOptions = isErrorVehicleActivityAssetData;

  return (
    <>
      <FilterContainer
        pageName={
          <Button color="#3E3E3E" leftIcon={<ArrowBackIcon />} onClick={() => navigate(-1)} variant="link">
            Vehicle Details
          </Button>
        }
      />
      <PageWrapper>
        <Grid gap={4} mt="16px" templateColumns="repeat(12, 1fr)">
          <GridItem
            bg="#fff"
            borderRadius="10px"
            colStart={1}
            colEnd={4}
            px={5}
            pt={10}
            pb={5}
            h="400px"
            pos="fixed"
            w="22%"
          >
            <VehicleSummary vehicleData={vehicleActivityAssetData} isLoading={isLoadingVehicleActivityAssetData} />
          </GridItem>
          <GridItem borderRadius="10px" colStart={4} colEnd={13} p={5} bg="#fff">
            <VehicleChampionDetails
              vehicleData={vehicleActivityAssetData}
              currentChampion={championAndContractData}
              contractData={contractData}
              isError={isErrorOptions}
              isLoading={isLoadingOptions}
              trancheData={trancheData}
            />
          </GridItem>
        </Grid>
      </PageWrapper>
    </>
  );
};
