import { FC } from 'react';
import { Box, Button, Flex, Select, Text } from '@chakra-ui/react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useQuery, useQueryClient } from 'react-query';
import { nanoid } from 'nanoid';

import { putRequest, useMutationWrapper } from 'services/apiHelper';
import axiosInstance, { maintenanceRequest } from 'services/httpService';
import { convertParamsToString } from 'helpers/auth-helpers';
import { useMaintenanceContext } from 'pages/maintenance/context';
import { QUERY_KEYS } from 'util/constants';
import { MaintenanceJobCardUpdateStatus } from 'interfaces/index';

import Modal from 'components/BaseModal';

interface StatusChangeModalI {
  isOpen: boolean;
  refetch: any;
  onClose: () => void;
}

interface StatusJobCardUpdateI {
  status: string;
  reason: string;
  duration_in_minute: number;
}

export const StatusChangeModal: FC<StatusChangeModalI> = ({ isOpen, onClose, refetch }) => {
  const { jobCardDetails, maintenanceReportID, testersReport } = useMaintenanceContext();

  const queryClient = useQueryClient();

  const numberOfFailedTests = testersReport?.checklist_metadata?.filter(
    (item) => item?.currentStatus?.status === 'bad'
  )?.length;

  let maintenanceEndTime;
  if (jobCardDetails[0]?.job_card_status_logs?.length > 0) {
    maintenanceEndTime = new Date(jobCardDetails[0]?.job_card_status_logs[0]?.created_at);
  } else {
    maintenanceEndTime = new Date(jobCardDetails[0]?.maintenance_start_time);
  }

  const currentTime = new Date();
  const millisecondsInMinutes = 60000;

  const durationInMins = Math.floor(Math.abs(currentTime.getTime() - maintenanceEndTime) / millisecondsInMinutes);

  const updateJobCardUrl = convertParamsToString(maintenanceRequest.UPDATE_JOB_CARD_STATUS, {
    maintenanceReportID
  });

  const getPauseReasons = async () => {
    try {
      const response = await axiosInstance.get(maintenanceRequest.GET_PAUSE_REASONS);
      return response?.data?.data;
    } catch (err) {
      console.error('Job card pause reasons::', err);
    }
  };

  const getPendingReasons = async () => {
    try {
      const response = await axiosInstance.get(maintenanceRequest.GET_PENDING_REASONS);
      return response?.data?.data;
    } catch (err) {
      console.error('Job card pending reasons::', err);
    }
  };

  const { data: getPauseReasonsData } = useQuery(QUERY_KEYS.PAUSE_REASONS, getPauseReasons);
  const { data: getPendingReasonsData } = useQuery(QUERY_KEYS.PENDING_REASONS, getPendingReasons);

  const initialValues: StatusJobCardUpdateI = {
    status: '',
    reason: 'Issues resolved',
    duration_in_minute: durationInMins
  };

  const onSuccess = () => {
    toast.success('Job status successfully updated!');
    onClose();
    refetch();
    queryClient.refetchQueries(QUERY_KEYS.GET_JOB_CARD_BY_ID);
  };

  const { mutate, isLoading } = useMutationWrapper(putRequest, onSuccess);

  const handleSubmit = (fields: StatusJobCardUpdateI) => {
    return mutate({
      url: updateJobCardUrl,
      data: fields
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Change Job Card Status"
      size="lg"
      subTitle="Please tick the appropriate boxes to indicate the correct status"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values: StatusJobCardUpdateI, { setSubmitting }: FormikHelpers<StatusJobCardUpdateI>) =>
          handleSubmit(values)
        }
        validateOnMount
      >
        {({ values }) => {
          const isCompletingJobCardWithoutSubmittedTesterReport =
            !testersReport.checklist_metadata && values.status === MaintenanceJobCardUpdateStatus.COMPLETED;
          const isCompletingJobCardWithFailedTests =
            testersReport.checklist_metadata &&
            numberOfFailedTests > 0 &&
            values.status === MaintenanceJobCardUpdateStatus.COMPLETED;

          return (
            <Form>
              {jobCardDetails[0]?.status === 'paused' ? (
                <Flex aria-labelledby="my-radio-group" direction="column" gap={4} p={5} role="group">
                  <label>
                    <Field type="radio" name="status" value="ongoing" />
                    <Box as="span" ml={5}>
                      Ongoing
                    </Box>
                  </label>
                </Flex>
              ) : (
                <Flex aria-labelledby="my-radio-group" direction="column" gap={4} p={5} role="group">
                  <label>
                    <Field type="radio" name="status" value="completed" />
                    <Box as="span" ml={5}>
                      Completed
                    </Box>
                  </label>
                  <label>
                    <Field type="radio" name="status" value="awaiting_parts" />
                    <Box as="span" ml={5}>
                      Awaiting Parts
                    </Box>
                  </label>

                  <label>
                    <Field type="radio" name="status" value="awaiting_maintenance" />
                    <Box as="span" ml={5}>
                      Awaiting Maintenance
                    </Box>
                  </label>
                  <label>
                    <Field type="radio" name="status" value="completed" />
                    <Box as="span" ml={5}>
                      Completed
                    </Box>
                  </label>
                  {jobCardDetails[0]?.status === MaintenanceJobCardUpdateStatus.READY_TO_TEST && (
                    <label>
                      <Field type="radio" name="status" value="ongoing" />
                      <Box as="span" ml={5}>
                        Ongoing
                      </Box>
                    </label>
                  )}
                  <label>
                    <Field type="radio" name="status" value="paused" />
                    <Box as="span" ml={5}>
                      Paused
                    </Box>
                  </label>
                  {values.status === MaintenanceJobCardUpdateStatus.PAUSED && (
                    <Field as={Select} name="reason">
                      {getPauseReasonsData?.map((reason) => (
                        <option key={nanoid()} value={reason}>
                          {reason}
                        </option>
                      ))}
                    </Field>
                  )}
                  {values.status === 'pending' && (
                    <Field as={Select} name="reason">
                      {getPendingReasonsData?.map((reason) => (
                        <option key={nanoid()} value={reason}>
                          {reason}
                        </option>
                      ))}
                    </Field>
                  )}
                </Flex>
              )}

              <Flex justifyContent="space-between" mt={8} px={5}>
                <Button
                  bgColor="#FFCC00"
                  borderRadius="4px"
                  fontSize="0.8125rem"
                  isDisabled={
                    isLoading || isCompletingJobCardWithoutSubmittedTesterReport || isCompletingJobCardWithFailedTests
                  }
                  px={6}
                  py={2}
                  type="submit"
                >
                  Change
                </Button>

                <Button bgColor="#EEEEEE" borderRadius="4px" fontSize="0.8125rem" onClick={onClose} px={6} py={2}>
                  CANCEL
                </Button>
              </Flex>
              {isCompletingJobCardWithoutSubmittedTesterReport && (
                <Text color="red.500" fontSize="0.8125rem" fontWeight="bold" mt={3} px={5}>
                  QA Tester report is yet to be submitted
                </Text>
              )}
              {isCompletingJobCardWithFailedTests && (
                <Text color="red.500" fontSize="0.8125rem" fontWeight="bold" mt={3} px={5}>
                  Please resolve all failed tests before completing job card
                </Text>
              )}
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
