import React from 'react';
import { format } from 'date-fns';

export function yes(o: any) {
  let b = typeof o !== 'undefined' && o != null && !!o;
  if (o && typeof o === 'string') {
    b = b && o !== '';
  }

  return b;
}

export function no(o: any) {
  return !yes(o);
}

export function isEmpty(subject: any): boolean {
  if (no(subject)) {
    return true;
  }

  switch (true) {
    case typeof subject === 'string':
      return no(subject);
    case Array.isArray(subject):
      return subject.length === 0;
    case typeof subject === 'object':
      return Object.keys(subject).length === 0;
    case typeof subject === 'number':
      return subject === 0;
    default:
      return no(subject);
  }
}

/**
 * Allows a React component's state property to be set
 * @param newState
 * @param fn
 */
export function setStatePromisified<T>(newState: T, fn: React.Dispatch<React.SetStateAction<T>>): Promise<T> {
  return new Promise<any>((resolve) => {
    fn((prevState) => {
      prevState = newState;
      resolve(prevState); // <- This allows the new state to be captured for use as the result of a Promise.
      return prevState; // <- This allows the state to be set in React.
    });
  });
}

export function is_string(o: any): boolean {
  return typeof o === 'string';
}

export function is_object(o: any): boolean {
  return typeof o === 'object';
}

export const JSONToCSVConvertor = (JSONData: any, ReportTitle: any, ShowLabel: any) => {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  const arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;

  let CSV = '';

  let dateDownload = `${ReportTitle} report as at ` + format(new Date(), 'hh:mma EEEE dd-MM-yyyy');
  CSV += dateDownload + `\r\n\n`;
  //This condition will generate the Label/Header
  if (ShowLabel) {
    let row = '';

    //This loop will extract the label from 1st index of on array
    for (const index in arrData[0]) {
      //Now convert each value to string and comma-seprated
      row += index + ',';
    }

    row = row.slice(0, -1);

    //append Label row with line break
    CSV += row + '\r\n';
  }

  //1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = '';

    //2nd loop will extract each column and convert it in string comma-seprated
    for (const index in arrData[i]) {
      row += '"' + arrData[i][index] + '",';
    }

    row.slice(0, row.length - 1);

    //add a line break after each row
    CSV += row + '\r\n';
  }

  if (CSV === '') {
    alert('Invalid data');
    return;
  }

  //Generate a file name
  let fileName = 'VAMS_Report_';
  //this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, '_');

  //Initialize file format you want csv or xls
  const uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  const link = document.createElement('a');
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  // link.style = "visibility:hidden";
  link.download = fileName + '.csv';

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function capitalizeWord(str: string) {
  return str[0].toUpperCase() + str.substring(1).toLowerCase();
}

export const capitalizeWords = (words: string): string => {
  return words
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
    .join(' ');
};

export function AllUpperCaseWord(str: string) {
  return str.toUpperCase();
}

export function allCapsFirstWord(words: any): any {
  return words.split(' ')[0].toUpperCase();
}

export const getVehicleType = (vehicle_type_id: number | string) => {
  vehicle_type_id = Number(vehicle_type_id);
  if (!vehicle_type_id) return 'N/A';
  if (vehicle_type_id === 1 || vehicle_type_id === 5) return '2 Wheeler';
  if (vehicle_type_id === 2 || vehicle_type_id === 6) return '3 Wheeler';
  if (vehicle_type_id === 3 || vehicle_type_id === 4 || vehicle_type_id === 7) return '4 Wheeler';
};
