import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Divider, Flex, Grid, GridItem, Spacer, Square, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

type ContractDetailsBlockProps = {
  isLoading: boolean;
  title: string;
  trancheTitle: string;
  vehicleID: string;
  currentContractInfo: any;
};

export const ContractDetailsBlock: FC<ContractDetailsBlockProps> = ({
  currentContractInfo,
  isLoading,
  title,
  trancheTitle,
  vehicleID
}) => {
  const contractDetailsHeader = [
    {
      title: 'Daily Remittance',
      value: currentContractInfo[0]?.daily_remit
    },
    {
      title: 'Total HP Amount',
      value: currentContractInfo[0]?.hp_value
    }
  ];

  const trancheDetails = [
    {
      title: 'Duration (Days)',
      value: currentContractInfo[0]?.duration_in_days
    },
    {
      title: 'Days Remaining',
      value: currentContractInfo[0]?.days_remaining
    },
    {
      title: 'Days Elapsed',
      value: currentContractInfo[0]?.days_elapsed
    }
  ];

  return (
    <>
      <Text fontSize="0.75rem" color="#808080" px={4} py={2}>
        {title}
      </Text>
      <Divider />
      <Grid templateColumns="repeat(2, 1fr)" gap={3} px={4} py={2}>
        {isLoading ? (
          <Text color="rgba(62, 62, 62, 1)" fontSize="0.875rem" fontWeight={600}>
            Loading...
          </Text>
        ) : contractDetailsHeader.every((header) => !header?.value) ? (
          <Text color="rgba(62, 62, 62, 1)" fontSize="0.875rem" fontWeight={600}>
            No Active Contract
          </Text>
        ) : (
          contractDetailsHeader.map((header) => (
            <GridItem w="100%" key={nanoid()}>
              <Text fontSize="0.75rem" color="rgba(62, 62, 62, 0.5)">
                {header.title} (&#x20A6;)
              </Text>
              <Text color="rgba(62, 62, 62, 1)" fontSize="0.875rem" fontWeight={600}>
                {parseInt(header?.value, 10).toLocaleString() ?? 'N/A'}
              </Text>
            </GridItem>
          ))
        )}
      </Grid>
      <Box my={2} mb={4} px={3} py={1}>
        <Box bg="#F7F7F7" borderRadius="md">
          <Text fontSize="0.75rem" color="#808080" px={4} py={2}>
            {trancheTitle}
          </Text>
          <Divider />
          <Flex px={4} py={5}>
            <Box>
              <Text fontSize="0.75rem" fontWeight={600} color="#3E3E3E">
                Tranche 1
              </Text>
              <Text color="rgba(62, 62, 62, 0.5)" fontSize="0.75rem">
                Daily remittance (&#x20A6;)
              </Text>
            </Box>
            <Spacer />
            {isLoading ? (
              <Text color="rgba(62, 62, 62, 1)" fontSize="0.875rem" fontWeight={600}>
                Loading...
              </Text>
            ) : (
              <Text color="rgba(62, 62, 62, 1)" fontSize="1.5rem" fontWeight={700}>
                {parseInt(currentContractInfo[0]?.daily_remit, 10).toLocaleString() ?? 'N/A'}
              </Text>
            )}
          </Flex>
          <Box px={4}>
            <Divider />
          </Box>
          <Grid templateColumns="repeat(3, 1fr)" gap={3} px={4} py={2}>
            {isLoading ? (
              <Text color="rgba(62, 62, 62, 1)" fontSize="0.875rem" fontWeight={600}>
                Loading...
              </Text>
            ) : (
              trancheDetails.map((detail) => (
                <GridItem w="100%" key={nanoid()}>
                  <Text fontSize="0.75rem" color="rgba(62, 62, 62, 0.5)">
                    {detail.title}
                  </Text>
                  <Text color="rgba(62, 62, 62, 1)" fontSize="0.875rem" fontWeight={600}>
                    {detail.value ? parseInt(detail.value, 10).toLocaleString() : 'N/A'}
                  </Text>
                </GridItem>
              ))
            )}
          </Grid>
        </Box>
      </Box>
      <Divider />
      <Square>
        <Link to={`/vehicle-activity/vehicle/detail/${vehicleID}#2`}>
          <Text
            as="u"
            color="rgba(232, 142, 21, 1)"
            fontSize="0.75rem"
            textAlign="center"
            py={2}
            fontWeight={600}
            _hover={{ cursor: 'pointer' }}
          >
            SEE ALL PAST CONTRACTS
          </Text>
        </Link>
      </Square>
    </>
  );
};
