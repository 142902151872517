import { FC } from 'react';
import { Flex, Spinner, Text } from '@chakra-ui/react';

type RenderLoaderProps = {
  description?: string;
  iconWidth?: number | string;
  iconHeight?: number | string;
};

export const RenderLoader: FC<RenderLoaderProps> = ({ description = 'Loading...', iconWidth, iconHeight }) => {
  return (
    <Flex direction="column" justify="center" align="center" py={10} gap={4}>
      <Spinner w={iconWidth} h={iconHeight} color="hsla(0, 0%, 0%, 0.1)" />
      <Text color="hsla(0, 0%, 44%, 1)" mb={5} size="md" textAlign="center" w={300}>
        {description}
      </Text>
    </Flex>
  );
};
