import Select, { components } from 'react-select';
import classNames from 'classnames';

import { FormErrorMessage } from '@chakra-ui/react';
import { MdOutlineKeyboardDoubleArrowDown } from 'react-icons/md';

export enum DropDownSize {
  md,
  sm
}

interface FormatOptionLabelProps {
  value: string;
  label: string;
}
const formatOptionLabel = ({ value, label }: FormatOptionLabelProps) => (
  <div className="flex items-center">
    <div className="flex flex-col">{label}</div>
  </div>
);

export default function DropDownSelect({
  size,
  label,
  placeholder = 'Select',
  options,
  disabled = false,
  loading = false,
  field,
  errors,
  storeValue
}: dropDownSelectProps) {
  var selectClass = classNames(['react-select-container'], {
    'react-select-container-lg': size === DropDownSize.md,
    'react-select-container-error': !!errors
  });

  const handleChange = (selectedOption: { value: string }) => {
    storeValue(selectedOption.value);
  };

  return (
    <div>
      {label && (
        <label
          className={`block text-black-50 mb-3 pl-[.8rem] ${
            size === DropDownSize.md ? 'font-[400] text-[1.4rem]' : ''
          }`}
        >
          {label}
        </label>
      )}
      {!storeValue ? (
        <Select
          isLoading={loading}
          isDisabled={disabled}
          {...field}
          options={options}
          classNamePrefix="react-select"
          placeholder={placeholder}
          components={{ DropdownIndicator }}
          unstyled
          className={selectClass}
          formatOptionLabel={formatOptionLabel}
        />
      ) : (
        <Select
          isLoading={loading}
          isDisabled={disabled}
          {...field}
          options={options}
          classNamePrefix="react-select"
          placeholder={placeholder}
          components={{ DropdownIndicator }}
          unstyled
          className={selectClass}
          formatOptionLabel={formatOptionLabel}
          onChange={handleChange}
        />
      )}

      {errors && (
        <FormErrorMessage as="div">
          <span className="font-[400] text-[1.2rem]">{errors}</span>
        </FormErrorMessage>
      )}
    </div>
  );
}

type dropDownSelectProps = {
  field?: any;
  label?: string;
  size?: DropDownSize;
  placeholder: string;
  disabled?: boolean;
  loading?: boolean;
  errors?: string;
  options?: {
    value: string;
    label: string;
  }[];
  storeValue?: any;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className="text-yellow mr-3">
        <MdOutlineKeyboardDoubleArrowDown />
      </div>
    </components.DropdownIndicator>
  );
};
