import ReactPaginate from 'react-paginate';
import style from './pagination.module.scss';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const Pagination = ({ page, setPage, pageCount = 1200 }) => {
  const handleSetPage = ({ selected }) => {
    setPage(selected + 1);
  };

  return (
    <div className={style['pagination-wrapper']}>
      <div className={style['pages-count']}>
        {page} of {pageCount} results
      </div>
      <ReactPaginate
        marginPagesDisplayed={0}
        previousLabel={<ChevronLeftIcon color={'#fff'} />}
        nextLabel={<ChevronRightIcon color={'#fff'} />}
        onPageChange={handleSetPage}
        pageRangeDisplayed={2}
        pageCount={Math.ceil(pageCount)}
        pageLinkClassName={style['pagination-btn']}
        activeClassName={style['active-btn']}
        disabledClassName={style['disabled-btn']}
        containerClassName={style['wrapper']}
        previousClassName={style['prev-btn']}
        nextClassName={style['next-btn']}
        forcePage={page - 1}
      />
    </div>
  );
};

export default Pagination;

Pagination.defaultProps = {
  page: 1,
  pageCount: 20,
  setPage: () => {}
};
