export default function ListItem({
  leading,
  trailing,
  trailingStyle,
  mainTitle,
  subTitle,
  mainTitleStyle,
  subTitleStyle,
  centered = false,
  appendClass,
  appendParentClass
}: ListItemProps) {
  return (
    <div className={`flex justify-between w-full ${appendParentClass ? appendParentClass : ''}`}>
      <div className={`flex items-center w-full ${appendClass ? appendClass : ''}`}>
        {leading && <div>{leading}</div>}
        <div className={`flex flex-col w-full ${centered ? 'items-center' : ''}`}>
          <span className={mainTitleStyle}>{mainTitle}</span>
          <span className={subTitleStyle}>{subTitle}</span>
        </div>
      </div>
      {trailing && <div className={`flex ${trailingStyle ? trailingStyle : ''}`}>{trailing}</div>}
    </div>
  );
}

type ListItemProps = {
  leading?: JSX.Element;
  mainTitle?: string | number | JSX.Element;
  mainTitleStyle?: string;
  subTitleStyle?: string;
  subTitle?: string | number | JSX.Element;
  trailing?: JSX.Element;
  trailingStyle?: string;
  centered?: boolean;
  appendClass?: string;
  appendParentClass?: string;
};
