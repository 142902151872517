import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import { getFirestore, collection, query, onSnapshot } from 'firebase/firestore';
import { Avatar, Box, Button, Container, Flex, Heading, StackDivider, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';

import { firebaseConfig } from 'config/firebase';
import { postRequest, useMutationWrapper } from 'services/apiHelper';
import { vehicleAssignment } from 'services/request';

import FilterContainer from 'components/filter-container/filter-container.component';
import PageWrapper from 'components/page-wrapper';

const Notification = () => {
  const [pendingVehicles, setPendingVehicles] = useState([]);
  const [currentId, setCurrentId] = useState('');

  const getVehiclesFromFirebase = async () => {
    const firebaseApp = initializeApp(firebaseConfig);
    const db = getFirestore(firebaseApp);
    const VEHICLE_STATUS = 'vehicle_assignment_operation';
    const pricedRef = collection(db, VEHICLE_STATUS);
    const pricedQuery = query(pricedRef);
    onSnapshot(pricedQuery, (querySnapshot) => {
      const pricedVeh: any = [];
      querySnapshot.forEach((doc: any) => {
        pricedVeh.push({ id: doc.id, ...doc.data() });
      });
      setPendingVehicles(pricedVeh);
    });
  };

  useEffect(() => {
    getVehiclesFromFirebase();
  }, []);

  const LoginSuccess = () => {
    toast.success('We are processing your request...');
  };

  const { mutate, isLoading } = useMutationWrapper(postRequest, LoginSuccess);

  const handleRetry = (vehicle) => {
    const prospect = vehicle.stageInfo['1'];
    setCurrentId(prospect.prospect_id);
    const data = {
      prospect_id: prospect.prospect_id,
      vehicle_id: prospect.vehicle_id,
      health_insurance: prospect.health_insurance,
      driver_license: prospect.driver_license,
      helmet_number: prospect.helmet_number,
      service_id: prospect.service_id
    };
    mutate({
      // url: vehicleAssignment.ASSIGN_VEHICLE,
      url: vehicleAssignment.ASSIGN_VEHICLE + '?retry=true',
      data
    });
  };

  return (
    <>
      <FilterContainer pageName="Notifications" />
      <PageWrapper>
        <Container maxW="680px">
          <Box w="100%" p="4" mt="2" bg="#fff" borderRadius="md">
            <Heading fontSize="24px">Messages</Heading>
            <VStack spacing={4} divider={<StackDivider borderColor="gray.200" />}>
              {pendingVehicles.length === 0 ? (
                <Box mt="2">There is no pending assignment</Box>
              ) : (
                pendingVehicles.map((vehicle: any) => (
                  <Flex key={nanoid()} alignItems="center">
                    <Avatar />
                    <Box mt={4} ml={4}>
                      <Text display="inline">{`Vehicle assignment to prospect with id`}</Text>
                      <Text as="span" fontWeight="bold" ml="2">
                        {vehicle.id}
                      </Text>
                      <Text as="span"> {vehicle.status === 'pending' ? 'is' : 'has'} </Text>
                      <Text as="span" color={vehicle.status === 'failed' ? 'orangered' : 'green'}>
                        {vehicle.status}
                      </Text>
                      <Flex>
                        <Text color="#8d99ae" fontSize="14px">
                          Assignment by:{' '}
                        </Text>

                        {vehicle?.stageInfo && (
                          <Text ml="2" textTransform="capitalize" color="#8d99ae" fontSize="14px">
                            {' '}
                            {`
                        ${vehicle?.stageInfo['1'].loggedInUser.first_name}
                        ${vehicle?.stageInfo['1'].loggedInUser.last_name}
                        `}
                          </Text>
                        )}
                      </Flex>
                      <Flex mt="2">
                        <Text fontSize="14px" color="#65676b">
                          Created on:
                        </Text>
                        <Text fontSize="14px" ml="2" color="#65676b">
                          {' '}
                          {format(new Date(vehicle.created_at), 'dd-MMM-yyyy')}{' '}
                        </Text>
                      </Flex>
                      {vehicle.status === 'failed' && (
                        <Button
                          isLoading={currentId === vehicle.id && isLoading}
                          isDisabled={isLoading && currentId === vehicle.id}
                          w="200px"
                          h="36px"
                          onClick={() => handleRetry(vehicle)}
                        >
                          {' '}
                          Retry
                        </Button>
                      )}
                    </Box>
                  </Flex>
                ))
              )}
            </VStack>
          </Box>
        </Container>
      </PageWrapper>
    </>
  );
};

export default Notification;
