import { Input, InputGroup } from '@chakra-ui/react';

type SearchBoxProps = {
  placeholder?: string;
  width?: string;
  height?: string;
  onChange: (event) => void;
  value?: string;
  disabled?:boolean,
  padding?: string;
};
const SearchBoxTechinicianAndQA = (props: SearchBoxProps) => {
  
  const { placeholder = 'Search...', width = '100%', height = '38px', onChange, value, disabled,padding="40px" } = props;
  
  return (
    <InputGroup>
      <Input
        placeholder={placeholder}
        width={width}
        height={height}
        paddingLeft={padding}
        fontSize="13px"
        onChange={onChange}
        value={value}
        _focus={{
          outline: 'none'
        }}
        disabled={disabled}
      />
      
    </InputGroup>
  );
};

export default SearchBoxTechinicianAndQA;
