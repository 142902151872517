import { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Center, Flex, Td, Tr } from '@chakra-ui/react';
// import { CSVLink } from 'react-csv';
import { useQuery } from 'react-query';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import { maintenanceRequest, reportRequest } from 'services/request';
import { getVehicleType } from 'util/index';
import axiosInstance from 'services/httpService';
import { QUERY_KEYS } from 'util/constants';
import { PROTECTED_PATHS } from 'routes/constants';
import { _status_options } from 'constants/index';
import { MaintenanceTableColumn } from './constants';

// import { ReactComponent as Excel } from 'assets/images/excel.svg';

import 'react-datepicker/dist/react-datepicker.css';

import FilterContainer from 'components/filter-container/filter-container.component';
import PageWrapper from 'components/page-wrapper';
import { MaintenanceSummary } from 'components/Maintenance/MaintenanceSummary';
import { SearchFilterWrapper } from 'components/UiElements/SearchFilter';
import { SelectDropdown } from 'components/UiElements/SelectDropdown/select-dropdown';
import VehicleDetail from 'components/UiElements/VehicleDetail';
import Pagination from 'components/UiElements/Pagination/Pagination';
import { StatusDisplay } from 'components/StatusDisplay/StatusDisplay';
import { Table } from 'components/Maintenance/Table';

export const Maintenance: FC = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(25);
  const [startDate, setStartDate] = useState<any>('');
  const [endDate, setEndDate] = useState<any>('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filterLocation, setFilterLocation] = useState('');
  const [scheduled, setScheduled] = useState('');
  const [unscheduled, setUnscheduled] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setPageSizes] = useState(10);
  const [scheduledCount, setScheduledCount] = useState(0);
  const [unscheduledCount, setUnscheduledCount] = useState(0);
  const [totalMaintenanceCount, setTotalMaintenanceCount] = useState(0);

  const navigate = useNavigate();

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getAllLocations = async () => {
    const url = reportRequest.ALL_SUB_CITIES;
    const data = await axiosInstance.get(url);
    return data?.data;
  };

  const { data: locationData }: any = useQuery(['locations'], getAllLocations);

  const _location_data =
    locationData &&
    locationData?.payload?.message?.objects?.map((el) => ({
      label: el.name,
      value: el.id
    }));

  useEffect(() => {
    if (searchQuery) {
    } else {
    }
  }, [searchQuery]);

  // get all vehicles due for maintenance
  const getAllVehicles = async (page) => {
    try {
      const url = `${maintenanceRequest.ALL_MAINTENANCE_VEHICLES}?page=${page}&size=${rowsPerPage}${
        scheduled && `&scheduled=${scheduled}`
      }${unscheduled && `&unscheduled=${unscheduled}`}${filterLocation && `&location=${filterLocation}`}${
        searchQuery && `&search_query=${searchQuery}`
      }${startDate && `&start_date=${format(startDate, 'yyyy-MM-dd')}`}${
        endDate && `&end_date=${format(endDate, 'yyyy-MM-dd')}`
      }`;

      const res = await axiosInstance.get(url);

      setPageCount(res?.data?.data?.pagination?.page_count);
      setCurrentPage(res?.data?.data?.pagination?.current_page);
      setPageSizes(res?.data?.data?.pagination?.size);
      setScheduledCount(res?.data?.data?.scheduledCount);
      setUnscheduledCount(res?.data?.data?.unscheduledCount);
      setTotalMaintenanceCount(res?.data?.data?.pagination?.total);

      return res?.data?.data?.results;
    } catch (err) {
      console.error('All maintenance vehicles::', err);
    }
  };

  const getAllDueForMaintenanceCounts = async () => {
    try {
      const url = `${maintenanceRequest.DUE_FOR_MAINTENANCE_COUNT}`;
      const res = await axiosInstance.get(url);
      return res?.data?.data?.total;
    } catch (err) {
      console.error(err);
    }
  };

  const { data: vehicleData, isLoading }: any = useQuery(
    [QUERY_KEYS.MAINTENANCE, page, filterLocation, scheduled, unscheduled, searchQuery, startDate, endDate],
    () => getAllVehicles(page)
  );

  const { data: dueForMaintenanceCounts }: any = useQuery(
    QUERY_KEYS.DUE_FOR_MAINTENANCE_COUNTS_MAINTENANCE_INDEX,
    getAllDueForMaintenanceCounts
  );

  const maintenanceSummaryIndexData = [
    { name: 'Scheduled', value: scheduledCount, navlink: PROTECTED_PATHS.SCHEDULED_MAINTENANCE },
    {
      name: 'Unscheduled',
      value: unscheduledCount,
      navlink: PROTECTED_PATHS.UNSCHEDULED_MAINTENANCE
    },
    {
      name: 'Due for Maintenance',
      value: dueForMaintenanceCounts ?? 0,
      navlink: PROTECTED_PATHS.DUE_FOR_MAINTENANCE
    }
  ];

  return (
    <>
      <FilterContainer pageName="Maintenance" />
      <PageWrapper>
        <Flex mt={5}>
          <Box w="300px">
            <MaintenanceSummary
              title="Total Vehicles Checkin"
              totalVehicles={totalMaintenanceCount}
              indexData={maintenanceSummaryIndexData}
            />
          </Box>
          <Box height="700px" overflow="hidden" position={'relative'} background="#fff" width="100%" ml="4">
            <Box height="100%" paddingBottom="81px" position="relative" overflow="scroll">
              <SearchFilterWrapper
                filterTitle=""
                searchPlaceholder="Search for something"
                setSearchQuery={setSearchQuery}
                title="Vehicle Checkin"
                toggleBlock={<></>}
                filterBlock={
                  <form className="table-filter-form">
                    <div className="strip-select-style">
                      <SelectDropdown
                        handleChange={(e) => {
                          setFilterLocation(e?.value);
                        }}
                        isClearable
                        name=""
                        options={_location_data}
                        placeholder="Location"
                      />
                    </div>

                    <div className="strip-select-style">
                      <SelectDropdown
                        handleChange={(e) => {
                          if (e?.value === 'scheduled') {
                            setScheduled(e?.value);
                            setUnscheduled('');
                          }
                          if (e?.value === 'unscheduled') {
                            setUnscheduled(e?.value);
                            setScheduled('');
                          }
                          return '';
                        }}
                        isClearable
                        name=""
                        options={_status_options}
                        placeholder="Status"
                      />
                    </div>
                    <div className="filter-date-range">
                      {/* <Date */}
                      <DatePicker
                        isClearable
                        selected={startDate}
                        onChange={onDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        placeholderText="Select A Date"
                        // inline
                      />

                      {/* <DatePicker selected={endDate} onChange={(date: Date) => setStartDate(date)} /> */}
                    </div>
                  </form>
                }
                exportBlock={
                  <button>
                    {/* <CSVLink filename="maintenance-vehicle-list.csv" data={vehicleData || ''}>
                      <Excel style={{ width: '14px' }} /> <span>EXPORT TO CSV</span>
                    </CSVLink> */}
                  </button>
                }
              />
              {isLoading ? (
                <Center pt={5}>Loading...</Center>
              ) : (
                <Table
                  columns={MaintenanceTableColumn}
                  tableData={vehicleData?.map((data) => (
                    <Tr
                      cursor="pointer"
                      fontSize="12px"
                      onClick={() => navigate(`/maintenance/vehicle/${data?.id}`)}
                      key={data?.id}
                    >
                      <Td>
                        <VehicleDetail
                          max_vehicle_id={data?.vehicle?.max_vehicle_id}
                          first_name={data?.champion?.first_name}
                          last_name={data?.champion?.last_name}
                          vehicle_type_id={getVehicleType(data?.vehicle?.model?.vehicle_type_id)}
                        />
                      </Td>
                      <Td>{data?.vehicle_plate_number}</Td>
                      <Td>{data?.vehicle?.trim?.name}</Td>
                      <Td>{data?.status}</Td>
                      <Td>{data?.odometer?.toFixed(2)}</Td>
                      <Td>
                        <StatusDisplay fontSize="0.75rem" status={data?.status} />
                      </Td>
                    </Tr>
                  ))}
                />
              )}
            </Box>
            {vehicleData?.length > 10 && (
              <Box background={'#fff'} bottom={0} width="100%" position="absolute">
                <Pagination page={currentPage} pageCount={pageCount} setPage={setPage} />
              </Box>
            )}
          </Box>
        </Flex>
      </PageWrapper>
    </>
  );
};

Maintenance.defaultProps = {
  scheduled_data: []
};
