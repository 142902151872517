import { PUBLIC_PATHS } from './constants';
import { AppRoute } from './types';
import Login from '../pages/login/Login';


const { LOGIN, FORGOT_PASSWORD } = PUBLIC_PATHS

export const PUBLIC_ROUTES: AppRoute[] = [
  { path: LOGIN, element: <Login /> },
  { path: FORGOT_PASSWORD, element: <Login /> },
  { path: "/", element: <Login /> },
  { path: "*", element: <div>Page not found</div> },

]