import { useMemo, useState } from 'react';
import { useTable } from 'react-table';
import NoData from './NoData';
import styled from '@emotion/styled';
import SkeletonLoader from 'components/Loaders/SkeletonTableLoader';
type BasicTableProps = {
  data: Array<any>;
  columns: any;
  loading: boolean;
  selectedRow?: (rowValue: any) => void;
  activeTab?: Record<string, any>;
  startIndex?: number
};

const BasicTable = (props: BasicTableProps) => {
  const { data: TData = [], columns: TColumn, loading, selectedRow, startIndex = 1 } = props;
  const columns = useMemo(() => TColumn, [TColumn]);
  const data = useMemo(() => TData, [TData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });
  const [highlightRow, sethighlightRow] = useState<string>('');

  const rowClicked = (currentRow: any) => {
    if (selectedRow) {
      selectedRow(currentRow.original);
      sethighlightRow(currentRow.original.id);
    }
  };
  return (
    <BasicTable.Wrapper>
      {/* <div className="basic-table-div" style={{ position: 'relative' }}> */}
      <table className="" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th>S/N</th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {loading ? <SkeletonLoader colSpan={8} /> : rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => rowClicked(row)}
                className={`${highlightRow === row.original.id ? 'selected-row-class' : ''}`}
                style={{ cursor: 'pointer' }}
              >
                <td>{startIndex + index}</td>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {!loading && data.length === 0 ? <NoData /> : null}
    </BasicTable.Wrapper>
  );
};
BasicTable.Wrapper = styled.div`
  overflow-y: auto;
  width: auto;
  height: 450px;

  table {
    width: 100%;
    background-color:#fff;
    th,
    td {
      padding: 10px 8px;
      text-align: left;
      font-size: 13px;
    }
    th {
      position: sticky;
      background-color: #fbfbfb;
      // z-index: 7;
      top: 0;
    }

    td {
      color: #000000de;
      border-bottom: 1px solid #f2f7fa;
      text-transform: capitalize;
    }
  }
  .selected-row-class {
    background-color: #fffbea;
  }
`;
export default BasicTable;
