import BasicTable from 'components/BasicTable';
import React, { useState } from 'react';
import VehiclesDetails from './vehicle-details.component';
import { format } from 'date-fns';
import { Button } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { convertParamsToString } from 'helpers/auth-helpers';
import { useQuery } from 'react-query';
import axiosInstance from 'services/httpService';
import { vehiclePageRequest } from 'services/request';
const VehicleDetails = () => {
  const [selected, setselected] = useState('first');
  const history = useNavigate();
  const selectedVehicle = JSON.parse(localStorage.getItem('selected') || '{}');
  const { id = '' } = useParams<any>();
  const getVehicles = async () => {
    const url = convertParamsToString(vehiclePageRequest.MOVEMENT_HISTORY, {
      id
    });
    const response = await axiosInstance.get(url);
    return response.data.data;
  };

  const { data, isLoading } = useQuery(['getVehicles', 'vehId'], getVehicles, {
    staleTime: 1000 * 600, // this is in milliseconds
    cacheTime: 10 * 60
  });
  const columns = [
    {
      Header: 'Time stamp',
      accessor: 'created_at',
      Cell: ({ value }: any) => {
        return format(new Date(value), 'dd-MMM-yyyy');
      }
    },
    {
      Header: 'Fleet Agent',
      accessor: 'creator_name'
    },
    {
      Header: 'Odometer',
      accessor: 'odometer'
    },
    {
      Header: 'Location',
      accessor: 'location_name'
    },
    {
      Header: 'Status',
      accessor: 'movement_type',
      Cell: ({ value }: any) => {
        return (
          <p className={`${value === 'entry' ? 'green-pill' : ''} ${value === 'exit' ? 'red-pill' : ''}`}>{value}</p>
        );
      }
    },
    {
      Header: 'Reason',
      accessor: 'reason'
    }
  ];
  return (
    <div className="mt-4">
      <Button variant="unstyled" className="export-vehicle" onClick={() => history('/vehicles')}>
        Back
      </Button>
      <div className="ui top attached tabular menu first">
        <div
          className={`item ${selected === 'first' ? 'active' : ''}`}
          data-tab="first"
          onClick={() => {
            setselected('first');
          }}
        >
          Vehicle Info
        </div>
        <div
          className={`item ${selected === 'second' ? 'active' : ''}`}
          data-tab="second"
          onClick={() => {
            setselected('second');
          }}
        >
          History
        </div>
      </div>
      <div className={`ui bottom attached tab segment ${selected === 'first' ? 'active' : ''}`} data-tab="first">
        <VehiclesDetails onEdit={(params) => {}} vehicleObj={selectedVehicle} />
      </div>
      <div className={`ui bottom attached tab segment ${selected === 'second' ? 'active' : ''}`} data-tab="second">
        <BasicTable data={data || []} columns={columns} loading={isLoading} />
      </div>
    </div>
  );
};

export default VehicleDetails;
