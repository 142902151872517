import { KEY_LS_MAX_USER_VAMS } from './constants';

export const getUserRole = () => {
  const userObj = localStorage.getItem(KEY_LS_MAX_USER_VAMS);
  if (userObj) {
    return JSON.parse(userObj).role.name;
  } else {
    localStorage.clear();
    window.location.href = '/login';
  }
};

export const getUserRoleLocation = (): string => {
  const userObj = localStorage.getItem(KEY_LS_MAX_USER_VAMS);
  if (!userObj) return '';

  return JSON.parse(userObj).role.city_id;
};

export enum USER_ROLES {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  AGENT = 'agent',
  FLEET_OFFICER = 'fleet_officer',
  FLEET_OFFICER_MOVEMENT = 'fleet_officer_movement',
  FLEET_OFFICER_MAINTENANCE = 'fleet_officer_maintenance',
  VEHICLE_MANAGER = 'vehicle_manager',
  BULK_UPLOAD_MANAGER = 'bulk_upload_manager'
}
