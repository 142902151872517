/* eslint-disable react/jsx-no-undef */
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { ReactComponent as MegaphoneIcon } from 'assets/images/Icon ionic-md-megaphone.svg';
import UserIcon from 'assets/images/helmet-md.svg';
import AgentIcon from 'assets/images/max-agent-image.svg';
import Badge, { BadgeState, BadgeStyle } from 'components/badge/Badge';
import CardTitle from 'components/card/card-title';
import CustomInput from 'components/input';
import ListItem from 'components/list-items/LisItem';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { MdOutlineHighlightOff } from 'react-icons/md';
import { PiNote } from 'react-icons/pi';
import { useQuery } from 'react-query';
import axiosInstance from 'services/httpService';
import { incidentBadgeStyle, removeUnderScores, ucFirst } from 'util/ui-helpers';
import UpdateTicketModal from './update-ticket-modal';
import CustomButton, { ButtonStates } from 'components/buttons/Button';
export default function CallScriptModal({ isOpen, onClose, ticketId, ticketSlug, refetch }: InciddentDetailsProps) {
  const [showUpdateTicket, setShowUpdateTicket] = useState<boolean>(false);

  const getMethod = async ({ queryKey }) => {
    let { route, params } = queryKey[1];
    try {
      const { data } = await axiosInstance.get(`${route}`, {
        params
      });
      return data;
    } catch (error) {
      throw error;
    }
  };
  const { data: category } = useQuery(
    [
      'get-category',
      {
        route: `${process.env.REACT_APP_TMS_BASE_URL}/category/${ticketSlug}`,
        params: {}
      }
    ],
    getMethod,
    { enabled: Boolean(ticketSlug) }
  );

  const categoryInfo = category?.data;

  const { data: ticket } = useQuery(
    [
      'get-ticket',
      {
        route: `${process.env.REACT_APP_TMS_BASE_URL}/ticket/${ticketId}`,
        params: {}
      }
    ],
    getMethod,
    { enabled: Boolean(ticketId) }
  );

  const ticketInfo = ticket?.data;
  const incidentInfo = [
    {
      title: 'Location',
      value: `${ticketInfo?.location || 'N/A'}`
    },
    {
      title: 'Date of incident',
      value: `${ticketInfo ? format(new Date(ticketInfo?.created_at), 'dd MMMM yyyy') : 'N/A'}`
    },
    {
      title: 'Time of incident',
      value: `${ticketInfo ? format(parseISO(ticketInfo?.created_at), 'hh:mm a') : 'N/A'}`
    },
    {
      title: 'Reporter',
      value: `${ticketInfo?.reporter || 'N/A'}`
    },
    {
      title: 'Vehicle Plate',
      value: `${ticketInfo?.plate_number || 'N/A'}`
    }
  ];

  const ticketStatus = ticketInfo ? ticketInfo?.status : '';

  const setAnswer = (question: string) => {
    return ticketInfo?.call_script?.find((_el: any) => _el.question === question);
  };

  const setMultiSelectAnswer = (question: string, detailQuestion: string) => {
    const multipartQuestion = ticketInfo?.call_script?.find((_el: any) => _el.question === question);

    return multipartQuestion?.answers?.find((_el: any) => _el.question === detailQuestion);
  };

  return (
    <>
      <UpdateTicketModal
        isOpen={showUpdateTicket}
        onClose={() => {
          setShowUpdateTicket(!showUpdateTicket);
        }}
        ticketInfo={ticketInfo}
        ticketId={ticketId}
        refetch={refetch}
      />
      <Modal isCentered blockScrollOnMount={false} onClose={onClose} size={'3xl'} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent className="amortization-schedule-summary-modal">
          <ModalHeader>
            <div className="flex items-end w-[50%]">
              <div className="w-[50%]">
                <ListItem
                  mainTitle={`${ucFirst(ticketInfo?.type) || ''} type`}
                  mainTitleStyle="comm-r-14 text-[#808080] opacity-50"
                  subTitle={`${ucFirst(removeUnderScores(ticketInfo?.category)) || ''}`}
                  subTitleStyle="text-black text-[16px]"
                />
              </div>
              <div className="h-[100%] ">
                <Badge state={incidentBadgeStyle(ticketStatus!)!} badgeStyle={BadgeStyle.box}>
                  {ticketInfo?.status}
                </Badge>
              </div>
            </div>
          </ModalHeader>
          <div className="text-grey01 cursor-pointer">
            <ModalCloseButton as={MdOutlineHighlightOff} />
          </div>
          <ModalBody>
            <div className="flex gap-4">
              <div>
                <div className="max-h-[500px] overflow-y-scroll scrollbar-hidden mt-5">
                  <div className="border border-grey01 rounded-2xl bg-white pb-5">
                    <CardTitle title="Ticket Information" />

                    <div className="grid grid-cols-2 pl-5 pr-4 py-8 gap-y-8">
                      {incidentInfo.map((data, index) => (
                        <ListItem
                          key={index}
                          mainTitle={data.title}
                          mainTitleStyle="comm-r-14 text-black-50"
                          subTitle={data.value!.toLocaleString()}
                          subTitleStyle="comm-m-16 font-bold "
                        />
                      ))}
                    </div>
                  </div>
                  <div className="border border-grey01 rounded-2xl mt-5 bg-white p-5">
                    <ListItem
                      mainTitle={`${ucFirst(ticketInfo?.champion_name) || ''}`}
                      mainTitleStyle="comm-d-14 font-bold text-black"
                      subTitle={`${ticketInfo?.champion_max_id?.toUpperCase() || ''}`}
                      subTitleStyle="comm-m-12 text-grey02"
                      appendParentClass="items-center"
                      leading={
                        <div className="mr-[15px]">
                          <img src={UserIcon} alt="user-icon" />
                        </div>
                      }
                      // trailing={
                      //   <div className="text-[#E88E15] text-center uppercase comm-b-12 w-full hover:underline cursor-pointer">
                      //     View champion
                      //   </div>
                      // }
                      trailingStyle="basis-3/5 justify-end items-center"
                    />
                  </div>
                  <div className="border border-grey01 rounded-2xl mt-5 bg-white pb-5">
                    <CardTitle title="Assigned Agent" />
                    <div className="px-5 pt-[20px] pb-[16px]">
                      <ListItem
                        mainTitle={`${ucFirst(ticketInfo?.agent_name) || ''}`}
                        mainTitleStyle="comm-d-14 font-bold text-black"
                        appendParentClass="items-center"
                        leading={
                          <div className="mr-[15px]">
                            <img src={AgentIcon} alt="user-icon" />
                          </div>
                        }
                        // trailing={
                        //   <div
                        //     className="text-[#E88E15] text-center uppercase comm-b-12 w-full hover:underline cursor-pointer"
                        //     onClick={() =>
                        //       navigate.push(`/agent/${ticketInfo?.agent_id}`)
                        //     }
                        //   >
                        //     View Agent
                        //   </div>
                        // }                        trailingStyle="basis-1/2 justify-end items-center"
                      />
                    </div>
                  </div>
                  <div className="border border-grey01 rounded-2xl mt-5 bg-white pb-5">
                    <CardTitle title="Investigation Report" />
                    <div className="px-5 py-8">
                      <ListItem
                        mainTitle="Added New Reports"
                        mainTitleStyle="comm-d-14 text-black"
                        subTitle="Uploaded 12/09/2023, 14:45"
                        subTitleStyle="comm-m-12 text-grey02"
                        appendParentClass="items-center"
                        leading={
                          <div className="w-[33px] h-[33px] flex items-center justify-center mr-[15px] rounded-[8px] border border-[#eeeeee] bg-[#F4F6FA]">
                            <PiNote className="w-[15.2px] h-[19px] rotate-[180deg]" />
                          </div>
                        }
                        trailingStyle="basis-1/2 justify-end items-center"
                      />
                    </div>
                  </div>
                </div>
                <CustomButton
                  customStyle="justify-center mt-8 !h-[45px] !rounded-[10px] w-[100%] font-bold !text-[12px]"
                  state={ButtonStates.warning}
                  handleClick={() => {
                    setShowUpdateTicket(!showUpdateTicket);
                    onClose();
                  }}
                >
                  UPDATE INCIDENT
                </CustomButton>
              </div>
              <div className="basis-[60%]">
                <div className="border border-grey01 rounded-2xl mt-5 bg-white pb-5">
                  <CardTitle title="Fixed Call Script" />
                  <div className="max-h-[510px] overflow-y-scroll scrollbar-hidden">
                    <div className="px-12 py-8">
                      <div className="">
                        <Badge state={BadgeState.primary} badgeStyle={BadgeStyle.box}>
                          <p className="comm-b-14">{`${ucFirst(removeUnderScores(ticketInfo?.category))}`}</p>
                        </Badge>
                        <div className="py-8 gap-y-[12px] flex flex-col">
                          {categoryInfo?.call_script?.map((question: any, index: number) =>
                            question?.type === 'script-select' ? (
                              <div
                                key={index}
                                className="flex items-start gap-x-[8px] p-[15px_17px] border border-[#dedede] rounded-[18px] bg-white w-[94%]"
                              >
                                <div className="mt-[4px]">
                                  <MegaphoneIcon />
                                </div>
                                <div className="flex flex-col gap-y-[15px]">
                                  <p className="comm-r-14">{question?.question}</p>
                                  <div className="grid grid-cols-2 items-center gap-x-[23px] gap-y-[15px]">
                                    {question?.options?.map((option: any, index: number) => (
                                      <div key={index} className="flex items-center gap-x-[10px]">
                                        <input
                                          type="radio"
                                          data-name="checked"
                                          id="checked"
                                          className="pointer-events-none"
                                          checked={setAnswer(question?.question)?.answer === option?.value}
                                          value={option?.value}
                                          style={{
                                            width: '18px',
                                            height: '18px'
                                          }}
                                        />
                                        <label className="comm-r-14 basis-[100%]">{option?.option}</label>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ) : question?.type === 'script-multipart' ? (
                              <div
                                key={index}
                                onClick={() => {}}
                                className="flex flex-col gap-y-[15px] p-[15px_17px] border border-[#dedede] rounded-[18px] bg-white w-[94%]"
                              >
                                <div className="flex items-center gap-x-[4px]">
                                  <div>
                                    <MegaphoneIcon />
                                  </div>
                                  <p className="comm-r-14">{question?.question}</p>
                                </div>
                                {question?.answers?.map((detail: any, index: number) =>
                                  detail?.question === 'Guarantor Phone Number' ? (
                                    <CustomInput
                                      key={index}
                                      type={'phone'}
                                      label={detail?.question}
                                      value={setMultiSelectAnswer(question?.question, detail?.question)?.answer}
                                    />
                                  ) : (
                                    <CustomInput
                                      key={index}
                                      type={'text'}
                                      label={detail?.question}
                                      value={setMultiSelectAnswer(question?.question, detail?.question)?.answer}
                                    />
                                  )
                                )}
                              </div>
                            ) : question?.type === 'script-date' ? (
                              <div
                                key={index}
                                className="flex flex-col gap-y-[15px] p-[15px_17px] border border-[#dedede] rounded-[18px] bg-white w-[94%]"
                              >
                                <div className="flex items-center gap-x-[4px]">
                                  <div>
                                    <MegaphoneIcon />
                                  </div>
                                  <p className="comm-r-14">{question?.question}</p>
                                </div>

                                <CustomInput type={'date'} value={setAnswer(question?.question)?.answer} />
                              </div>
                            ) : question?.question?.includes('time') ? (
                              <div
                                key={index}
                                className="flex flex-col gap-y-[15px] p-[15px_17px] border border-[#dedede] rounded-[18px] bg-white w-[94%]"
                              >
                                <div className="flex items-center gap-x-[4px]">
                                  <div>
                                    <MegaphoneIcon />
                                  </div>
                                  <p className="comm-r-14">{question?.question}</p>
                                </div>

                                <CustomInput type={'time'} value={setAnswer(question?.question)?.answer} />
                              </div>
                            ) : //TODO: remember to update number type question
                            question?.question?.includes('amount') || question?.question?.includes('many') ? (
                              <div
                                key={index}
                                className="flex flex-col gap-y-[15px] p-[15px_17px] border border-[#dedede] rounded-[18px] bg-white w-[94%]"
                              >
                                <div className="flex items-center gap-x-[4px]">
                                  <div>
                                    <MegaphoneIcon />
                                  </div>
                                  <p className="comm-r-14">{question?.question}</p>
                                </div>

                                <CustomInput
                                  type={'number'}
                                  placeholder={'Enter figure'}
                                  value={setAnswer(question?.question)?.answer}
                                />
                              </div>
                            ) : question?.question?.includes('phone') ? (
                              <div
                                key={index}
                                className="flex flex-col gap-y-[15px] p-[15px_17px] border border-[#dedede] rounded-[18px] bg-white w-[94%]"
                              >
                                <div className="flex items-center gap-x-[4px]">
                                  <div>
                                    <MegaphoneIcon />
                                  </div>
                                  <p className="comm-r-14">{question?.question}</p>
                                </div>

                                <CustomInput
                                  type={'phone'}
                                  customStyle="w-full"
                                  value={setAnswer(question?.question)?.answer}
                                />
                              </div>
                            ) : question?.question === 'What are your details?' ? (
                              <div
                                key={index}
                                className="flex flex-col gap-y-[15px] p-[15px_17px] border border-[#dedede] rounded-[18px] bg-white w-[94%]"
                              >
                                <div className="flex items-center gap-x-[4px]">
                                  <div>
                                    <MegaphoneIcon />
                                  </div>
                                  <p className="comm-r-14">{question?.question}</p>
                                </div>
                                {question?.answers?.map((detail: any, index: number) =>
                                  detail?.question === 'Phone Number' ? (
                                    <CustomInput
                                      key={index}
                                      type={'phone'}
                                      label={detail?.question}
                                      value={setMultiSelectAnswer(question?.question, detail?.question)?.answer}
                                    />
                                  ) : detail?.question?.includes('BVN') ? (
                                    <CustomInput
                                      key={index}
                                      type={'number'}
                                      label={detail?.question}
                                      value={setMultiSelectAnswer(question?.question, detail?.question)?.answer}
                                    />
                                  ) : (
                                    <CustomInput
                                      key={index}
                                      type={'text'}
                                      label={detail?.question}
                                      value={setMultiSelectAnswer(question?.question, detail?.question)?.answer}
                                    />
                                  )
                                )}
                              </div>
                            ) : (
                              <div
                                key={index}
                                className="flex flex-col gap-y-[15px] p-[15px_17px] border border-[#dedede] rounded-[18px] bg-white w-[94%]"
                              >
                                <div className="flex items-center gap-x-[4px]">
                                  <div>
                                    <MegaphoneIcon />
                                  </div>
                                  <p className="comm-r-14">{question?.question}</p>
                                </div>

                                <CustomInput
                                  type={'text'}
                                  // value={setAnswer(question?.question)?.answer}
                                />
                              </div>
                            )
                          )}
                        </div>
                        <div className="border border-grey01 rounded-2xl mt-5 bg-white pb-5">
                          <CardTitle title="Additional Comment" />
                          <div className="px-12 py-8">
                            <p className="comm-r-14 text-black">{ticketInfo?.comment}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

type InciddentDetailsProps = {
  isOpen: boolean;
  onClose: () => void;
  ticketId: string;
  ticketSlug: string;
  status?: string;
  refetch?: any;
};
