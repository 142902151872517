import { convertParamsToString } from 'helpers/auth-helpers';
import axiosInstance, { dashboardRequest } from 'services/httpService'
import {reportRequest} from 'services/httpService'

export const getFilteredVehicleCount = async (subcity, vehicleType, startDate,endDate, platform, manufacturer, city) => {
  const url = convertParamsToString(reportRequest.ALL_VEHICLES_COUNT, {
    subcity,
    vehicleType,
    startDate,
    endDate,
    platform,
    manufacturer,
    city
  });
  const res = await axiosInstance.get(url);
  return res.data;
}


export const getFirebaseVehicleCount = async (params) => {
  const {queryKey} = params;
  const url = convertParamsToString(reportRequest.FIREBASE_VEHICLE_COUNT, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1] ,
    endDate: queryKey[2] ,
    platform: queryKey[5] ,
    manufacturer: queryKey[6],
    city:queryKey[7] ||""
  });
  const res = await axiosInstance.get(url);
  return res.data;
}

export const getFireBaseActiveVehicles = async (params) => {
  const {queryKey} = params;
  const url = convertParamsToString(reportRequest.FIREBASE_ACTIVE_VEHICLES, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page:queryKey[5],
    pageSize:queryKey[6],
    platform:queryKey[7],
    manufacturer:queryKey[8],
    city:queryKey[9]
  });
  const res = await axiosInstance.get(url);
  return res.data;
}

export const getFireBaseMissingVehicles = async (params) => {
  const {queryKey} = params;
  const url = convertParamsToString(reportRequest.FIREBASE_MISSING_VEHICLES, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page:queryKey[5],
    pageSize:queryKey[6],
    platform:queryKey[7],
    manufacturer:queryKey[8],
    city:queryKey[9]
  });
  const res = await axiosInstance.get(url);
  return res.data;
}

export const getFireBaseInactiveVehicles = async (params) => {
  const {queryKey} = params;
  const url = convertParamsToString(reportRequest.FIREBASE_INACTIVE_VEHICLES, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page:queryKey[5],
    pageSize:queryKey[6],
    platform:queryKey[7],
    manufacturer:queryKey[8],
    reason:queryKey[9],
    city:queryKey[10]
  });
  const res = await axiosInstance.get(url);
  return res.data;
}

export const getFireBaseScrappedVehicles = async (params) => {
  const {queryKey} = params;
  const url = convertParamsToString(reportRequest.FIREBASE_SCRAPPED_VEHICLES, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page:queryKey[5],
    pageSize:queryKey[6],
    platform:queryKey[7],
    manufacturer:queryKey[8],
    city:queryKey[9]
  });
  const res = await axiosInstance.get(url);
  return res.data;
}

export const getFireBaseUnremittedVehicles = async (params) => {
  const {queryKey} = params;
  const url = convertParamsToString(reportRequest.FIREBASE_NOTREMITTED_VEHICLES, {
    subcity: queryKey[2],
    vehicleType: queryKey[3],
    days: queryKey[1],
    page: queryKey[4],
    pageSize: queryKey[5],
    platform: queryKey[6],
    manufacturer: queryKey[7],
    city:queryKey[8]
  });
  const res = await axiosInstance.get(url);
  return res.data;
}

export const getFireBaseCheckinVehicles = async (params) => {
  const {queryKey} = params;
  const url = convertParamsToString(reportRequest.FIREBASE_CHECKIN_VEHICLES, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page:queryKey[5],
    pageSize:queryKey[6],
    platform:queryKey[7],
    manufacturer:queryKey[8],
    city:queryKey[9]
  });
  const res = await axiosInstance.get(url);
  return res.data;
}

export const getFireBaseCheckedoutVehicles = async (params) => {
  const {queryKey} = params;
  const url = convertParamsToString(reportRequest.FIREBASE_CHECKEDOUT_VEHICLES, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page:queryKey[5],
    pageSize:queryKey[6],
    platform:queryKey[7],
    manufacturer:queryKey[8],
    city:queryKey[9]
  });
  const res = await axiosInstance.get(url);
  return res.data;
}
export const getFilteredHpComplete = async (subcity, vehicleType, startDate, endDate, page, pageSize, city, platform, manufacturer) => {
  const url = convertParamsToString(reportRequest.FIREBASE_HP_COMPLETE, {
    subcity,
    vehicleType,
    startDate,
    endDate,
    page,
    pageSize,
    platform,
    city,
    manufacturer
  });
  const res = await axiosInstance.get(url);
  return res.data;
}
export const getFirebasehpCompleteVehicles = async (params) => {
  const {queryKey} = params;
  const url = convertParamsToString(reportRequest.FIREBASE_HP_COMPLETE, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page:queryKey[5],
    pageSize:queryKey[6],
    platform:queryKey[7],
    manufacturer:queryKey[8],
    city:queryKey[9]
  });
  const res = await axiosInstance.get(url);
  return res.data;
}

export const getFilteredNonRemitted =  async (subCity, vehicleType, startDate, endDate,platform, manufacturer, city) => {
  const url = convertParamsToString(reportRequest.ONLY_ACTIVE_NOT_REMITTED, {
    subcity:subCity,
    vehicleType,
    startDate,
    endDate,
    platform,
    manufacturer,
    city
  });
  const res = await axiosInstance.get(url);
  return res.data;
}
export const getOnlyNotRemitted = async (params:any) => {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.ONLY_ACTIVE_NOT_REMITTED, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1] ,
    endDate: queryKey[2] ,
    platform: queryKey[5] ,
    manufacturer: queryKey[6],
    city:queryKey[7] ||""
  });
  const res = await axiosInstance.get(url);
  return res.data;
}
export const getNumberOfVehicles = async (params:any) => {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.ALL_VEHICLES_COUNT, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1] ,
    endDate: queryKey[2] ,
    platform: queryKey[5] ,
    manufacturer: queryKey[6],
    city:queryKey[7] ||""
  });
  const res = await axiosInstance.get(url);
  return res.data;
}

export const getFilteredActiveVehicles = async (subcity, vehicleType, startDate, endDate, page, pageSize, city, platform="", manufacturer="") => {
  const url = convertParamsToString(reportRequest.FIREBASE_ACTIVE_VEHICLES, {
    subcity,
    vehicleType,
    startDate,
    endDate,
    page,
    pageSize,
    platform,
    manufacturer,
    city
  });
  const res = await axiosInstance.get(url)
  return res.data.data
}
export const getActiveVehicles = async (params:any)=> {
  const {queryKey} = params
  const url = convertParamsToString(reportRequest.FIREBASE_ACTIVE_VEHICLES, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page:queryKey[5],
    pageSize:queryKey[6],
    platform:queryKey[7],
    manufacturer:queryKey[8],
    city:queryKey[9]
  });
  const res = await axiosInstance.get(url)
  return res.data.data
}
export const getFilteredScrappedVehicles = async (subCity, vehicleType, startDate, endDate, pages, pageSize, city, platform, manufacturer) => {
  const url = convertParamsToString(reportRequest.FIREBASE_SCRAPPED_VEHICLES, {
    subcity:subCity,
    vehicleType,
    page:pages,
    pageSize,
    startDate,
    endDate,
    platform,
    manufacturer,
    city
  });
  const res = await axiosInstance.get(url);
  return res.data.data
}
export const getFilteredUnremittedVehicle = async (days, subcity, vehicleType, city, page, pageSize, platform="", manufacturer="") => {
  const url = convertParamsToString(reportRequest.FIREBASE_NOTREMITTED_VEHICLES, {
    days,
    subcity,
    page,
    pageSize,
    vehicleType,
    startDate:"",
    endDate:"",
    platform,
    manufacturer,
    city
  });
  const res = await axiosInstance.get(url);
  return res.data;
}
export const getFilteredInactive = async (subCity, vehicleType, startDate, endDate, pages, pageSize, reason, city, platform="", manufacturer="") => {
  const url = convertParamsToString(reportRequest.FIREBASE_INACTIVE_VEHICLES, {
    subcity:subCity,
    vehicleType,
    startDate,
    endDate,
    page:pages,
    pageSize,
    platform,
    manufacturer,
    reason,
    city
  });
  const res = await axiosInstance.get(url)
  return res.data.data
}
export const getInactiveVehicles = async (params:any)=> {
  const {queryKey} = params
  const url = convertParamsToString(reportRequest.FIREBASE_INACTIVE_VEHICLES, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page: queryKey[5],
    pageSize: queryKey[6],
    platform: queryKey[7],
    manufacturer: queryKey[8],
    reason:queryKey[9],
    city:queryKey[10]
  });
  const res = await axiosInstance.get(url)
  return res.data.data
}

export const getHpComplete = async (params:any)=> {
  const {queryKey} = params
  const url = convertParamsToString(reportRequest.FIREBASE_HP_COMPLETE, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page: queryKey[5],
    pageSize: queryKey[6],
    platform: queryKey[7],
    manufacturer: queryKey[8],
    city:queryKey[9]
  });
  const res = await axiosInstance.get(url)
  return res.data.data
}
export const getReasons = async () => {
  const res = await axiosInstance.get(reportRequest.GET_INACTIVE_REASONS)
  return res.data
}

export const getLostVehicles = async(params:any)=>{
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.LOST_VEHICLES, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page: queryKey[5],
    pageSize: queryKey[6],
    platform: queryKey[7],
    manufacturer: queryKey[8],
    city:queryKey[9]
  });
  const res = await axiosInstance.get(url);
  return res.data.data;
}

export const getUnremittedVehicles = async (params:any)=> {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.NOT_REMITTED, {
    subcity: queryKey[2],
    vehicleType: queryKey[3],
    days: queryKey[1],
    page: queryKey[4],
    pageSize: queryKey[5],
    platform: queryKey[6],
    manufacturer: queryKey[7],
    city:queryKey[8]
  });
  const res = await axiosInstance.get(url);
  return res.data.data;
}

export const getFilteredMissingVehicle = async (subCity, vehicleType, startDate, endDate, pages, pageSize, city, platform, manufacturer) => {
  const url = convertParamsToString(reportRequest.FIREBASE_MISSING_VEHICLES, {
    subcity:subCity,
    vehicleType,
    startDate,
    endDate,
    page:pages,
    pageSize,
    platform,
    manufacturer,
    city
  });
  const res = await axiosInstance.get(url);
  return res.data.data;
}
export const getFilteredCheckinVehicle = async (subCity, vehicleType, startDate, endDate, pages, pageSize, city, platform, manufacturer) => {
  const url = convertParamsToString(reportRequest.FIREBASE_CHECKIN_VEHICLES, {
    subcity: subCity,
    vehicleType,
    startDate,
    endDate,
    page: pages,
    pageSize,
    platform,
    manufacturer,
    city
  });
  const res = await axiosInstance.get(url);
  return res.data.data;
}

export const getFilteredCheckoutVehicle = async (subCity, vehicleType, startDate, endDate, pages, pageSize, city, platform, manufacturer) => {
  const url = convertParamsToString(reportRequest.FIREBASE_CHECKEDOUT_VEHICLES, {
    subcity: subCity,
    vehicleType,
    startDate,
    endDate,
    page: pages,
    pageSize,
    platform,
    manufacturer,
    city
  });
  const res = await axiosInstance.get(url);
  return res.data.data;
}
export const getCheckedinVehicles = async (params:any) => {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.FIREBASE_CHECKIN_VEHICLES, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page: queryKey[5],
    pageSize: queryKey[6],
    platform: queryKey[7],
    manufacturer: queryKey[8],
    city:queryKey[9]
  });
  const res = await axiosInstance.get(url);
  return res.data.data;
}

export const getCheckedoutVehicles = async (params:any) => {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.CHECKING_OUT, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page: queryKey[5],
    pageSize: queryKey[6],
    platform: queryKey[7],
    manufacturer: queryKey[8],
    city: queryKey[9]
  });
  const res = await axiosInstance.get(url);
  return res.data.data;
}

export const getVehiclesSubcity = async ()=> {
  const res = await axiosInstance.get(reportRequest.ALL_VEHICLES_SUBCITY)
  return res.data
}

export const getAllVehicles = async (params:any)=> {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.ALL_VEHICLES, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    startDate: queryKey[1],
    endDate: queryKey[2],
    page: queryKey[5],
    pageSize: queryKey[6],
    platform: queryKey[7],
    manufacturer: queryKey[8],
    city: queryKey[9] || ""
  });
  const res = await axiosInstance.get(url);
  return {...res.data, city:queryKey[3]}
}

export const getScrappedVehicles = async (params:any)=>{
  const {queryKey} = params
  const url = convertParamsToString(reportRequest.SCRAPPED_VEHICLES, {
    subcity: queryKey[3],
    vehicleType: queryKey[4],
    page: queryKey[5],
    pageSize: queryKey[6],
    startDate: queryKey[1],
    endDate: queryKey[2],
    platform: queryKey[7],
    manufacturer: queryKey[8],
    city: queryKey[9]
  });
  const res = await axiosInstance.get(url);
  return res.data.data
}

export const getActiveVehiclesByDate = async (params:any)=> {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.FIREBASE_ACTIVE_BY_DATE, {
    city: queryKey[1],
    vehicleType: queryKey[2]
  });
  const res = await axiosInstance.get(url);
  return res.data
}

export const getInactiveVehiclesByDate = async (params:any)=> {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.FIREBASE_INACTIVE_DATE, {
    city: queryKey[1],
    vehicleType: queryKey[2]
  });
  const res = await axiosInstance.get(url);
  return res.data;
}

export const getCheckedinVehiclesByDate = async (params:any)=> {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.FIREBASE_CHECKIN_DATE, {
    city: queryKey[1],
    vehicleType: queryKey[2]
  });
  const res = await axiosInstance.get(url);
  return res.data;
}

export const getAllCities = async ()=> {
  const res = await axiosInstance.get(reportRequest.ALL_CITIES)
  return res.data
}

export const getCheckedoutVehiclesByDate = async (params:any)=> {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.CHECKING_OUT_BY_DATE, {
    city: queryKey[1],
    vehicleType: queryKey[2]
  });
  const res = await axiosInstance.get(url);
  return res.data;
}

export const getUnremittedVehiclesByDate = async (params:any) => {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.FIREBASE_UNREMITEED_DATE, {
    city: queryKey[1],
    vehicleType: queryKey[2]
  });
  const res = await axiosInstance.get(url);
  return res.data;
};

export const getLostVehiclesByDate = async (params:any) => {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.FIREBASE_MISSING_DATE, {
    city: queryKey[1],
    vehicleType: queryKey[2]
  });
  const res = await axiosInstance.get(url);
  return res.data;
}


export const getHpCompleteByDate = async (params: any) => {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.FIREBASE_HP_COMPLETE_DATE, {
    city: queryKey[1],
    vehicleType: queryKey[2]
  });
  const res = await axiosInstance.get(url);
  return res.data;
};

export const getScrappedVehiclesByDate = async (params: any) => {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.SCRAPPED_VEHICLES_BY_DATE, {
    city: queryKey[1],
    vehicleType: queryKey[2]
  });
  const res = await axiosInstance.get(url);
  return res.data;
};
export const getCheckinReasons = async (params:any) => {
  const { queryKey } = params;
  const url = convertParamsToString(reportRequest.CHECKING_IN_BY_DATE, {
    city: queryKey[1],
    vehicleType: queryKey[2]
  });
  const res = await axiosInstance.get(url);
  return res.data
}

export const getLocations = async ()=>{
  const res = await axiosInstance.get(dashboardRequest.LOCATIONS);
  return res.data;
}

export const prepareForCSV =(results: any) =>{
  return results.map((item: any, index: number) => {
    return {
      'S/N': index + 1,
      'Time Stamp': item.updated_at,
      'Vehicle Type': item.vehicle_type,
      'Max Vehicle ID': item.max_vehicle_id || 'N/A',
      'Ignition No.': item.engine_number || 'N/A',
      'Plate Number': item.plate_number,
      'Tracker IMEI': item.device_imei,
      'Date Added': item.created_at,
      Location: item.city,
      Status: item.vehicle_status || 'N/A',
      'Asset Type': item.vehicle_type,
      Odometer: item.odometer || 'N/A'
    };
  });
}

