import React from 'react';
import { Box, Button, Divider, Flex, Grid, GridItem, Text, useDisclosure } from '@chakra-ui/react';

import { useMaintenanceContext } from 'pages/maintenance/context';
import { TechnicianReport } from '../TechnicianReport';
import { JobCardSection } from '../JobCardSection';
import { AssignTechnicianModal } from '../AssignTechnicianModal';
import { AssignQATesterModal } from '../AssignQATesterModal';
import { QATestersReport } from '../QATestersReport';
import { USER_ROLES, getUserRole } from 'util/customQueryHooks';
export const MaintenanceReportTab = ({ refetch }) => {
  const { jobCardDetails, pastQaTesters, pastTechnicians } = useMaintenanceContext();

  const currentTester = pastQaTesters[pastQaTesters.length - 1];
  const currentTechnician = pastTechnicians[pastTechnicians.length - 1];

  const [reassignTechnician, setReassignTechnician] = React.useState({
    shouldReassign: false,
    currentTechnicianId: ''
  });

  const [reassignTester, setReassignTester] = React.useState({
    shouldReassign: false,
    currentTesterId: ''
  });

  const {
    isOpen: isOpenAssignTechnicianModal, //(boolean)If true, it sets the controlled component to its visible state
    onOpen: onOpenAssignTechnicianModal, // todo (function)Callback function to set a truthy value for the isOpen parameter
    onClose: onCloseAssignTechnicianModal //(function)Callback function to set a falsy value for the isOpen paramete
  } = useDisclosure(); //useDisclosure is a custom hook used to help handle common open, close, or toggle scenarios. It can be used to control feedback component such as Modal, AlertDialog, Drawer, etc.

  const {
    isOpen: isOpenAssignQATesterModal,
    onOpen: onOpenAssignQATesterModal,
    onClose: onCloseAssignQATesterModal
  } = useDisclosure();

  const {
    isOpen: isOpenTechnicianReportModal,
    onOpen: onOpenTechnicianReportModal,
    onClose: onCloseTechnicianReportModal
  } = useDisclosure();

  const {
    isOpen: isOpenQATesterReportModal,
    onOpen: onOpenQATesterReportModal,
    onClose: onCloseQATesterReportModal
  } = useDisclosure();

  //todo Reassign technicians  logic
  const openReassignTechnicianModal = () => {
    onOpenAssignTechnicianModal();
    setReassignTechnician({ shouldReassign: true, currentTechnicianId: currentTechnician.id });
  };

  const openReassignTesterModal = () => {
    onOpenAssignQATesterModal();
    setReassignTester({ shouldReassign: true, currentTesterId: currentTester.id });
  };

  //logic to fiter the name of technician with status "assigned" or "unknown"
  const assignedTechnician: any = [''];

  let filteredTechnician: any = pastTechnicians
    .filter(function (e: any) {
      return e.status !== 'reassigned';
    })
    .map(function (t: any) {
      return `${t.user.first_name} ${t.user.last_name}`;
    });

  filteredTechnician.forEach(function (a: any) {
    assignedTechnician.unshift(a);
  });

  const userRole = getUserRole();

  return (
    <>
      <Grid templateColumns="repeat(6, 1fr)" gap={6} px={4}>
        <GridItem colStart={1} colEnd={4}>
          <JobCardSection />
        </GridItem>
        <GridItem colStart={1} colEnd={4}>
          <Box bgColor="#fff" border="1px" borderColor="rgba(238, 238, 238, 1)" borderRadius="10px">
            <Box px={4} py={2}>
              <Text color="rgba(128, 128, 128, 1)" fontSize="0.75rem">
                Technician
              </Text>
            </Box>
            <Divider />
            {/* ==================================first technicians==================================================== */}
            <Flex alignItems="center" gap={4} justifyContent="space-between" px={6} py={4}>
              <Box>
                <Text color="hsla(0, 0%, 24%, 0.5)" fontSize="0.875rem">
                  Name
                </Text>
                <Text color="hsla(0, 0%, 24%, 1)">
                  {pastTechnicians.length > 0 ? `${assignedTechnician[0] || ''} ` : 'Not Applicable'}
                </Text>
              </Box>

              {userRole !== USER_ROLES.VEHICLE_MANAGER && (
                <Button
                  bgColor="hsla(218, 14%, 89%, 1)"
                  borderRadius="2px"
                  fontSize="0.75rem"
                  fontWeight="semibold"
                  isDisabled={jobCardDetails[0]?.status === 'completed'}
                  onClick={pastTechnicians.length > 0 ? openReassignTechnicianModal : onOpenAssignTechnicianModal}
                  px={6}
                  py={2}
                >
                  {pastTechnicians.length > 0 ? 'REASSIGN' : 'ASSIGN'}
                </Button>
              )}
            </Flex>
            {/* ===================================================Second technicians=============================================================== */}
            <Flex alignItems="center" gap={4} justifyContent="space-between" px={6} py={4}>
              <Box>
                <Text color="hsla(0, 0%, 24%, 0.5)" fontSize="0.875rem">
                  Name
                </Text>
                <Text color="hsla(0, 0%, 24%, 1)">
                  {pastTechnicians.length > 0 ? `${assignedTechnician[1] || ''} ` : 'Not Applicable'}
                </Text>
              </Box>

              {userRole !== USER_ROLES.VEHICLE_MANAGER && (
                <Button
                  bgColor="hsla(218, 14%, 89%, 1)"
                  borderRadius="2px"
                  fontSize="0.75rem"
                  fontWeight="semibold"
                  isDisabled={jobCardDetails[0]?.status === 'completed'}
                  onClick={pastTechnicians.length > 1 ? openReassignTechnicianModal : onOpenAssignTechnicianModal}
                  px={6}
                  py={2}
                >
                  {pastTechnicians.length > 1 ? 'REASSIGN' : 'ASSIGN'}
                </Button>
              )}
            </Flex>
          </Box>
        </GridItem>

        {/* QA tester */}
        <GridItem colStart={4} colEnd={7}>
          <Box bgColor="#fff" border="1px" borderColor="rgba(238, 238, 238, 1)" borderRadius="10px">
            <Box px={4} py={2}>
              <Text color="rgba(128, 128, 128, 1)" fontSize="0.75rem">
                QA Tester
              </Text>
            </Box>
            <Divider />
            <Flex alignItems="center" gap={4} justifyContent="space-between" px={6} py={4}>
              <Box>
                <Text color="hsla(0, 0%, 24%, 0.5)" fontSize="0.875rem">
                  Name
                </Text>
                <Text color="hsla(0, 0%, 24%, 1)">
                  {pastQaTesters.length > 0
                    ? `${currentTester?.user?.first_name || ''} ${currentTester?.user?.last_name || ''}`
                    : 'Not Applicable'}
                </Text>
              </Box>
              {userRole !== USER_ROLES.VEHICLE_MANAGER && (
                <Button
                  bgColor="hsla(218, 14%, 89%, 1)"
                  borderRadius="2px"
                  fontSize="0.75rem"
                  fontWeight="semibold"
                  isDisabled={jobCardDetails[0]?.status === 'completed'}
                  onClick={pastQaTesters.length > 0 ? openReassignTesterModal : onOpenAssignQATesterModal}
                  px={6}
                  py={2}
                >
                  {pastQaTesters.length > 0 ? 'REASSIGN' : 'ASSIGN'}
                </Button>
              )}
            </Flex>
          </Box>
        </GridItem>
        <GridItem colStart={1} colEnd={4}>
          <TechnicianReport
            isOpen={isOpenTechnicianReportModal}
            onClose={onCloseTechnicianReportModal}
            onOpen={onOpenTechnicianReportModal}
            refetch={refetch}
          />
        </GridItem>
        <GridItem colStart={4} colEnd={7}>
          <QATestersReport
            refetch={refetch}
            isOpen={isOpenQATesterReportModal}
            onClose={onCloseQATesterReportModal}
            onOpen={onOpenQATesterReportModal}
          />
        </GridItem>
      </Grid>

      {
        <AssignTechnicianModal
          setReassignTechnician={setReassignTechnician} // 60%sure
          reassignTechnician={reassignTechnician} //sending boolean and ID React.useState=reassignTechnician, setReassignTechnician
          isOpen={isOpenAssignTechnicianModal} //! no problem (boolean)  to make visible or hidden useDisclosure=isOpenAssignTechnicianModal,onCloseAssignTechnicianModal
          onClose={onCloseAssignTechnicianModal}
          refetch={refetch} // from useDisclosure
        />
      }

      <AssignQATesterModal
        setReassignTester={setReassignTester}
        reassignTester={reassignTester}
        isOpen={isOpenAssignQATesterModal}
        onClose={onCloseAssignQATesterModal}
        refetch={refetch}
      />
    </>
  );
};
