import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { PROTECTED_PATHS, PUBLIC_PATHS } from './constants';
import { AppRoute } from './types';

import { InactiveVehicleReport } from '../pages/reports/inactive-vehicles/inactive-vehicles';
import { ActiveVehicleReport } from '../pages/reports/active-vehicles/active-vehicles';
import { Maintenance } from '../pages/maintenance';
import { MaintenanceDetail } from '../pages/maintenance/detail';
import { LostVehicleReport } from '../pages/reports/lost-vehicles/lost-vehicles';
import { VehicleCheckinReport } from '../pages/reports/vehicle-checkins/vehicle-checkins';
import { VehicleCheckOutReport } from '../pages/reports/vehicle-checkouts/vehicle-checkouts';
import { NotRemittedReport } from '../pages/reports/not-remitted/not-remitted';
import { UserManagement } from '../pages/user-management/user-management';
import VehicleDetails from '../pages/vehicles/vehicle-details';
import HpCompleteVehicles from '../pages/reports/hp-complete-vehicles/hp-complete-vehicles';
import ScrappedVehicles from '../pages/reports/scrapped-vehicles/scrapped-vehicles';
import Notification from 'pages/Notification';
import { VehicleActivity } from 'pages/vehicle-activity';
import { VehicleActivityDetail } from 'pages/vehicle-activity/vehicle-activity-detail';
import WithSuspense from '../components/WithSuspense';
import { UnscheduledMaintenance } from 'pages/maintenance/unscheduled-maintenance';
import { ScheduledMaintenance } from 'pages/maintenance/scheduled-maintenence';
import { DueForMaintenance } from 'pages/maintenance/due-for-maintenence';
import MaintenanceContextProvider from 'pages/maintenance/context';
import ContactCenter from 'pages/contact-center/ContactCenter';
import VehiclesReadyForActivations from 'pages/ready-for-activation';
import TicketManagmentOverview from 'pages/ticket-managment/ticket-managment-overview';

// const VehicleASSIGNMENT = WithSuspense(lazy(() => import('pages/vehicle-assignment')));
const AssetManagement = WithSuspense(lazy(() => import('pages/asset-management')));
const VehiclesComponent = WithSuspense(lazy(() => import('pages/vehicles')));

export const PROTECTED_ROUTES: AppRoute[] = [
  { path: PROTECTED_PATHS.VEHICLES, element: <VehiclesComponent /> },
  { path: PROTECTED_PATHS.READY_FOR_ACTIVATION, element: <VehiclesReadyForActivations /> },
  { path: PROTECTED_PATHS.ASSET_MANAGEMENT, element: <AssetManagement /> },
  // { path: PROTECTED_PATHS.VEHICLE_ASSIGNMENT, element: <VehicleASSIGNMENT /> },////!to dissable vehicle assement element: <VehicleASSIGNMENT /> to element: <AssetManagement />
  { path: '/reports/inactive-vehicles', element: <InactiveVehicleReport /> },
  { path: '/reports/active-vehicles', element: <ActiveVehicleReport /> },
  { path: '/reports/lost-vehicles', element: <LostVehicleReport /> },
  { path: '/reports/vehicle-checkins', element: <VehicleCheckinReport /> },
  { path: '/reports/vehicle-checkouts', element: <VehicleCheckOutReport /> },
  { path: '/reports/not-remitted', element: <NotRemittedReport /> },
  { path: '/reports/hp-completed', element: <HpCompleteVehicles /> },
  { path: '/reports/scrapped-vehicles', element: <ScrappedVehicles /> },
  { path: PROTECTED_PATHS.MAINTENANCE, element: <Maintenance /> },
  { path: PROTECTED_PATHS.UNSCHEDULED_MAINTENANCE, element: <UnscheduledMaintenance /> },
  { path: PROTECTED_PATHS.SCHEDULED_MAINTENANCE, element: <ScheduledMaintenance /> },
  { path: PROTECTED_PATHS.DUE_FOR_MAINTENANCE, element: <DueForMaintenance /> },
  { path: PROTECTED_PATHS.TICKET_MANAGEMENT, element: <TicketManagmentOverview /> },
  { path: PROTECTED_PATHS.CONTACT_CENTER, element: <ContactCenter /> },
  {
    path: PROTECTED_PATHS.MAINTENANCE_DETAIL,
    element: (
      <MaintenanceContextProvider>
        <MaintenanceDetail />
      </MaintenanceContextProvider>
    )
  },
  { path: PROTECTED_PATHS.USER_MANAGEMENT, element: <UserManagement /> },
  { path: PROTECTED_PATHS.NOTIFICATION, element: <Notification /> },
  { path: PROTECTED_PATHS.VEHICLE_ACTIVITY, element: <VehicleActivity /> },
  { path: '/vehicles/details/:id', element: <VehicleDetails /> },
  { path: '/vehicle-activity/vehicle/detail/:id', element: <VehicleActivityDetail /> },
  { path: '/', element: <VehiclesComponent /> },
  // this enables you not to access the public routes when logged in
  ...Object.values(PUBLIC_PATHS).map((route) => {
    return {
      path: route,
      element: <Navigate to={PROTECTED_PATHS.ASSET_MANAGEMENT} />
    };
  }),
  { path: '*', element: <div>Page not found</div> }
];
