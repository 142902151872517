import React from 'react';
import styled from 'styled-components';
import AsyncSelect from 'react-select/async';

type SelectProps = {
  loadOptions: any;
  defaultOptions?: any;
  value?: any;
  fullWidth?: boolean;
  onChange: any;
  placeholder?: string;
  name?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  inputValue?: any;
  [x: string]: any;
};

const AsyncCustomSelect = ({
  loadOptions,
  onChange,
  fullWidth,
  isDisabled,
  isLoading,
  name,
  defaultOptions,
  placeholder = 'Select an Option',
  value,
  inputValue,
  ...rest
}: SelectProps) => {
  return (
    <AsyncCustomSelect.Wrapper>
      <AsyncSelect
        {...rest}
        value={value}
        loadOptions={loadOptions}
        onChange={onChange}
        isDisabled={isDisabled}
        isLoading={isLoading}
        placeholder={placeholder}
        defaultOptions={defaultOptions}
        matchPos="any"
        name={name}
        className={`select-item ${fullWidth ? 'full-width' : ''}`}
        classNamePrefix="react-select"
        isSearchable={true}
        isClearable={true}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#FFCC00',
            primary: 'black',
            primary50: '#f1c40f'
          }
        })}
      />
    </AsyncCustomSelect.Wrapper>
  );
};

AsyncCustomSelect.Wrapper = styled.div`
  font-size: 13px;
  .select-item {
    width: 180px;
  }
  .full-width {
    width: 100%;
  }
`;
export default AsyncCustomSelect;
