import React from 'react';
import { Button, Flex, Image, Text } from '@chakra-ui/react';

import ErrorImg from 'assets/images/vehicle-activity/error-illustration.png';
class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }
  render() {
    if (this.state.hasError) {
      return (
        <Flex align="center" bg="white" direction="column" h="90vh" justify="center" py={10}>
          <Image src={ErrorImg} alt="Loading illustration" w={250} />
          <Text size="md" mb={5}>
            You encountered an error. Please try again later.
          </Text>
          <Button
            type="button"
            onClick={() => {
              this.setState({ hasError: false });
              window.location.href = '/';
            }}
          >
            Return to dashboard
          </Button>
        </Flex>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
