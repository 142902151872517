import { FC } from 'react';
import { Box, Divider, Flex, Grid, GridItem, Tabs, TabList, TabPanels, Tab, TabPanel, Text } from '@chakra-ui/react';
import Moment from 'react-moment';
import { IoCloseCircle, IoCheckmarkCircleSharp, IoWarning } from 'react-icons/io5';

import Modal from 'components/BaseModal';

type ReportHistoryModalType = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  modalData: any;
};

export const ReportHistoryModal: FC<ReportHistoryModalType> = ({ isLoading, isOpen, modalData, onClose }) => {
  const tabHeaders = ['1st Report', '2nd Report', '3rd Report'];
  //   const tabOneData = modalData?.maintenance_user?.maintenance_report[0];

  const displayIconOptions = {
    ok: <IoCheckmarkCircleSharp color="#00BE7C" size={20} />,
    bad: <IoCloseCircle color="#F03738AC" size={20} />,
    warn: <IoWarning color="#ffcc00" size={20} />,
    not_applicable: <IoWarning color="#ffcc00" size={20} />
  };

  // const DisplayFilledReport = () => {
  //   const displayIconOptions = {
  //     ok: <IoCheckmarkCircleSharp color="#00BE7C" size={20} />,
  //     bad: <IoCloseCircle color="#F03738AC" size={20} />,
  //     warn: <IoWarning color="#ffcc00" size={20} />,
  //     not_applicable: <IoWarning color="#ffcc00" size={20} />
  //   };

  //   return (
  //     <>
  //       <Grid gap={4} templateColumns="repeat(2, 1fr)" maxH="500px" overflowY="scroll" px={5} py={4}>
  //         {modalData?.maintenance_user?.maintenance_report?.map((item, idx) => (
  //           <GridItem key={idx}>
  //             <Flex gap={10} justifyContent="space-between">
  //               <Text color="#707070" fontSize="0.875rem">
  //                 {item?.name}
  //               </Text>
  //               {displayIconOptions[item?.currentStatus?.status]}
  //             </Flex>
  //           </GridItem>
  //         ))}
  //       </Grid>
  //     </>
  //   );
  // };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} title="Checkin Report" size="4xl">
        <Tabs colorScheme="gray" variant="solid-rounded">
          <Flex alignItems="center" justifyContent="space-between" px={5} py={3}>
            <TabList>
              {tabHeaders.map((tab,idx) => (
                <Tab key={idx} fontSize="0.875rem">{tab}</Tab>
              ))}
            </TabList>
            <Text fontSize="0.75rem" color="#3E3E3E">
              <Box as="span" color="#808080">
                Checkin Date:
              </Box>{' '}
              <Moment format="DD/MM/YYYY hh:mm A">{modalData?.created_at}</Moment>
            </Text>
          </Flex>
          <TabPanels>
            <TabPanel>
              <Box>
                <Grid templateColumns="repeat(6, 1fr)" gap={2}>
                  <GridItem
                    bgColor="#fff"
                    border="1px"
                    borderColor="rgba(238, 238, 238, 1)"
                    borderRadius="10px"
                    colEnd={4}
                    colStart={1}
                  >
                    <Flex alignItems="center" display="flex" justifyContent="space-between" px={4} py={2}>
                      <Text color="rgba(128, 128, 128, 1)" fontSize="0.75rem" fontWeight="bold">
                        Technician's Report
                      </Text>
                      <Text color="rgba(128, 128, 128, 1)" fontSize="0.75rem" fontWeight="bold">
                        {modalData?.champion?.first_name} {modalData?.champion?.last_name}
                      </Text>
                    </Flex>
                    <Divider />
                    <Grid gap={4} templateColumns="repeat(2, 1fr)" maxH="500px" overflowY="scroll" px={5} py={4}>
                      {modalData?.maintenance_user[0]?.maintenance_report?.map((item, idx) => (
                        <GridItem key={idx}>
                          <Flex gap={10} justifyContent="space-between">
                            <Text color="#707070" fontSize="0.875rem">
                              {item?.name}
                            </Text>
                            {displayIconOptions[item?.currentStatus?.status]}
                          </Flex>
                        </GridItem>
                      ))}
                    </Grid>
                  </GridItem>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel>
              <p>two!</p>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Modal>
    </>
  );
};
