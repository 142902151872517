import Select from 'react-select';
import styled from 'styled-components';

type SelectProps = {
  onChange: any;
  placeholder?: string;
  name?: string;
  options?: Array<any>;
  value?: any;
  fullWidth?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  [x: string]: any;
};
const CustomSelect = ({
  options = [],
  onChange,
  placeholder = 'Select an Option',
  name,
  isLoading,
  fullWidth = false,
  isDisabled,
  value,
  ...rest
}: SelectProps) => {
  return (
    <CustomSelect.Wrapper>
      <Select
        {...rest}
        options={options}
        value={value}
        className={`select-item ${fullWidth ? 'full-width' : ''}`}
        classNamePrefix="react-select"
        isSearchable={true}
        isClearable={true}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        components={{
          IndicatorSeparator: () => null
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#FFCC00',
            primary: 'black',
            primary50: '#f1c40f'
          }
        })}
      />
    </CustomSelect.Wrapper>
  );
};
CustomSelect.Wrapper = styled.div`
  font-size: 13px;
  .select-item {
    width: 150px;
  }
  .full-width {
    width: 100%;
  }
`;
export default CustomSelect;
