import { FC, useMemo, useState } from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import {
  Center,
  Flex,
  IconButton,
  // NumberDecrementStepper,
  // NumberInput,
  // NumberIncrementStepper,
  // NumberInputField,
  // NumberInputStepper,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { nanoid } from 'nanoid';

import { RenderEmptyData } from 'components/EmptyData/EmptyData';

type NewTableProps = {
  emptyDataDescription?: string;
  tableData?: any[];
  tableColumns?: any[];
  selectedRow?: any;
  setPageNumber?: any;
  pageNumber: number;
  pageCount?: number;
  currentPage: number;
  setPageSizes?: any;
  pageSizes?: number;
  gotoPage?: number | undefined;
};

const defaultPageSizeOptions = [10, 25, 50, 100, 500, 1000];

export const NewTable: FC<NewTableProps> = ({
  emptyDataDescription,
  tableData = [],
  tableColumns = [],
  selectedRow,
  setPageNumber,
  pageNumber,
  pageCount,
  currentPage,
  setPageSizes,
  pageSizes
  // gotoPage,
}) => {
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => tableData, [tableData]);
  const [, sethighlightRow] = useState('');

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, state } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useGlobalFilter,
    usePagination
  );

  const { pageSize } = state;

  const rowClicked = (currentRow) => {
    if (selectedRow) {
      selectedRow(currentRow.original);
      sethighlightRow(currentRow.original.id);
    }
  };

  return (
    <div>
      {!data.length ? (
        <Center>
          <RenderEmptyData iconWidth={30} iconHeight={30} description={emptyDataDescription} />
        </Center>
      ) : (
        <Table variant="simple" {...getTableProps}>
          <Thead bg="#FFF8CE">
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th fontSize="9px" {...column.getHeaderProps()} color="#5F6862">
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  onClick={() => rowClicked(row)}
                  _hover={{ cursor: 'pointer', bg: '#FFFBEA' }}
                >
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()} fontSize="14px">
                      {cell.render('Cell')}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      )}

      <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex>
          <Tooltip label="First Page" hasArrow>
            <IconButton
              onClick={() => setPageNumber(1)}
              isDisabled={pageNumber <= 1}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
              aria-label="Previous Page"
            />
          </Tooltip>
          <Tooltip label="Previous Page" hasArrow>
            <IconButton
              onClick={() => setPageNumber((currentPage: any) => currentPage - 1)}
              isDisabled={pageNumber <= 1}
              icon={<ChevronLeftIcon h={6} w={6} />}
              aria-label="Prev Page"
            />
          </Tooltip>
        </Flex>

        <Flex alignItems="center">
          <Text flexShrink="0" mr={8}>
            Page{' '}
            <Text fontWeight="bold" as="span">
              {currentPage}
            </Text>{' '}
            of{' '}
            <Text fontWeight="bold" as="span">
              {pageCount}
            </Text>
          </Text>
          {/* <Text flexShrink="0">Go to page:</Text>{' '}
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={pageCount}
            onChange={(e: any) => {
              const goToPageNumber = e.target.value ? +e.target.value - 1 : 0;
              // @ts-ignore
              gotoPage(goToPageNumber);
            }}
            defaultValue={currentPage}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput> */}

          <Select
            w={32}
            value={pageSize}
            onChange={(e) => {
              setPageSizes(Number(e.target.value));
            }}
            isDisabled
          >
            {defaultPageSizeOptions.map((pageSize) => (
              <option key={nanoid()} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Tooltip label="Next Page" hasArrow>
            <IconButton
              onClick={() => setPageNumber((pageNumber: any) => pageNumber + 1)}
              isDisabled={pageNumber === pageCount}
              icon={<ChevronRightIcon h={6} w={6} />}
              aria-label="Previous page"
            />
          </Tooltip>
          <Tooltip label="Last Page" hasArrow>
            <IconButton
              onClick={() => setPageNumber(pageCount)}
              isDisabled={pageNumber === pageCount}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
              aria-label="Previous page"
            />
          </Tooltip>
        </Flex>
      </Flex>
    </div>
  );
};
