import { FC } from 'react';
import { Tag, Text } from '@chakra-ui/react';

type StatusDisplayProps = {
  borderRadius?: string | number;
  fontSize?: string | number;
  status: string;
};

export const StatusDisplay: FC<StatusDisplayProps> = ({ borderRadius, fontSize = '0.875rem', status }) => {
  const statusSwitch = {
    'active vehicle': {
      background: 'rgba(49, 196, 145, 0.1)',
      name: 'Active Vehicle',
      color: '#31c491'
    },
    active: {
      background: 'rgba(49, 196, 145, 0.1)',
      name: 'Active',
      color: '#31c491'
    },
    'inactive vehicle': {
      background: 'rgba(226, 98, 98, 0.1)',
      name: 'Inactive Vehicle',
      color: '#E26262'
    },
    inactive: {
      background: 'rgba(226, 98, 98, 0.1)',
      name: 'Inactive',
      color: '#E26262'
    },
    'hp completed vehicle': {
      background: 'rgb(171, 70, 210, 0.1)',
      name: 'HP Completed Vehicle',
      color: '#AB46D2'
    },
    inbound: {
      background: 'rgb(171, 70, 210, 0.1)',
      name: 'Inbound',
      color: '#AB46D2'
    },
    churned: {
      background: 'rgba(194, 11, 11, 0.1)',
      name: 'Churned',
      color: '#c20b0b'
    },
    deactivated: {
      background: 'rgba(157, 19, 43, 0.1)',
      name: 'Deactivated',
      color: '#9d132b'
    },
    'scrapped vehicle': {
      background: 'rgba(157, 19, 43, 0.1)',
      name: 'Scrapped Vehicle',
      color: '#9d132b'
    },
    'missing vehicle': {
      background: 'rgba(157, 19, 43, 0.1)',
      name: 'Missing Vehicle',
      color: '#9d132b'
    },
    unassigned: {
      background: 'rgba(31, 120, 180, .1)',
      name: 'UNASSIGNED',
      color: 'rgba(31, 120, 180, 1)'
    },
    assigned: {
      background: 'rgba(112, 112, 112, .1)',
      name: 'ASSIGNED',
      color: 'rgba(112, 112, 112, 1)'
    },
    scheduled: {
      background: 'rgba(49, 196, 145, 0.1)',
      name: 'scheduled',
      color: '#31c491'
    },
    unscheduled: {
      background: 'rgb(255, 248, 188, 0.7)',
      name: 'unscheduled',
      color: '#FAC213'
    },
    paused: {
      background: 'rgb(255, 248, 188, 0.7)',
      name: 'Paused',
      color: '#FAC213'
    },
    completed: {
      background: 'rgba(49, 196, 145, 0.1)',
      name: 'Completed',
      color: '#31c491'
    },
    pending: {
      background: 'rgba(194, 11, 11, 0.1)',
      name: 'Pending',
      color: '#c20b0b'
    },
    ongoing: {
      background: 'hsla(265, 100%, 44%, 0.1)',
      name: 'Ongoing',
      color: 'hsla(265, 100%, 44%, 1)'
    },
    ready_to_test: {
      background: 'rgba(31, 120, 180, .1)',
      name: 'Ready to Test',
      color: 'rgba(31, 120, 180, 1)'
    },
    awaiting_parts: {
      background: 'hsla(265, 100%, 44%, 0.1)',
      name: 'Awaiting Parts',
      color: 'hsla(265, 100%, 44%, 1)'
    },
    awaiting_maintenance: {
      background: 'hsla(265, 100%, 44%, 0.1)',
      name: 'Awaiting Maintenance',
      color: 'hsla(265, 100%, 44%, 1)'
    }
  };

  const currentStatus = statusSwitch[status?.toLowerCase()] || 'Active Vehicle';

  return (
    <Tag borderRadius={borderRadius} variant="solid" background={currentStatus.background} px={4} minW="74px">
      <Text
        fontWeight="bold"
        textTransform="capitalize"
        fontSize={fontSize}
        color={currentStatus.color}
        textAlign="center"
        py={1}
      >
        {currentStatus.name}
      </Text>
    </Tag>
  );
};
