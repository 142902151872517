import { FC, useState } from 'react';
import { Box, Button, Flex, InputLeftElement, InputGroup, Text } from '@chakra-ui/react';
import { Search2Icon, WarningIcon } from '@chakra-ui/icons';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { KEY_LS_MAX_USER_VAMS, QUERY_KEYS } from 'util/constants';
import { useMaintenanceContext } from 'pages/maintenance/context';
import axiosInstance, { maintenanceRequest } from 'services/httpService';

import Modal from 'components/BaseModal';

import SearchBoxTechinicianAndQA from 'pages/vehicles/vehicles-nav/SearchBoxTechinicianAndQA';

interface AssignTechnicianModalI {
  isOpen: boolean;
  onClose: () => void;
  setReassignTester: any;
  refetch: any;
  reassignTester: {
    shouldReassign: boolean;
    currentTesterId: string;
  };
}

interface TechnicianDataI {
  user_account_id: string;
  id: string;
  first_name: string;
  last_name: string;
  role_id?: string;
  email?: string;
  phone?: string;
}

export const AssignQATesterModal: FC<AssignTechnicianModalI> = ({ isOpen, onClose, reassignTester, refetch }) => {
  const [searchText, setSearchText] = useState('');
  const fleetOfficerData = JSON.parse(localStorage.getItem(KEY_LS_MAX_USER_VAMS) || '{}');

  const { vehicleData, qaTesterRoleId } = useMaintenanceContext();

  const queryClient = useQueryClient();

  const [selectedTester, setSelectedTester] = useState<TechnicianDataI>({
    user_account_id: '',
    id: '',
    first_name: '',
    last_name: '',
    role_id: '',
    email: ''
  });

  const assignTesterPayload = {
    username: selectedTester?.first_name + ' ' + selectedTester?.last_name,
    email: selectedTester?.email,
    role_id: selectedTester?.role_id,
    phone_number: selectedTester?.phone,
    maintenance_schedule_id: vehicleData?.maintenance_schedule?.id,
    maintenance_id: vehicleData?.id,
    fleetOfficerName: `${fleetOfficerData?.first_name} ${fleetOfficerData?.last_name}`,
    fleetOfficerLocation: fleetOfficerData?.role?.city_id
  };

  // handle assign tester
  const assignTesterMutation = useMutation(
    (data: any) => axiosInstance.post(`${maintenanceRequest.ASSIGN_QA_TESTER}`, data),
    {
      onSuccess: (data) => {
        refetch();
        toast.success(data?.data?.message);
        queryClient.invalidateQueries(QUERY_KEYS.TESTERS_HISTORY);
        queryClient.invalidateQueries(QUERY_KEYS.GET_MAINTENANCE_VEHICLE_BY_ID);
        onClose();
      },

      onError: (err: any) => {
        toast.error(err?.response?.data?.message);
      }
    }
  );

  const handleAssignTester = () => {
    assignTesterMutation.mutate({ ...assignTesterPayload, user_id: selectedTester?.user_account_id });
  };

  // handle reassign tester
  const reassignTesterMutation = useMutation(
    (data: any) => {
      return axiosInstance.post(`${maintenanceRequest.REASSIGN_QA_TESTER}`, data);
    },
    {
      onSuccess: (data) => {
        toast.success(data?.data?.message);
        queryClient.invalidateQueries(QUERY_KEYS.TESTERS_HISTORY);
        queryClient.invalidateQueries(QUERY_KEYS.GET_MAINTENANCE_VEHICLE_BY_ID);
        // onClose();
        refetch();
      },

      onError: (err: any) => {
        toast.error(err?.response?.data?.message);
      }
    }
  );

  const handleReassignTester = () => {
    reassignTesterMutation.mutate({
      ...assignTesterPayload,
      account_user_id: selectedTester?.user_account_id,
      initial_assigned_user_id: reassignTester.currentTesterId // uuid of past past testers
    });
  };

  const searchUser = async (params: any) => {
    const url = `${maintenanceRequest.ALL_ACTIVE_USERS}/${qaTesterRoleId}?role=qa_tester&status=active${
      searchText && `&search_query=${searchText}`
    }`;
    const response = await axiosInstance.get(url);
    // setDatas(response.data.data.users);
    return response.data.data;
  };

  const allUsers = useQuery([QUERY_KEYS.QA_TESTER, searchText], searchUser, {
    enabled: !!qaTesterRoleId
  });
  const temp = allUsers.data?.users;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${reassignTester.shouldReassign ? 'Reassign' : 'Assign'} QA Tester`}
      size="lg"
      subTitle="Please select the QA Tester to work on this assignment."
    >
      <Text color="#707070" fontSize="0.875rem" px={5} py={4}>
        Select QA Tester
      </Text>
      <Box bgColor="#EEEEEE" borderRadius="8px" px={5} py={4}>
        <InputGroup bgColor="#fff" borderRadius="8px" _focus={{ color: 'yellow.200' }}>
          <InputLeftElement pointerEvents="none" children={<Search2Icon color="gray.300" />} />
          {/* <Input type="text" placeholder="Search for QA Testers" onChange={debounceOnChange} /> */}
          <SearchBoxTechinicianAndQA
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={'Search for technicians'}
          />
        </InputGroup>
        <Flex
          flexDirection="column"
          bgColor="#fff"
          borderRadius="8px"
          gap={1}
          mt={3}
          maxH="40vh"
          overflowY="scroll"
          px={5}
          py={2}
        >
          {!temp?.length ? (
            <Box textAlign="center" p={10}>
              <WarningIcon w={5} color="gray.400" mb={2} />
              <Text maxW="md">We have no record of that QA Tester.</Text>
            </Box>
          ) : (
            temp?.map((technician, idx) => (
              <Box
                onClick={() => {
                  setSelectedTester(technician);
                }}
                border="1px"
                borderColor={
                  selectedTester?.user_account_id === technician?.user_account_id ? 'yellow.400' : 'gray.200'
                }
                borderRadius="2px"
                cursor="pointer"
                key={idx}
                px={5}
                py={3}
              >
                <Text>
                  {technician.first_name} {technician.last_name}
                </Text>
              </Box>
            ))
          )}
        </Flex>
      </Box>
      <Flex justifyContent="space-between" mt={5}>
        <Button
          onClick={reassignTester.shouldReassign ? handleReassignTester : handleAssignTester}
          bgColor="#FFCC00"
          borderRadius="4px"
          fontSize="0.8125rem"
          px={6}
          py={2}
        >
          {reassignTester.shouldReassign ? 'REASSIGN QA TESTER' : 'ASSIGN QA TESTER'}
        </Button>
        <Button bgColor="#EEEEEE" borderRadius="4px" fontSize="0.8125rem" px={6} py={2} onClick={onClose}>
          CANCEL
        </Button>
      </Flex>
    </Modal>
  );
};
