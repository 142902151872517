import React, { FC } from 'react';
import { Box, Button, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { IoCloseCircle, IoCheckmarkCircleSharp, IoWarning } from 'react-icons/io5';
import { useQuery } from 'react-query';

import { useMaintenanceContext } from 'pages/maintenance/context';
import axiosInstance, { maintenanceRequest } from 'services/httpService';

import { QAReportEntryModal } from './QATesterReportEntryModal';
import { USER_ROLES, getUserRole } from 'util/customQueryHooks';

interface QATestersReportI {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  refetch: any;
}

export const QATestersReport: FC<QATestersReportI> = ({ isOpen, onClose, onOpen, refetch }) => {
  const { jobCardDetails, techniciansReport, testersReport, setTestersReport, vehicleData } = useMaintenanceContext();

  const isQaTesterNotAssigned =
    vehicleData?.maintenance_schedule?.is_qa_tester_assigned === 'no' ||
    !vehicleData?.maintenance_schedule?.is_qa_tester_assigned ||
    !techniciansReport.checklist_metadata;

  const userRole = getUserRole();
  // get testers report
  const getReports = async () => {
    const url = `${maintenanceRequest.MAINTENANCE_REPORT}/${vehicleData?.maintenance_schedule?.id}/qa_tester`;
    const data = await axiosInstance.get(url);
    return data;
  };
  const { isLoading } = useQuery(['testers-report'], getReports, {
    enabled: !!vehicleData?.maintenance_schedule?.id,
    onSuccess: ({ data: { data = {} } }) => {
      setTestersReport(data);
    }
  });

  const FillAssignedReport = () => {
    return (
      <Box py={16} textAlign="center">
        <Text color="hsla(0, 0%, 24%, 0.5)" fontSize="0.875rem" mb={3}>
          Click the button below to fill the QA Tester’s report
        </Text>
        <Button
          bgColor="hsla(218, 14%, 89%, 1)"
          borderRadius="4px"
          fontSize="0.8125rem"
          fontWeight="semibold"
          isDisabled={isQaTesterNotAssigned}
          onClick={onOpen}
          px={6}
          py={2}
        >
          FILL REPORT
        </Button>
      </Box>
    );
  };

  const DisplayFilledReport = () => {
    const displayIconOptions = {
      ok: <IoCheckmarkCircleSharp color="#00BE7C" size={20} />,
      bad: <IoCloseCircle color="#F03738AC" size={20} />,
      warn: <IoWarning color="#ffcc00" size={20} />,
      not_applicable: <IoWarning color="#ffcc00" size={20} />
    };

    return (
      <>
        {testersReport && (
          <Grid gap={4} templateColumns="repeat(2, 1fr)" maxH="500px" overflowY="scroll" px={5} py={4}>
            {testersReport.checklist_metadata?.map((item, idx) => (
              <GridItem key={idx}>
                <Flex gap={10} justifyContent="space-between">
                  <Text color="#707070" fontSize="0.875rem">
                    {item?.name}
                  </Text>
                  {displayIconOptions[item?.currentStatus?.status]}
                </Flex>
              </GridItem>
            ))}
          </Grid>
        )}
      </>
    );
  };

  const DisplayEmptyState = () => {
    return (
      <Box color="hsla(0, 0%, 24%, 0.5)" fontSize="0.875rem" py="74px" textAlign="center">
        <Text>Nothing to see here.</Text>
        <Text>Please assign a QA Tester to begin.</Text>
      </Box>
    );
  };

  const DisplayLoading = () => {
    return (
      <Box color="hsla(0, 0%, 24%, 0.5)" fontSize="0.875rem" minH="70px" py={20} textAlign="center">
        <Text>Loading...</Text>
      </Box>
    );
  };

  return (
    <>
      <Box bgColor="#fff" border="1px" borderColor="rgba(238, 238, 238, 1)" borderRadius="10px">
        <Box justifyContent="space-between" alignItems="center" display="flex" px={4} py={2}>
          <Text color="rgba(128, 128, 128, 1)" fontSize="0.75rem">
            QA Testers's Report
          </Text>
          {testersReport.checklist_metadata && (
            <Button
              height="32px"
              isDisabled={jobCardDetails[0]?.status === 'completed' || userRole === USER_ROLES.VEHICLE_MANAGER}
              onClick={onOpen}
            >
              Update Report
            </Button>
          )}
        </Box>
        <Divider />
        {!testersReport.checklist_metadata && <FillAssignedReport />}
        {testersReport.checklist_metadata && <DisplayFilledReport />}
        {!vehicleData && !testersReport.checklist_metadata && <DisplayEmptyState />}
        {isLoading && <DisplayLoading />}
      </Box>
      <QAReportEntryModal isOpen={isOpen} onClose={onClose} refetch={refetch} />
    </>
  );
};
